import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Stack, Typography } from "@mui/material";
import * as changesets from "json-diff-ts";
import React, { useEffect, useState } from "react";
import { ClauseType } from "../../../../Constants/ClauseType";
import {
  CurrencyType,
  LinkPhraseRequest,
  SentencesData,
  editedPhrases,
  phraseInfo,
  tableInfo,
} from "../../../../State/documentState";
import { getClauseDataFormat } from "../../../ClauseComponent/utils/ClauseTypeUtils";
import { isTableCell, phraseBiMap } from "../../../Utils/docUtils";

interface Props {
  dataPointName: string;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editOptionSelected: (editOptionSelected: boolean) => void;
  savedInsight: any;
  editPhrasesRequest: (newPhraseRequest: LinkPhraseRequest) => void;
  savedHighlightedPhrases: phraseInfo[] | null;
  saveHighlightedPhrases: (
    savedHighlightedPhrases: phraseInfo[] | null
  ) => void;
  phraseEditOptionSelected: boolean;
  savePhraseEditOption: (phraseEditOptionSelected: boolean) => void;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  saveDeletePhrase: (
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ) => void;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  saveAddPhrase: (
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  currencyList: CurrencyType[];
  onClose: () => void;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  sentenceData: SentencesData;
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  clauseData: any;
  updatedClauseData: any;
}

interface State {
  isAddingPhrase: boolean;
  isEditingState: boolean;
  currentEditingPhrase: phraseInfo | null;
  hideOptions: boolean;
  selectedOption: CurrencyType;
  currencyValue: string;
}

const RenewalReferenceDates: React.FC<Props> = (props) => {
  const [state, setState] = useState<State>({
    isAddingPhrase: false,
    isEditingState: false,
    currentEditingPhrase: null,
    hideOptions: false,
    selectedOption: {
      currencyName: "",
      currencyType: "",
      currencyTypeId: -1,
    },
    currencyValue: "-1",
  });

  useEffect(() => {
    props.saveHighlightedDataPoint(props.dataPointName);
  }, []);

  useEffect(() => {
    if (props.phraseInAddMode !== null) {
      setState((prevState) => ({ ...prevState, isAddingPhrase: false }));
    }
  }, [props.phraseInAddMode]);

  const setCurrencyValue = (event: any) => {
    let value = event.target.value;
    setState((prevState) => ({ ...prevState, currencyValue: value }));
  };

  const linkToPhrase = () => {
    props.savePhraseEditOption(true);
    setState((prevState) => ({
      ...prevState,
      isAddingPhrase: true,
      currentEditingPhrase: null,
      isEditingState: false,
    }));
    props.saveDeletePhrase(null, false);
    props.saveHighlightedTableCells(null);
    props.editOptionSelected(true);
    props.saveHighlightedDataPoint(props.dataPointName);
  };

  const editLinkedPhraseOnDoc = (phraseInfo: phraseInfo) => {
    let deletePhraseElement = "";
    if (isTableCell(phraseInfo)) {
      let tempTablCell: tableInfo = {
        paraId: phraseInfo.paraId,
        rowId: phraseInfo.rowId !== null ? phraseInfo.rowId : -1,
        columnId: phraseInfo.columnId !== null ? phraseInfo.columnId : -1,
      };
      deletePhraseElement =
        "p" +
        phraseInfo.paraId +
        ";r" +
        phraseInfo.rowId +
        ";c" +
        phraseInfo.columnId;
      props.saveHighlightedTableCells([tempTablCell]);
    } else {
      deletePhraseElement =
        "p" + phraseInfo.paraId + ";w" + phraseInfo.startWordId;
      props.saveHighlightedTableCells(null);
    }
    let phraseElement = document.getElementById(deletePhraseElement);
    phraseElement !== undefined &&
      phraseElement !== null &&
      phraseElement.scrollIntoView({ block: "center" }); //scroll to linked phrase
    document.documentElement.style.scrollBehavior = "smooth";

    props.saveDeletePhrase(phraseInfo, true);
    props.saveHighlightedDataPoint(props.dataPointName);
    setState((prevState) => ({
      ...prevState,
      isEditingState: true,
      isAddingPhrase: false,
      currentEditingPhrase: phraseInfo,
    }));
    props.editOptionSelected(true);
  };

  const linkPhraseOnDoc = (phraseInfo: phraseInfo | null) => {
    if (phraseInfo === null) {
      return (
        <Stack alignItems="start" spacing={1} width="100%">
          <Button
            sx={{
              color:
                state.isAddingPhrase !== true
                  ? "#88305F"
                  : props.phraseEditOptionSelected === true
                  ? "#C1C1C1"
                  : "#88305F",
              padding: 0,
            }}
            onClick={() => linkToPhrase()}
          >
            Link phrase
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button
            sx={{
              color:
                state.currentEditingPhrase === phraseInfo
                  ? "#C1C1C1"
                  : "#88305F",
              padding: 0,
            }}
            onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
          >
            Edit linked phrase
          </Button>
          {props.phraseEditOptionSelected === true && (
            <Typography fontSize="14px">
              {phraseInfo === null ? 0 : 1}
            </Typography>
          )}
        </Stack>
      );
    }
  };

  const onCancel = () => {
    props.saveDeletePhrase(null, false);
    props.saveAddPhrase(null, false);
    props.saveHighlightedPhrases(null);
    props.savePhraseEditOption(false);
    props.editOptionSelected(false);
    props.saveHighlightedTableCells(null);
    setState((prevState) => ({
      ...prevState,
      isAddingPhrase: false,
      isEditingState: false,
      currentEditingPhrase: null,
      hideOptions: false,
      selectedOption: {
        currencyName: "",
        currencyType: "",
        currencyTypeId: -1,
      },
      currencyValue: "-1",
    }));
    props.onClose();
  };

  const getSaveStatus = () => {
    let { phraseInAddMode } = props;
    let { currencyValue } = state;
    let saveStatus: boolean = false;
    if (
      phraseInAddMode !== null &&
      phraseInAddMode.paraId !== null &&
      phraseInAddMode.paraId !== -1 &&
      currencyValue !== "-1"
    ) {
      saveStatus = true;
    }
    return saveStatus;
  };

  const onSave = () => {
    const {
      phraseInAddMode,
      updatedClauseDataByType,
      clauseDataByType,
      fileId,
      sentenceData,
      parentClauseType,
      clauseType,
      postClauseDataByType,
    } = props;
    const { currencyValue } = state;
    let tempPhraseRequest: LinkPhraseRequest = {
      mode: "",
      editedPhrases: { upsert: [], deleted: [], bi: "" },
    };
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: "",
    };
    let newPhrase: phraseInfo =
      phraseInAddMode === null
        ? {
            paraId: -1,
            startWordId: -1,
            endWordId: -1,
            startSentenceId: -1,
            endSentenceId: -1,
            rowId: -1,
            columnId: -1,
            phrase: "",
            total: -1,
            currency_typeid: -1,
          }
        : phraseInAddMode;

    addedDeletedPhrases = {
      upsert: [
        {
          paraId: newPhrase.paraId,
          startWordId: newPhrase.startWordId,
          endWordId: newPhrase.endWordId,
          startSentenceId: newPhrase.startSentenceId,
          endSentenceId: newPhrase.endSentenceId,
          rowId: newPhrase.rowId,
          columnId: newPhrase.columnId,
          phrase: newPhrase.phrase,
          date: currencyValue.toString(),
        },
      ],
      deleted: [],
      bi: phraseBiMap[props.dataPointName],
    };

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: "manual",
    };

    let updatedData = updatedClauseDataByType;
    let newData = updatedData;

    let addedData = tempPhraseRequest.editedPhrases.upsert;

    for (let i = 0; i < addedData.length; i++) {
      if (addedData[i].startSentenceId === addedData[i].endSentenceId) {
        newData = getClauseDataFormat(
          "add",
          clauseType as ClauseType,
          addedData[i],
          newData,
          sentenceData
        );
      }
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType as ClauseType,
        diff,
        newData
      );
    }

    props.editPhrasesRequest(tempPhraseRequest);
    props.saveDeletePhrase(null, false);
    props.saveAddPhrase(null, false);
    props.savePhraseEditOption(false);
    props.saveHighlightedPhrases(null);
    props.editOptionSelected(false);
    props.saveHighlightedDataPoint(props.dataPointName);
    props.saveHighlightedTableCells(null);
    setState((prevState) => ({
      ...prevState,
      isAddingPhrase: false,
      isEditingState: false,
      currentEditingPhrase: null,
      hideOptions: false,
      selectedOption: {
        currencyName: "",
        currencyType: "",
        currencyTypeId: -1,
      },
      currencyValue: "-1",
    }));
    props.onClose();
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add / Edit {props.dataPointName}
      </Typography>

      <Stack spacing={2} m="0 10px">
        <Stack alignItems="start" width="100%" spacing={1}>
          <input
            type="date"
            className="currency-input"
            value={state.currencyValue === "-1" ? "" : state.currencyValue}
            placeholder="Enter Reference Date."
            onChange={(e) => setCurrencyValue(e)}
            style={{ width: "100%" }}
          />
          {linkPhraseOnDoc(props.phraseInAddMode)}
        </Stack>
        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to add a {props.dataPointName}?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Input the date using the field above.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Click on "Link Phrase" button to link a text from the contract.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Select and copy the desired phrase for
            {props.dataPointName}.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Confirm your selection using the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <Button
            variant="contained"
            type="submit"
            startIcon={<CheckIcon />}
            onClick={() => onSave()}
            disabled={!getSaveStatus()}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => onCancel()}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default RenewalReferenceDates;
