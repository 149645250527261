import * as changesets from "json-diff-ts";

import React, { useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function NonCompeteClause({
  getClauseDataByType,
  clauseData,
  fileId,
  updatedClauseData,
  postClauseDataByType,
  durationList,
  hasData,
  ...props
}: ClauseComponentInterface) {
  const [showAdd, setShowAdd] = useState(false);
  const [showAddDuration, setShowAddDuration] = useState(false);
  const [showAddTerritory, setShowAddTerritory] = useState(false);
  const [durationId, setDurationId] = useState<number>(-1);
  const [editDuration, setEditDuration] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "non_compete");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.nonCompete && clauseData.nonCompete,
    [clauseData?.nonCompete]
  );

  const updates = useMemo(
    () => updatedClauseData?.nonCompete || {},
    [updatedClauseData]
  );

  const nonCompete = useMemo(() => {
    if (updates?.non_compete) {
      let sortData = updates.non_compete.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const nonCompeteDuration = useMemo(() => {
    if (updates?.duration) {
      let sortData = updates.duration.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      sortData.map((data: any) => {
        const index = durationList.findIndex(
          (list) => list.durationTypeId === data.duration_type_id
        );
        const typeIndex = durationList.findIndex(
          (list) => list.durationName === data.duration_type
        );
        if (index > -1) {
          const type = durationList[index].durationName;
          data.duration_type = type;
        }
        if (!data.duration_type_id && typeIndex > -1) {
          const id = durationList[typeIndex].durationTypeId;
          data.duration_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [durationList, updates.duration]);

  const territory = useMemo(() => {
    if (updates?.territory) {
      let sortData = updates.territory.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let non_compete = nonCompete?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let duration = nonCompeteDuration?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.sentence_id
      );

      let non_compete_territory = territory?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        non_compete,
        duration,
        territory: non_compete_territory,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_compete", diff, updatedData);
      }
    },
    [
      nonCompete,
      nonCompeteDuration,
      territory,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteDuration = React.useCallback(
    (item: any) => {
      let duration = nonCompeteDuration?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id ||
          data.start_word_id !== item.start_word_id ||
          data.end_word_id !== item.end_word_id
      );
      let updatedData = {
        ...updates,
        duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_compete", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, nonCompeteDuration, data, fileId]
  );

  const deleteTerritory = React.useCallback(
    (item: any) => {
      let non_compete_territory = territory?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id ||
          data.start_word_id !== item.start_word_id ||
          data.end_word_id !== item.end_word_id
      );
      let updatedData = {
        ...updates,
        territory: non_compete_territory,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_compete", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, territory, data, fileId]
  );

  const editPhrase = React.useCallback(
    (data: any, index: number) => {
      let duration = nonCompeteDuration.slice();
      duration[index] = data;
      let updatedData = {
        ...updates,
        duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_compete", diff, updatedData);
      }
    },
    [fileId, nonCompeteDuration, postClauseDataByType, updates]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="non compete"
          onClick={() =>
            handleAddEdit(props, "non_compete", nonCompete, setShowAdd)
          }
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="non_compete"
          savedInsight={!isNullOrUndefined(nonCompete) ? nonCompete : []}
          savedParentClauseDataPoint={nonCompete}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="non_compete"
        />
      )}
      <Scrollable maxHeight={200}>
        {nonCompete?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "non_compete", index });
              props.onClickHighlight(index, item, "non_compete");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "non_compete" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
            para={
              item.para ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>
      {!showAddDuration ? (
        <ClauseHeader
          title="duration"
          buttonText="duration"
          onClick={() =>
            handleAddEdit(
              props,
              "Non Compete Duration",
              nonCompeteDuration,
              setShowAddDuration
            )
          }
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="Non Compete Duration"
          savedInsight={
            !isNullOrUndefined(nonCompeteDuration) ? nonCompeteDuration : []
          }
          savedParentClauseDataPoint={nonCompeteDuration}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddDuration(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="non_compete"
        />
      )}
      {nonCompeteDuration &&
        nonCompeteDuration.map((durationData: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "duration", index });
              props.onClickHighlight(
                index,
                durationData,
                "Non Compete Duration"
              );
            }}
            index={index}
            clauseItem={durationData}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "duration" && activeClause?.index === index
            }
            deleteClause={() => deleteDuration(durationData)}
            phraseLevel
            subTitle={`${durationData.duration_value} ${durationData.duration_type}`}
          />
        ))}
      {!showAddTerritory ? (
        <ClauseHeader
          title="Territory"
          buttonText="territory"
          onClick={() =>
            handleAddEdit(
              props,
              "Non Compete Territory",
              territory,
              setShowAddTerritory
            )
          }
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="Non Compete Territory"
          savedInsight={!isNullOrUndefined(territory) ? territory : []}
          savedParentClauseDataPoint={territory}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAddTerritory(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="non_compete"
        />
      )}
      <Scrollable maxHeight={200}>
        {territory?.map((territoryItem: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "territory", index });
              props.onClickHighlight(index, territoryItem, "Territory");
            }}
            index={index}
            clauseItem={territoryItem}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "territory" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteTerritory(territoryItem)}
            phraseLevel
          />
        ))}
      </Scrollable>
    </>
  );
}
