import { Box } from "@mui/material";
import CheckListComponent from "../DraftChecklist/CheckListComponent";
import React from "react";

interface Props {
  draftData: any;
  isExternal?: boolean;
}

const ChecklistTab: React.FC<Props> = ({ draftData, isExternal }) => {
  return (
    <Box
      sx={{
        marginY: "15px",
      }}
    >
      {draftData && (
        <CheckListComponent
          selectedDraftData={draftData}
          isExternal={isExternal}
        />
      )}
    </Box>
  );
};

export default ChecklistTab;
