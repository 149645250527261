import * as changesets from "json-diff-ts";
import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function InsuranceClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;
  const [showAdd, setShowAdd] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "insurance");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.insurance && clauseData.insurance,
    [clauseData?.insurance]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.insurance || {},
    [updatedClauseData]
  );

  const insurance = React.useMemo(() => {
    if (updates?.insurance) {
      let sortData = updates.insurance.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let value = insurance?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        insurance: value,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "insurance", diff, updatedData);
      }
    },
    [updates, data, fileId, insurance, postClauseDataByType]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="insurance"
          onClick={() =>
            handleAddEdit(props, "insurance", insurance, setShowAdd)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={props.toBeEdited}
          savedInsight={!isNullOrUndefined(insurance) ? insurance : []}
          savedParentClauseDataPoint={insurance}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      {insurance?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "insurance", index });
            props.onClickHighlight(index, item, "insurance");
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === "insurance" && activeClause?.index === index
          }
          deleteClause={() => deleteClause(item)}
          para={
            item.para ||
            getPara(
              props?.sentenceData,
              item.para_id,
              item.sentence_id,
              item.table && item.table[0]?.row,
              item.table && item.table[0]?.column
            )
          }
        />
      ))}
    </>
  );
}
