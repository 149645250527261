import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  LinkDateRequest,
  dateInfo,
  editedDates,
  tableInfo,
} from "../../../../State/documentState";
import {
  dateBiMap,
  getDatesFromChild,
  getFilteredDateParas,
  getFilteredDatePhrases,
  getFilteredDateTableCells,
  normalizeDates,
} from "../../../Utils/docUtils";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editEndDates: (endDates: LinkDateRequest) => void;
  savedDates: any;
  dataPointName: string;
  highlightedId: number[] | null;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  savedHighlightedDates: dateInfo[] | null;
  saveHighlightedDates: (savedHighlightedDates: dateInfo[] | null) => void;
  dateInAddEditMode: dateInfo | null;
  saveDateInAddEditMode: (dateInAddEditMode: dateInfo | null) => void;
  dateEditingStatus: boolean;
  saveDateEditingStatus: (dateEditingStatus: boolean) => void;
  clauseData: any;
}

const EffectiveDates: React.FC<Props> = (props) => {
  const [dateInAddMode, setDateInAddMode] = useState<dateInfo | null>(null);

  useEffect(() => {
    let endDates = getDatesFromChild(props.savedDates);
    props.saveHighlightedDates(endDates);
  }, [props.savedDates]);

  useEffect(() => {
    if (
      props.dateInAddEditMode !== null &&
      props.dateInAddEditMode.dateId === -1
    ) {
      if (
        dateInAddMode !== null &&
        (props.dateInAddEditMode.paraId !== dateInAddMode.paraId ||
          props.dateInAddEditMode.rowId !== dateInAddMode.rowId ||
          props.dateInAddEditMode.columnId !== dateInAddMode.columnId)
      ) {
        setDateInAddMode(props.dateInAddEditMode);
      }
    }
  }, [props.dateInAddEditMode, dateInAddMode]);

  const selectDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    let selectedDate = normalizeDates(event.currentTarget.value);
    let tempDateInAddMode: dateInfo = {
      dateId: -1,
      phrase: selectedDate,
      paraId: -1,
      rowId: -1,
      columnId: -1,
    };
    setDateInAddMode(tempDateInAddMode);
    props.saveDateEditingStatus(false);
    props.saveHighlightedId(null);
    props.saveHighlightedTableCells(null);
    props.saveDateInAddEditMode(null);
  };

  const editLinkedPara = (dateInfo: dateInfo) => {
    let { saveHighlightedDataPoint, dataPointName } = props;

    if (
      dateInfo.paraId !== null &&
      dateInfo.paraId !== -1 &&
      dateInfo.rowId !== null &&
      dateInfo.rowId !== -1 &&
      dateInfo.columnId !== null &&
      dateInfo.columnId !== -1
    ) {
      let tempTableCell = {
        paraId: dateInfo.paraId,
        rowId: dateInfo.rowId,
        columnId: dateInfo.columnId,
      };
      let scrollToTableCellId = document.getElementById(
        "p" + dateInfo.paraId + ";r" + dateInfo.rowId + ";c" + dateInfo.columnId
      );
      scrollToTableCellId !== undefined &&
        scrollToTableCellId !== null &&
        scrollToTableCellId.scrollIntoView({ block: "center" });
      props.saveHighlightedTableCells([tempTableCell]);
      props.saveHighlightedId(null);
    } else if (
      dateInfo.paraId !== null &&
      dateInfo.paraId !== -1 &&
      (dateInfo.rowId === null || dateInfo.rowId === -1) &&
      (dateInfo.columnId === null || dateInfo.columnId === -1)
    ) {
      let scrollToParaId = document.getElementById("p" + dateInfo.paraId);
      scrollToParaId !== undefined &&
        scrollToParaId !== null &&
        scrollToParaId.scrollIntoView({ block: "center" });
      props.saveHighlightedId([dateInfo.paraId]);
      props.saveHighlightedTableCells(null);
    }
    props.saveDateInAddEditMode(dateInfo);
    saveHighlightedDataPoint(dataPointName);
    props.saveDateEditingStatus(true);
    props.editOptionSelected(true);
  };

  const linkToPara = (dateInfo: dateInfo) => {
    props.saveDateEditingStatus(true);
    props.saveHighlightedId(null);
    props.saveHighlightedTableCells(null);
    props.editOptionSelected(true);
    props.saveHighlightedDataPoint(props.dataPointName);
    props.saveDateInAddEditMode(dateInfo);
  };

  const linkParaToDate = (dateString: dateInfo | null) => {
    if (dateString !== null) {
      if (
        props.dateEditingStatus === true &&
        props.dateInAddEditMode !== null &&
        dateString.dateId === props.dateInAddEditMode.dateId
      ) {
        if (
          props.dateInAddEditMode.paraId !== null &&
          props.dateInAddEditMode.paraId > -1
        ) {
          return (
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography fontSize="14px" sx={{ color: "#C1C1C1" }}>
                Edit linked paragraph
              </Typography>
              <Typography fontSize="14px">1</Typography>
            </Stack>
          );
        } else {
          return (
            <Stack width="100%">
              <Typography fontSize="14px" sx={{ color: "#C1C1C1" }}>
                Link to paragraph
              </Typography>
            </Stack>
          );
        }
      }

      if (
        (props.dateInAddEditMode !== null &&
          props.dateInAddEditMode.dateId !== dateString.dateId) ||
        props.dateInAddEditMode === null
      ) {
        if (
          dateString.paraId !== null &&
          dateString.paraId > -1 &&
          dateString.phrase !== ""
        ) {
          return (
            <Button
              variant="text"
              sx={{ color: "#88305F", padding: 0 }}
              onClick={() => editLinkedPara(dateString)}
            >
              Edit linked paragraph
            </Button>
          );
        } else if (
          dateString.phrase !== "" &&
          (dateString.paraId === null || dateString.paraId === -1)
        ) {
          return (
            <Button
              variant="text"
              sx={{ color: "#88305F", padding: 0 }}
              onClick={() => linkToPara(dateString)}
            >
              Link to paragraph
            </Button>
          );
        }
      }
    }
  };

  const getEndDates = () => {
    return (
      <Stack width="100%">
        <input
          type="date"
          className="tag-input"
          placeholder="Enter text"
          onChange={(e) => selectDate(e)}
          style={{ width: "100%" }}
        />
        {linkParaToDate(dateInAddMode)}
      </Stack>
    );
  };

  const onCancel = () => {
    props.saveHighlightedDates(null);
    props.saveDateInAddEditMode(null);
    props.saveHighlightedTableCells(null);
    props.saveHighlightedId(null);
    props.editOptionSelected(false);
    props.saveDateEditingStatus(false);
    setDateInAddMode(null);
  };

  const onSave = () => {
    let { savedHighlightedDates, dataPointName } = props;
    let tempHighlightedDates =
      savedHighlightedDates !== null ? savedHighlightedDates : [];
    if (dateInAddMode !== null && dateInAddMode.phrase !== "") {
      tempHighlightedDates.push(dateInAddMode);
    }
    let oldDates = getDatesFromChild(props.savedDates);
    let upsertDates: dateInfo[] = [];
    let deletedDates: dateInfo[] = [];
    let editedDates: editedDates = { upsert: [], deleted: [], bi: "" };
    if (oldDates.length === 0) {
      if (
        savedHighlightedDates === null ||
        savedHighlightedDates.length === 0
      ) {
        //nothing added nor deleted
        editedDates.upsert = [];
        editedDates.deleted = [];
      } else if (
        savedHighlightedDates !== null &&
        savedHighlightedDates.length > 0
      ) {
        //new dates added
        upsertDates = [];
        savedHighlightedDates.forEach((date) => upsertDates.push(date));
        editedDates.upsert = upsertDates;
        editedDates.deleted = [];
      }
    } else if (
      oldDates.length > 0 &&
      (savedHighlightedDates === null || savedHighlightedDates.length === 0)
    ) {
      //all old dates deleted
      deletedDates = [];
      oldDates.forEach((date) => deletedDates.push(date));
      editedDates.upsert = [];
      editedDates.deleted = deletedDates;
    } else {
      let oldDatePhrases = getFilteredDatePhrases(oldDates);
      let changedDatePhrases = getFilteredDatePhrases(tempHighlightedDates);
      let oldDateParas = getFilteredDateParas(oldDates);
      let changedDateParas = getFilteredDateParas(tempHighlightedDates);
      let oldDateTableCells = getFilteredDateTableCells(oldDates);
      let changedDateTableCells =
        getFilteredDateTableCells(tempHighlightedDates);

      let editedDatePhrases = getAddedDeletedDatePhrases(
        oldDatePhrases,
        changedDatePhrases
      );
      let editedDateParas = getAddedDeletedDateParas(
        oldDateParas,
        changedDateParas
      );
      let editedDateTableCells = getAddedDeletedDateTableCells(
        oldDateTableCells,
        changedDateTableCells
      );

      editedDates = mergeAddedDeletedDates(
        editedDatePhrases,
        editedDateParas,
        editedDateTableCells
      );
    }

    editedDates.bi = dateBiMap[dataPointName];

    let tempDateRequest: LinkDateRequest = {
      data: "Yes",
      editedDates: editedDates,
      mode: "manual",
    };

    props.editEndDates(tempDateRequest);
    props.saveHighlightedDates(null);
    props.saveDateInAddEditMode(null);
    props.saveHighlightedTableCells(null);
    props.saveHighlightedId(null);
    props.saveDateEditingStatus(false);
    props.editOptionSelected(false);
    setDateInAddMode(null);
  };

  const saveOrCancel = () => {
    return (
      <Stack direction="row">
        <Button
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={onSave}
          disabled={!getSaveStatus()}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={onCancel} startIcon={<CloseIcon />}>
          Cancel
        </Button>
      </Stack>
    );
  };

  const getSaveStatus = () => {
    let { savedHighlightedDates } = props;
    let isSavedDatesLinked: boolean = false;
    if (savedHighlightedDates !== null && savedHighlightedDates.length > 0) {
      if (
        savedHighlightedDates.findIndex(
          (date) => date.paraId === null || date.paraId === -1
        ) === -1
      ) {
        isSavedDatesLinked = true;
      }
    } else if (
      savedHighlightedDates === null ||
      savedHighlightedDates.length === 0
    ) {
      isSavedDatesLinked = true;
    }
    let isNewDateLinked = false;
    if (dateInAddMode !== null) {
      if (dateInAddMode.paraId !== null && dateInAddMode.paraId !== -1) {
        isNewDateLinked = true;
      }
    } else if (dateInAddMode === null) {
      isNewDateLinked = true;
    }
    if (isSavedDatesLinked === true && isNewDateLinked === true) {
      return true;
    }
    return false;
  };

  const getAddedDeletedDateParas = (
    previousDateParas: dateInfo[],
    changedDateParas: dateInfo[]
  ): editedDates => {
    let addedDateParas: dateInfo[] = [];
    let deletedDateParas: dateInfo[] = [];
    if (previousDateParas.length > 0) {
      if (changedDateParas.length > 0) {
        //newly added
        for (let i = 0; i < changedDateParas.length; i++) {
          let addedExists = false;
          for (let j = 0; j < previousDateParas.length; j++) {
            if (
              changedDateParas[i].phrase === previousDateParas[j].phrase &&
              changedDateParas[i].paraId === previousDateParas[j].paraId
            ) {
              addedExists = true;
              break;
            }
          }
          if (addedExists === false) {
            addedDateParas.push(changedDateParas[i]);
          }
        }

        //deleted elements
        for (let i = 0; i < previousDateParas.length; i++) {
          let deletedExists = false;
          for (let j = 0; j < changedDateParas.length; j++) {
            if (
              previousDateParas[i].phrase === changedDateParas[j].phrase &&
              previousDateParas[i].paraId === changedDateParas[j].paraId
            ) {
              deletedExists = true;
              break;
            }
          }
          if (deletedExists === false) {
            deletedDateParas.push(previousDateParas[i]);
          }
        }
      } else {
        //all old deleted
        for (let i = 0; i < previousDateParas.length; i++) {
          deletedDateParas.push(previousDateParas[i]);
        }
      }
    } else {
      if (changedDateParas.length > 0) {
        //newly added
        for (let i = 0; i < changedDateParas.length; i++) {
          addedDateParas.push(changedDateParas[i]);
        }
      }
    }

    let editedDateParas: editedDates = {
      upsert: addedDateParas,
      deleted: deletedDateParas,
      bi: "",
    };
    return editedDateParas;
  };

  const getAddedDeletedDateTableCells = (
    previousDateTableCells: dateInfo[],
    changedDateTableCells: dateInfo[]
  ) => {
    let addedDateTableCells: dateInfo[] = [];
    let deletedDateTableCells: dateInfo[] = [];
    if (previousDateTableCells.length > 0) {
      if (changedDateTableCells.length > 0) {
        //newly added
        for (let i = 0; i < changedDateTableCells.length; i++) {
          let addedExists = false;
          for (let j = 0; j < previousDateTableCells.length; j++) {
            if (
              changedDateTableCells[i].phrase ===
                previousDateTableCells[j].phrase &&
              changedDateTableCells[i].paraId ===
                previousDateTableCells[j].paraId &&
              changedDateTableCells[i].rowId ===
                previousDateTableCells[j].rowId &&
              changedDateTableCells[i].columnId ===
                previousDateTableCells[j].columnId
            ) {
              addedExists = true;
              break;
            }
            if (addedExists === false) {
              addedDateTableCells.push(changedDateTableCells[i]);
            }
          }
        }
        //deleted elements
        for (let i = 0; i < previousDateTableCells.length; i++) {
          let deletedExists = false;
          for (let j = 0; j < changedDateTableCells.length; j++) {
            if (
              previousDateTableCells[i].phrase ===
                changedDateTableCells[j].phrase &&
              previousDateTableCells[i].paraId ===
                changedDateTableCells[j].paraId &&
              previousDateTableCells[i].rowId ===
                changedDateTableCells[j].rowId &&
              previousDateTableCells[i].columnId ===
                changedDateTableCells[j].columnId
            ) {
              deletedExists = true;
              break;
            }
          }
          if (deletedExists === false) {
            deletedDateTableCells.push(previousDateTableCells[i]);
          }
        }
      } else {
        //all deleted
        for (let i = 0; i < previousDateTableCells.length; i++) {
          deletedDateTableCells.push(previousDateTableCells[i]);
        }
      }
    } else {
      //all newly added
      if (changedDateTableCells.length > 0) {
        for (let i = 0; i < changedDateTableCells.length; i++) {
          addedDateTableCells.push(changedDateTableCells[i]);
        }
      }
    }

    let editedDateTableCells: editedDates = {
      upsert: addedDateTableCells,
      deleted: deletedDateTableCells,
      bi: "",
    };
    return editedDateTableCells;
  };

  const getAddedDeletedDatePhrases = (
    previousDatePhrases: dateInfo[],
    changedDatePhrases: dateInfo[]
  ) => {
    let addedDatePhrases: dateInfo[] = [];
    let deletedDatePhrases: dateInfo[] = [];

    if (previousDatePhrases.length > 0) {
      if (changedDatePhrases.length > 0) {
        //newly added
        for (let i = 0; i < changedDatePhrases.length; i++) {
          let addedExists = false;
          for (let j = 0; j < previousDatePhrases.length; j++) {
            if (
              changedDatePhrases[i].phrase === previousDatePhrases[j].phrase
            ) {
              addedExists = true;
              break;
            }
          }
          if (addedExists === false) {
            addedDatePhrases.push(changedDatePhrases[i]);
          }
        }

        //deleted elements
        for (let i = 0; i < previousDatePhrases.length; i++) {
          let deletedExists = false;
          for (let j = 0; j < changedDatePhrases.length; j++) {
            if (
              previousDatePhrases[i].phrase === changedDatePhrases[j].phrase
            ) {
              deletedExists = true;
              break;
            }
          }
          if (deletedExists === false) {
            deletedDatePhrases.push(previousDatePhrases[i]);
          }
        }
      } else {
        //all old deleted
        for (let i = 0; i < previousDatePhrases.length; i++) {
          deletedDatePhrases.push(previousDatePhrases[i]);
        }
      }
    } else {
      //all new added
      if (changedDatePhrases.length > 0) {
        for (let i = 0; i < changedDatePhrases.length; i++) {
          addedDatePhrases.push(changedDatePhrases[i]);
        }
      }
    }

    let editedDatePhrases: editedDates = {
      upsert: addedDatePhrases,
      deleted: deletedDatePhrases,
      bi: "",
    };
    return editedDatePhrases;
  };

  const mergeAddedDeletedDates = (
    firstEditedDates: editedDates,
    secondEditedDates: editedDates,
    thirdEditedDates: editedDates
  ): editedDates => {
    let upsertDates = firstEditedDates.upsert
      .concat(secondEditedDates.upsert)
      .concat(thirdEditedDates.upsert);
    let deletedDates = firstEditedDates.deleted
      .concat(secondEditedDates.deleted)
      .concat(thirdEditedDates.deleted);

    let mergedEditedDates = {
      upsert: upsertDates,
      deleted: deletedDates,
      bi: "",
    };
    return mergedEditedDates;
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add / Edit {props.dataPointName}
      </Typography>

      <Stack width="100%" spacing={2} m="0 10px">
        <Typography color="#938F99" fontSize="14px">
          <InfoOutlinedIcon /> Confirm your selection using the Save button
          below.
        </Typography>
        <Grid container spacing={2} alignItems="center">
          {getEndDates()}
        </Grid>
        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to add a {props.dataPointName}?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Input the date using the field above.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Click on "link paragraph" button to link a text from the
            contract.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the desired paragraph that contains{" "}
            {props.dataPointName}.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Confirm your selection using the Save button below.
          </Typography>
        </Stack>
        {saveOrCancel()}
      </Stack>
    </Box>
  );
};

export default EffectiveDates;
