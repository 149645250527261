import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "../RiverusUI/Components/Icons/DeleteIcon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteStamp } from "../Services/Stamp";
import { useSnackbar } from "notistack";

interface Props {
  data: any;
  isLoading: boolean;
  showAction?: boolean;
}

const StampTable = (props: Props) => {
  const { data, isLoading, showAction = true } = props;

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: deleteStampMutation } = useMutation({
    mutationKey: ["delete-stamp"],
    mutationFn: deleteStamp,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stamp-bank-list"] });
      queryClient.invalidateQueries({ queryKey: ["stamp-bank"] });
      enqueueSnackbar({
        message: "Stamp deleted successfully!",
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const columns = React.useMemo<GridColDef<any>[]>(() => {
    let col = [
      {
        field: "serial_number",
        headerName: "Serial Number",
        flex: 1,
        sortable: false,
      },
      {
        field: "denomination",
        headerName: "Value",
        flex: 1,
        sortable: false,
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 120,
        flex: 1,
        sortable: false,
      },
      {
        field: "used_on",
        headerName: "Used On",
        minWidth: 120,
        flex: 1,
        sortable: false,
      },
      {
        field: "id",
        headerName: "Action",
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <IconButton onClick={() => deleteStampMutation(params.id)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ];
    if (!showAction) {
      col = col.filter((data: any) => data.headerName !== "Action");
    }
    return col;
  }, [deleteStampMutation, showAction]);

  const getHeight = () => {
    const length = data?.results.length;
    switch (length) {
      case 1:
        return 130;
      case 2:
        return 180;
      case 3:
        return 250;
      case 4:
        return 300;
      default:
        return 350;
    }
  };

  if (data?.results.length) {
    return (
      <Box sx={{ height: getHeight(), width: "100%" }}>
        <DataGrid
          rows={data?.results || []}
          columns={columns}
          disableRowSelectionOnClick
          rowCount={data?.count || 0}
          loading={isLoading}
          disableColumnMenu
          hideFooter
          slots={{
            loadingOverlay: LinearProgress,
          }}
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "riPrimary.10",
              fontSize: 16,
              borderRadius: "50px",
              borderBottom: "none",
            },
          }}
        />
      </Box>
    );
  }
  return (
    <Typography color="#938F99" margin="8px 0 8px 16px">
      <InfoOutlinedIcon /> No stamp-papers available
    </Typography>
  );
};

export default StampTable;
