import React from "react";
import UserAuthChecker from "../HOC/auth";
import VerifyExternalUser from "../ExternalUserFlow/VerifyExternalUser";
import ViewDraft from "../Draft/Component/ViewDraft";
import StampBank from "../StampBank";

const Admin = React.lazy(() => import("../Admin"));
const ApprovalsContainer = React.lazy(
  () => import("../Approvals/Container/ApprovalsContainer")
);
const ClauseLibraryAdminContainer = React.lazy(
  () => import("../ClauseLibrary/Admin/Container/ClauseLibraryAdminContainer")
);
const ContractTypeCont = React.lazy(
  () => import("../ContractTypes/Container/ContractTypeCont")
);
const DataDictionaryAdminContainer = React.lazy(
  () => import("../DataDictionary/Admin/Container/DataDictionaryAdminContainer")
);
const DataDictionaryContainer = React.lazy(
  () => import("../DataDictionary/Container/DataDictionaryContainer")
);
const DraftContainer = React.lazy(
  () => import("../Draft/Container/DraftContainer")
);
const FilterContainer = React.lazy(
  () => import("../Filter/Container/FilterContainer")
);
const ReminderContainer = React.lazy(
  () => import("../Reminder/Container/ReminderCon")
);
const SearchCon = React.lazy(() => import("../Search/Container/SearchCon"));
const TemplateAdminCon = React.lazy(
  () => import("../Templates/Admin/Container/templatesContainerAdmin")
);
const TemplateCon = React.lazy(
  () => import("../Templates/Container/templatesContainer")
);

const authenticatorContainer = React.lazy(
  () =>
    import(
      "../UniversalComponents/HeimdallChild/Container/authenticatorContainer"
    )
);
const clauseLibraryCon = React.lazy(
  () => import("../ClauseLibrary/Container/clauseLibraryCon")
);
const dashboardCont = React.lazy(
  () => import("../Dashboard/Container/dashboardCont")
);
const documentLibraryCon = React.lazy(
  () => import("../DocumentLibrary/Container/documentLibraryCon")
);
const historyCon = React.lazy(() => import("../History/Container/historyCon"));
const introCon = React.lazy(() => import("../IntroPage/Container/introCon"));
const newAnalysisCon = React.lazy(
  () => import("../NewAnalysis/Container/newAnalysisContainer")
);
const newDashboardCon = React.lazy(
  () => import("../NewDashboard/Container/newDashboardContainer")
);
const projectCon = React.lazy(() => import("../Project/Container/projectCon"));
const reportsCon = React.lazy(() => import("../Reports/Container/reportsCon"));
const settingsCon = React.lazy(
  () => import("../Settings/Container/settingsCon")
);
const signInCon = React.lazy(
  () => import("../Authentication/Container/signInCon")
);
// const signUpCon = React.lazy(() => import("../Authentication/Container/signUpCon"));
const susbscriptionsCon = React.lazy(
  () => import("../Susbscriptions/Container/susbscriptionsCon")
);
const taskManagementCon = React.lazy(
  () => import("../TaskManagement/Container/taskManagementCon")
);
const uploadCon = React.lazy(
  () => import("../UploadFile/Container/uploadFileContainer")
);

const DocumentView = React.lazy(
  () => import("../DocumentView/Container/documentCon")
);

// import uploadCon from "../Upload/Container/uploadCon";

export interface RouteInterface {
  path: string;
  component: any;
  exact: boolean;
}

export const ROUTES: RouteInterface[] = [
  {
    path: "/",
    // component: introCon,
    component: UserAuthChecker(introCon, ["internal"]),
    exact: true,
  },
  /* {
    path: '/dashboard',
    component: dashboardCont,
    exact: true
  }, */
  {
    path: "/dashboard",
    component: newDashboardCon,
    exact: true,
  },
  {
    path: "/uploads",
    component: historyCon,
    exact: true,
  },
  {
    path: "/addfiles",
    component: uploadCon,
    exact: true,
  },
  {
    path: "/settings",
    component: settingsCon,
    exact: true,
  },
  {
    path: "/project",
    component: projectCon,
    exact: true,
  },
  {
    path: "/subscriptions",
    component: susbscriptionsCon,
    exact: true,
  } /* 
    {
        path: '/analysis',
        component: analysisCon,
        exact: true
    }, */,
  {
    path: "/document/:page/:id/:requestid?",
    component: DocumentView,
    exact: true,
  },
  {
    path: "/search/:query",
    component: SearchCon,
    exact: true,
  },
  {
    path: "/documentlibrary",
    component: documentLibraryCon,
    exact: true,
  },
  {
    path: "/reminders",
    component: ReminderContainer,
    exact: true,
  },
  {
    path: "/reports",
    component: reportsCon,
    exact: true,
  },
  // {
  //   path: "/tasks",
  //   component: taskManagementCon,
  //   exact: true,
  // },
  {
    path: "/clauselibrary",
    component: clauseLibraryCon,
    exact: true,
  },
  {
    path: "/analysis",
    component: newAnalysisCon,
    exact: true,
  },
  {
    path: "/templates",
    component: TemplateCon,
    exact: true,
  },
  {
    path: "/admin/templates-admin",
    component: TemplateAdminCon,
    exact: true,
  },
  {
    path: "/admin/stamp-bank",
    component: StampBank,
    exact: true,
  },
  {
    path: "/draftingreview",
    component: DraftContainer,
    exact: true,
  },
  {
    path: "/draftingreview/:id",
    component: DraftContainer,
    exact: true,
  },
  {
    path: "/draft/:version/:id",
    component: ViewDraft,
    exact: true,
  },
  {
    path: "/approvals",
    component: ApprovalsContainer,
    exact: true,
  },
  {
    path: "/datadictionary",
    component: DataDictionaryContainer,
    exact: false,
  },
  {
    path: "/datadictionary/:id/",
    component: DataDictionaryContainer,
    exact: false,
  },
  {
    path: "/admin/data-dictionary-admin/:id/",
    component: DataDictionaryAdminContainer,
    exact: false,
  },
  {
    path: "/admin/data-dictionary-admin",
    component: DataDictionaryAdminContainer,
    exact: true,
  },
  {
    path: "/admin/clause-library-admin",
    component: ClauseLibraryAdminContainer,
    exact: true,
  },
  {
    path: "/admin",
    component: Admin,
    exact: true,
  },
  {
    path: "/admin/contracttypes",
    component: ContractTypeCont,
    exact: true,
  },
  {
    path: "/filter",
    component: FilterContainer,
    exact: true,
  },
];

export const FREEROUTES: RouteInterface[] = [
  {
    path: "/auth",
    component: authenticatorContainer,
    exact: true,
  },
  {
    path: "/login",
    component: signInCon,
    exact: true,
  },
];

export const STYLUSROUTES: RouteInterface[] = [
  {
    path: "/template",
    component: TemplateCon,
    exact: true,
  },
];

export const EXTERNAL_USER_ROUTES: RouteInterface[] = [
  {
    path: "/draft/:version/:id",
    component: VerifyExternalUser,
    exact: true,
  },
];
