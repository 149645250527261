import {
  Box,
  Button,
  Chip,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";

interface IProps extends StackProps {
  heading: string;
  subHeading: string;
  index: number;
  text: string;
  handleEdit: VoidFunction;
  deleteClause: VoidFunction;
  tags?: string[];
}

const PaymentTimelineCard: FC<IProps> = ({
  heading,
  subHeading,
  index,
  text,
  tags,
  handleEdit,
  deleteClause,
  ...rest
}) => {
  return (
    <Box sx={{ borderLeft: "1px solid #88305F", padding: "0 8px 4px" }}>
      <Stack
        sx={{
          background: "#fff7faad",
          padding: "8px 16px",
          borderRadius: "12px",
        }}
        {...rest}
      >
        <Stack
          direction="row"
          mb="5px"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Stack direction="row" spacing="5px">
            <Typography fontWeight="600" fontSize="14px">{`#${
              index + 1
            } (${heading})`}</Typography>
            <Box>
              {tags?.map((tag: string) => (
                <Chip
                  style={{ background: "#D3FFC4", margin: 0, padding: 0 }}
                  label={tag}
                />
              ))}
            </Box>
          </Stack>
          <Stack direction="row">
            <Button
              sx={{ padding: 0 }}
              onClick={handleEdit}
              startIcon={<EditOutlinedIcon />}
            >
              Edit
            </Button>
            <Button
              sx={{ padding: 0 }}
              onClick={deleteClause}
              startIcon={<DeleteSweepOutlinedIcon />}
            >
              Remove
            </Button>
          </Stack>
        </Stack>
        <Typography variant="body1">{subHeading}</Typography>
        <Typography variant="body1">{text}</Typography>
      </Stack>
    </Box>
  );
};

export default PaymentTimelineCard;
