import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  draftSignatures,
  getDraft,
  getSignatureDetails,
  onSignedStatus,
  sendAgreement,
  updateDraftSignatures,
  updateSignatureDetails,
  uploadDocument,
} from "../../../Services/signatureTab";
import { useMutation, useQuery } from "@tanstack/react-query";

import CustomModal from "../../../RiverusUI/Components/CustomModal";
import { KeyboardReturn } from "@mui/icons-material";
import NameAvatar from "../../../RiverusUI/DataGrid/NameAvatar";
import colors from "../../../RiverusUI/Theme/colors";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";

interface Props {
  signatoriesList: any[];
  isLoggedInUser: any;
  addedSignatureFields: boolean;
  draftData: any;
  handleConfirmation: any;
  setRedirect?: Dispatch<SetStateAction<string>>;
  isExternal?: boolean;
  sendDraftConfirmation: boolean;
  editModeDisabled?: boolean;
  instance: any;
  setAddedSignatureFields: Dispatch<SetStateAction<boolean>>;
  setDocumentSigned: Dispatch<SetStateAction<boolean>>;
  declineSignature: VoidFunction;
}

const SignatoriesList: React.FC<Props> = ({
  signatoriesList,
  isLoggedInUser,
  addedSignatureFields,
  draftData,
  handleConfirmation,
  setRedirect,
  isExternal,
  sendDraftConfirmation,
  editModeDisabled,
  declineSignature,
  instance,
  setAddedSignatureFields,
  setDocumentSigned,
}) => {
  const [decline, setDecline] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [email, setEmail] = useState<string[]>([]);
  const [signatureName, setSignatureName] = useState<string[]>([]);
  const [signAccessToken, setSignAccessToken] = useState<string>();
  const [annotPosition, setAnnotPosition] = useState(0);

  const draftUrl = React.useMemo(() => draftData?.access_url, [draftData]);

  const draftFileName = React.useMemo(
    () => draftData?.contractName,
    [draftData]
  );

  const { enqueueSnackbar } = useSnackbar();

  const openDeclineDialog = () => {
    setDecline(true);
  };

  const closeDeclineDialog = () => {
    setDecline(false);
  };

  const handleDecline = () => {
    declineSignature();
    setDecline(false);
    enqueueSnackbar("Your Preference has been noted", {
      variant: "info",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  };

  const handleGoBackButton = async () => {
    handleConfirmation();
    setRedirect?.("/draftingreview");
  };

  const showSignButton = useCallback(
    (signatory: any) => {
      if (
        isLoggedInUser(signatory?.id, signatory?.email, signatory?.user_type) &&
        !signatory?.signed_date &&
        draftData?.signature_method === "Stylus (Riverus)" &&
        !editModeDisabled
      ) {
        return true;
      }
      return false;
    },
    [draftData, isLoggedInUser, editModeDisabled]
  );

  useEffect(() => {
    if (localStorage.getItem("signAccessToken")) {
      setSignAccessToken(localStorage.getItem("signAccessToken") || undefined);
    } else {
      window.addEventListener("storage", () => {
        const initialToken = localStorage.getItem("signAccessToken");
        if (initialToken) {
          setSignAccessToken(initialToken);
        }
      });
    }
  }, []);

  const { mutate: onSignedMutation } = useMutation({
    mutationKey: ["on_signed", draftData?.id],
    mutationFn: async (status: string) =>
      await onSignedStatus(draftData?.id, status),
  });

  const { mutate: uploadMutation, data: uploadDocumentData } = useMutation({
    mutationKey: ["upload_document"],
    mutationFn: async (formData: any) => await uploadDocument(formData),
  });

  const { mutate: signDetailsMutation } = useMutation({
    mutationKey: ["sign_details"],
    mutationFn: async (payload: any) => await updateSignatureDetails(payload),
  });

  const { mutate: agreementIdMutation, data: agreementIdData } = useMutation({
    mutationKey: ["agreement_id"],
    mutationFn: async (payload: any) => await sendAgreement(payload),
  });

  const { data: signatureAgreementData } = useQuery({
    queryKey: ["signature_agreement", draftData?.id],
    queryFn: async () => await draftSignatures(draftData?.id),
    select: (response: any) => {
      if (response?.data?.status === "SIGNED") {
        onSignedMutation("Contract Executed Successfully");
      }
      return response;
    },
    enabled: !isExternal && !!draftData?.id,
  });

  const { data: signatureDetailsData } = useQuery({
    queryKey: ["signature_details", draftData?.id, signatureAgreementData],
    queryFn: async () =>
      await getSignatureDetails(
        draftData?.id,
        signatureAgreementData?.data?.agreement
      ),
    enabled: !!signatureAgreementData?.data?.agreement && !isExternal,
  });

  useQuery({
    queryKey: ["send_document"],
    queryFn: async () => await getDraft(draftUrl),
    select: (response: any) => {
      let myFile: File;
      myFile = new File([response], draftFileName + ".pdf");
      const formData = new FormData();
      formData.append("File", myFile);
      uploadMutation(formData);
      return response;
    },
    enabled: isSending && !isExternal,
  });

  useQuery({
    queryKey: ["send_agreement"],
    queryFn: async () => {
      if (email.length === signatoriesList.length) {
        let participantSetsInfo: any[] = email.map((value, index) => {
          return {
            memberInfos: [{ email: email[index] }],
            order: index + 1,
            role: "SIGNER",
          };
        });
        const agreementInfo = {
          fileInfos: [
            {
              transientDocumentId:
                uploadDocumentData?.data?.transientDocumentId,
            },
          ],
          name: draftFileName + " - Agreement",
          participantSetsInfo: participantSetsInfo,
          signatureType: "ESIGN",
          state: "IN_PROCESS",
        };
        agreementIdMutation(agreementInfo);
      }
    },
    enabled:
      !!uploadDocumentData && !!email && !!signatoriesList && !isExternal,
  });

  const { data: addSignatureDetailsData } = useQuery({
    queryKey: ["add_signature_details"],
    queryFn: async () =>
      await updateDraftSignatures(agreementIdData?.data?.id, draftData?.id),
    select: (response: any) => {
      let signatureDetails: any[] = email.map((element, index) => {
        return { email: email[index], name: signatureName[index] };
      });
      const payload = {
        draft: draftData?.id,
        agreement: agreementIdData?.data?.id,
        signatures: signatureDetails,
      };
      signDetailsMutation(payload);
      return response;
    },
    enabled: !!agreementIdData && !isExternal,
  });

  const sendDocument = () => {
    setIsSending(true);
    const savedSignAccessToken = localStorage.getItem("signAccessToken");
    setSignAccessToken(savedSignAccessToken || undefined);
  };

  const agreementStatus = React.useMemo(() => {
    if (addSignatureDetailsData?.data?.status) {
      return addSignatureDetailsData?.data?.status;
    } else {
      if (signatureAgreementData?.data?.message) {
        return signatureAgreementData?.data?.message;
      } else if (signatureAgreementData?.data?.status) {
        return signatureAgreementData?.data?.status;
      }
    }
  }, [addSignatureDetailsData, signatureAgreementData]);

  const displaySignatories = React.useMemo(() => {
    if (
      (agreementStatus &&
        agreementStatus !== "SIGNED" &&
        !draftData?.status
          .toLowerCase()
          .includes("contract executed successfully")) ||
      (draftData?.signature_method && draftData?.signature_method !== "Adobe")
    ) {
      return true;
    } else {
      return false;
    }
  }, [agreementStatus, draftData?.status, draftData?.signature_method]);

  useEffect(() => {
    if (
      sendDraftConfirmation &&
      draftData?.signature_method === "Adobe" &&
      agreementStatus !== "SIGNED" &&
      signAccessToken
    ) {
      sendDocument();
    }
  }, [
    sendDraftConfirmation,
    draftData?.signature_method,
    agreementStatus,
    signAccessToken,
  ]);

  useEffect(() => {
    let emailList: any[] = [];
    let signatureNameList: any[] = [];
    signatoriesList.map((signatory: any, index: number) => {
      const signatoryEmail = signatory.email;
      emailList[index] = signatoryEmail;
      if (signatory.user_type === "internal") {
        const signatureName =
          signatory?.first_name + " " + signatory?.last_name;
        signatureNameList[index] = signatureName;
      } else {
        const signatureName = signatory?.name;
        signatureNameList[index] = signatureName;
      }
    });
    setEmail(emailList);
    setSignatureName(signatureNameList);
  }, [signatoriesList]);

  const user_data = useMemo(() => getLocalStorage("user_profile"), []);
  const external_email = useMemo(() => getLocalStorage("external_email"), []);

  const submit = async () => {
    const { documentViewer, Annotations } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const annotationsList = annotationManager.getAnnotationsList();

    const widgetField = annotationsList.filter(
      (annot: any) => annot.Subject.toLowerCase() === "widget"
    );
    const signatureField = annotationsList.filter(
      (annot: any) => annot.Subject.toLowerCase() === "signature"
    );

    if (signatureField.length > widgetField.length) {
      setDocumentSigned(true);
    }

    const annotsToDraw: any = [];

    const id = isExternal ? external_email : user_data.id;
    const name = isExternal
      ? external_email
      : `${user_data.first_name} ${user_data.last_name}`;
    const date = new Date().toISOString();
    await Promise.all(
      annotationsList.map(async (annot: any) => {
        if (
          annot.Subject.toLowerCase() === "free text" &&
          annot.getCustomData("user") === id
        ) {
          annot.setContents(`signed by: ${name}`);
          annot.FontSize = "12px";
          annot.TextColor = new Annotations.Color(18, 13, 61);
          var dateAnnot = new Annotations.FreeTextAnnotation();
          dateAnnot.setContents(
            `${dayjs(date).format("DD/MM/YYYY, h:mm A")} IST`
          );
          dateAnnot.PageNumber = annot.getPageNumber();
          dateAnnot.X = annot.getX();
          dateAnnot.Y = annot.getY() + 20;
          dateAnnot.rotation = annot.Rotation;
          if (annot.Rotation === 0 || annot.Rotation === 180) {
            dateAnnot.Width = annot.getWidth();
            dateAnnot.Height = annot.getHeight();
          } else {
            dateAnnot.Width = annot.getHeight();
            dateAnnot.Height = annot.getWidth();
          }
          dateAnnot.FontSize = "12px";
          dateAnnot.TextAlign = "center";
          dateAnnot.TextColor = new Annotations.Color(18, 13, 61);
          dateAnnot.StrokeThickness = 0;

          annotationManager.addAnnotation(dateAnnot);
          annotsToDraw.push(dateAnnot);
        }
      })
    );
    // refresh viewer
    await annotationManager.drawAnnotationsFromList(annotsToDraw);

    setAddedSignatureFields(true);
  };

  return (
    <>
      {displaySignatories &&
        signatoriesList?.map((signatory: any, index: number) => (
          <Box
            sx={{ backgroundColor: colors?.riPrimary[20] }}
            borderRadius="10px"
            padding={2}
            marginY={2}
            key={`${signatory?.id}-${index}`}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box display="flex">
                <NameAvatar
                  firstName={
                    signatory?.first_name || signatory?.name?.split(" ")[0]
                  }
                  lastName={
                    signatory?.last_name || signatory?.name?.split(" ")[1]
                  }
                />

                {!signatory.signed_date && !signatory.declined_date && (
                  <Typography fontSize="16px" fontWeight="400" marginLeft={2}>
                    {signatory?.name ||
                      signatory?.first_name + " " + signatory?.last_name}{" "}
                    signature pending
                  </Typography>
                )}
                {signatory.signed_date && !signatory.declined_date && (
                  <Typography fontSize="16px" fontWeight="400" marginLeft={2}>
                    {signatory?.name ||
                      signatory?.first_name + " " + signatory?.last_name}{" "}
                    has already signed
                  </Typography>
                )}
              </Box>
              {signatory.signed_date && (
                <Typography fontSize="11px" fontWeight="500">
                  {dayjs(signatory.signed_date).format("DD/MM/YYYY, h:mm A")}
                </Typography>
              )}
            </Box>

            {showSignButton(signatory) && (
              <Box display="flex" marginTop={1}>
                <IconButton
                  style={{
                    transform: "scaleX(-1)",
                    paddingRight: "15px",
                  }}
                >
                  <KeyboardReturn />
                </IconButton>
                <Button onClick={openDeclineDialog}>Decline</Button>
                <Button onClick={submit}>Submit</Button>
              </Box>
            )}
            <CustomModal
              title="Decline signature"
              handleClose={closeDeclineDialog}
              open={decline}
            >
              <Typography margin={4} fontSize="16px" fontWeight="400">
                Are you sure you want to decline giving your signature?
              </Typography>
              <Box>
                <Button variant="contained" onClick={closeDeclineDialog}>
                  No, go back
                </Button>
                <Button variant="outlined" onClick={handleDecline}>
                  Yes, decline
                </Button>
              </Box>
            </CustomModal>

            <CustomModal
              title="Signature placed successfully"
              handleClose={handleConfirmation}
              open={addedSignatureFields}
            >
              <Typography margin={4} fontSize="16px" fontWeight="400">
                Thank you for signing the draft. Your signature has been placed
                successfully.
              </Typography>
              <Box>
                {!isExternal && (
                  <Button variant="contained" onClick={handleGoBackButton}>
                    Go back to dashboard
                  </Button>
                )}
                <Button variant="outlined" onClick={handleConfirmation}>
                  Close
                </Button>
              </Box>
            </CustomModal>
          </Box>
        ))}
      {agreementStatus && (
        <Typography margin={4} fontSize="18px" fontWeight="600">
          Status: {agreementStatus}
        </Typography>
      )}
      {signatureDetailsData?.data.length > 0 &&
        agreementStatus === "SIGNED" && (
          <>
            <Typography margin={4} fontSize="18px" fontWeight="600">
              <Stack margin={4} sx={{ paddingBottom: 2 }} spacing={2}>
                Signed By:
              </Stack>
              <ul>
                {signatureDetailsData?.data?.map(
                  (element: any, index: number) => (
                    <li key={index}>{element.name}</li>
                  )
                )}
              </ul>
            </Typography>
            <Typography margin={4} fontSize="18px" fontWeight={600}>
              <Stack margin={4} sx={{ paddingBottom: 2 }} spacing={2}>
                Signed At:
              </Stack>
              <Stack margin={4} spacing={2}>
                {signatureDetailsData?.data[0]?.signed_at}
              </Stack>
            </Typography>
          </>
        )}
    </>
  );
};
export default SignatoriesList;
