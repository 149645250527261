import * as changesets from "json-diff-ts";

import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";
import { handleAddEdit } from "./Components/ClauseComponent";
import { getPara } from "./utils/ClauseTypeUtils";

export default function ChangeClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    fileId,
    clauseData,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddDefinition, setShowAddDefinition] = useState(false);
  const [showAddTermination, setShowAddTermination] = useState(false);
  const [showAddNotice, setShowAddNotice] = useState(false);
  const [showAddConsent, setShowAddConsent] = useState(false);
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "change_of_control");
  }, [hasData, fileId]);

  const data = React.useMemo(
    () => clauseData?.changeOfControl && clauseData.changeOfControl,
    [clauseData?.changeOfControl]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.changeOfControl || {},
    [updatedClauseData]
  );

  const changeOfControl = React.useMemo(() => {
    if (updates?.change_of_control) {
      let sortData = updates.change_of_control.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const definitions = React.useMemo(() => {
    if (updates?.definitions) {
      let sortData = updates.definitions.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const termination = React.useMemo(() => {
    if (updates?.termination) {
      let sortData = updates.termination.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const notice = React.useMemo(() => {
    if (updates?.notice) {
      let sortData = updates.notice.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const consent = React.useMemo(() => {
    if (updates?.consent) {
      let sortData = updates.consent.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const payment = React.useMemo(() => {
    if (updates?.payment) {
      let sortData = updates.payment.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let change_of_control = changeOfControl?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let definition_data = definitions?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let notice_data = notice?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let termination_data = termination?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let consent_data = consent?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let payment_data = payment?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        change_of_control,
        definitions: definition_data,
        termination: termination_data,
        payment: payment_data,
        consent: consent_data,
        notice: notice_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [
      changeOfControl,
      definitions,
      notice,
      termination,
      consent,
      payment,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteDefinition = React.useCallback(
    (item: any) => {
      let definition_data = definitions?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        definitions: definition_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, definitions, data, fileId]
  );

  const deleteTermination = React.useCallback(
    (item: any) => {
      let termination_data = termination?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        termination: termination_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, termination, data, fileId]
  );

  const deletePayment = React.useCallback(
    (item: any) => {
      let payment_data = payment?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        payment: payment_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, payment, data, fileId]
  );

  const deleteConsent = React.useCallback(
    (item: any) => {
      let consent_data = consent?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        consent: consent_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, consent, data, fileId]
  );

  const deleteNotice = React.useCallback(
    (item: any) => {
      let notice_data = notice?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        notice: notice_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, notice, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="change of control"
          onClick={() =>
            handleAddEdit(
              props,
              "Change of Control",
              changeOfControl,
              setShowAdd
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="change_of_control"
          savedInsight={
            !isNullOrUndefined(changeOfControl) ? changeOfControl : []
          }
          savedParentClauseDataPoint={changeOfControl}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      <Scrollable maxHeight={200}>
        {changeOfControl?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "change_of_control", index });
              props.onClickHighlight(index, item, "change_of_control");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "change_of_control" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>
      {!showAddDefinition ? (
        <ClauseHeader
          title="Definition"
          buttonText="definition"
          onClick={() =>
            handleAddEdit(
              props,
              "definition",
              definitions,
              setShowAddDefinition
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Change of Control Definition"
          savedInsight={!isNullOrUndefined(definitions) ? definitions : []}
          savedParentClauseDataPoint={definitions}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddDefinition(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="change_of_control"
        />
      )}
      <Scrollable maxHeight={200}>
        {definitions?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "definition", index });
              props.onClickHighlight(
                index,
                item,
                "Change of Control Definition"
              );
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "definition" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteDefinition(item)}
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>

      {!showAddTermination ? (
        <ClauseHeader
          title="Termination"
          buttonText="termination"
          onClick={() =>
            handleAddEdit(
              props,
              "termination",
              termination,
              setShowAddTermination
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Change of Control Termination"
          savedInsight={!isNullOrUndefined(termination) ? termination : []}
          savedParentClauseDataPoint={termination}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddTermination(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="change_of_control"
        />
      )}
      <Scrollable maxHeight={200}>
        {termination?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "termination", index });
              props.onClickHighlight(
                index,
                item,
                "Change of Control Termination"
              );
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "termination" &&
              activeClause?.index === index
            }
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
            deleteClause={() => deleteTermination(item)}
          />
        ))}
      </Scrollable>

      {!showAddPayment ? (
        <ClauseHeader
          title="Payment"
          buttonText="payment"
          onClick={() =>
            handleAddEdit(props, "payment", payment, setShowAddPayment)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Change of Control Payment"
          savedInsight={!isNullOrUndefined(payment) ? payment : []}
          savedParentClauseDataPoint={payment}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddPayment(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="change_of_control"
        />
      )}
      <Scrollable maxHeight={200}>
        {payment?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "payment", index });
              props.onClickHighlight(index, item, "Change of Control Payment");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "payment" && activeClause?.index === index
            }
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
            deleteClause={() => deletePayment(item)}
          />
        ))}
      </Scrollable>

      {!showAddConsent ? (
        <ClauseHeader
          title="Consent"
          buttonText="consent"
          onClick={() =>
            handleAddEdit(props, "consent", consent, setShowAddConsent)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Change of Control Consent"
          savedInsight={!isNullOrUndefined(consent) ? consent : []}
          savedParentClauseDataPoint={consent}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddConsent(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="change_of_control"
        />
      )}
      <Scrollable maxHeight={200}>
        {consent?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "Consent", index });
              props.onClickHighlight(index, item, "Change of Control Consent");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "Consent" && activeClause?.index === index
            }
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
            deleteClause={() => deleteConsent(item)}
          />
        ))}
      </Scrollable>

      {!showAddNotice ? (
        <ClauseHeader
          title="Notice"
          buttonText="notice"
          onClick={() =>
            handleAddEdit(props, "notice", notice, setShowAddNotice)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Change of Control Notice"
          savedInsight={!isNullOrUndefined(notice) ? notice : []}
          savedParentClauseDataPoint={notice}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddNotice(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="change_of_control"
        />
      )}
      <Scrollable maxHeight={200}>
        {notice?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "Notice", index });
              props.onClickHighlight(index, item, "Change of Control Notice");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "Notice" && activeClause?.index === index
            }
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
            deleteClause={() => deleteNotice(item)}
          />
        ))}
      </Scrollable>
    </>
  );
}
