import { getLocalStorage } from "../Authentication/Actions/authentication";

const APIPaths = {
  getClauseType:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft/clause_types/",
  getClauseTerm:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft/insights/",
};

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

export const fetchClauseTypes = async (draftDataId: any) => {
  const res = await fetch(
    `${APIPaths.getClauseType}${draftDataId}/`,
    APIConfig()
  );
  return res.json();
};

export const fetchClauseTerm = async (draftDataId: any, clause_type: any) => {
  const res = await fetch(
    `${APIPaths.getClauseTerm}${draftDataId}/${clause_type}/`,
    APIConfig()
  );
  return res.json();
};

export const fetchExtractedData = async (draftDataId: any) => {
  const res = await fetch(
    `${APIPaths.getClauseTerm}${draftDataId}/extracted/`,
    APIConfig()
  );
  return res.json();
};
