import { ClauseErrorList } from "../State/clauseErrorManagementState";

export const GETCLAUSEERRORDATA = "GETCLAUSEERRORDATA";
export type GETCLAUSEERRORDATA = typeof GETCLAUSEERRORDATA;

export const GETCLAUSEERRORDATA_SUCCESS = "GETCLAUSEERRORDATA_SUCCESS";
export type GETCLAUSEERRORDATA_SUCCESS = typeof GETCLAUSEERRORDATA_SUCCESS;

export const GETCLAUSEERRORDATA_FAILURE = "GETCLAUSEERRORDATA_FAILURE";
export type GETCLAUSEERRORDATA_FAILURE = typeof GETCLAUSEERRORDATA_FAILURE;

export interface GetClauseErrorData {
  type: GETCLAUSEERRORDATA;
  payload: {
    fileId: string;
  };
}
export interface GetClauseErrorDataSuccess {
  type: GETCLAUSEERRORDATA_SUCCESS;
  payload: {
    clauseErrorData: ClauseErrorList[];
  };
}
export interface GetClauseErrorDataFailure {
  type: GETCLAUSEERRORDATA_FAILURE;
}

export type ClauseErrorManagementAction =
  | GetClauseErrorData
  | GetClauseErrorDataSuccess
  | GetClauseErrorDataFailure;
