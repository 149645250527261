import { authApi } from "./authApi";

export const fetchStamps = async (params = ""): Promise<any> => {
  return await authApi.get(`stylus/stamp-bank/${params}`);
};

export const fetchState = async () => {
  return await authApi.get("stylus/state/");
};

export const postStamp = async (payload: any) => {
  return await authApi.post("stylus/stamp-bank/", payload);
};

export const postStampFile = async (payload: any) => {
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });
  return await authApi.post("stylus/stamp-bank/upload-file/", formData);
};

export const deleteStamp = async (id: string) => {
  return await authApi.delete(`stylus/stamp-bank/${id}/`);
};

export const fetchStampDenominations = async (params = ""): Promise<any> => {
  return await authApi.get(`stylus/stamp-bank/state-denomination/${params}`);
};

export const addStampForDraft = async (payload: any) => {
  return await authApi.post("stylus/stamp-bank/draft-stamp/", payload);
};
