import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import {
  editDraftDataExternal,
  fetchExternalChecklistData,
  updateExternalChecklist,
} from "../../../ExternalUserFlow/Services/Draft";
import {
  addNewChecklist,
  editDraftData,
  fetchChecklistData,
  fetchCollaborators,
  updateActiveStatus,
  updateChecklist,
} from "../../../Services/Draft";

import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";
import AddNewChecklist from "./AddNewChecklist";
import AssignDialog from "./AssignDialog";
import Checklist from "./Checklist";
import DeleteDialog from "./DeleteDialog";
import UploadDialog from "./UploadDialog";

interface Props {
  selectedDraftData: any;
  isExternal?: boolean;
}

const CheckListComponent: React.FC<Props> = ({
  selectedDraftData,
  isExternal,
}) => {
  const draftId = selectedDraftData?.draftID;
  const contractTypeId = selectedDraftData?.contractType?.id;

  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [deleteChecklistId, setDeleteChecklistId] = useState<string>("");
  const [checklistId, setChecklistId] = useState<string>("");
  const [passPropData, setPassPropData] = useState<any>({});
  const [supportDocList, setSupportDocList] = useState<any[]>([]);
  const [collaborators, setCollaborators] = useState<any>([]);
  const [deleteData, setDeleteData] = useState<any>({});
  const [checklistItemData, setChecklistItemData] = useState<any>({});

  const queryClient = useQueryClient();
  const { handleSubmit, control, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const user_data = useMemo(() => getLocalStorage("user_profile"), []);

  const userIsOwner = useMemo(
    () =>
      selectedDraftData?.owners?.find(
        (owner: any) => owner.id === user_data.id
      ),
    [selectedDraftData, user_data]
  );

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setChecklistItemData({});
    setChecklistId("");
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteChecklistId("");
    setDeleteData({});
  };

  const handleCloseAssignDialog = () => {
    setOpenAssignDialog(false);
  };

  const { data: getDraftCheckList, isLoading: isLoadingCheckList } = useQuery({
    queryKey: ["draft_checkList"],
    queryFn: async () => {
      const response = isExternal
        ? await fetchExternalChecklistData(draftId)
        : await fetchChecklistData(draftId);
      return response?.results;
    },
  });

  const { data: approversData } = useQuery({
    queryKey: ["Approvers_list"],
    queryFn: async () => {
      const response = await fetchCollaborators();
      let groups = response.results.map((data: any) => ({
        ...data,
        name: data.first_name + " " + data.last_name,
      }));
      const filteredApproversList = groups.filter((data: any) => {
        return data?.groups?.some((element: string) => {
          if (selectedDraftData?.groups?.includes(element)) return data;
        });
      });
      return filteredApproversList;
    },
    enabled: !isExternal,
  });

  const { mutate: updateDraftData } = useMutation({
    mutationFn: isExternal ? editDraftDataExternal : editDraftData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: isExternal
          ? ["get_draft_by_id_for_external"]
          : ["get_draft_by_id"],
      });
      enqueueSnackbar("Successfully updated draft data!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
    onError: () => {
      enqueueSnackbar("Failed to update draft data!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  useEffect(() => {
    if (getDraftCheckList?.length) {
      const listOfActiveStatus = getDraftCheckList?.filter(
        (item: any) => item?.active_status === true
      );
      setChecked(listOfActiveStatus);
    } else {
      setChecked([]);
    }
  }, [getDraftCheckList]);

  useEffect(() => {
    let collaborator = selectedDraftData?.collaborators?.map(
      (data: any) => data.id
    );
    setCollaborators(collaborator || []);
  }, [selectedDraftData]);

  const { mutate: updateChecklistItem, isPending: loadingUpdateItem } =
    useMutation({
      mutationFn: isExternal ? updateExternalChecklist : updateChecklist,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["draft_checkList"],
        });
        queryClient.invalidateQueries({
          queryKey: ["drafts"],
        });
        handleCloseAssignDialog();
        enqueueSnackbar("Checklist item updated successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        const payload = {
          id: selectedDraftData?.id,
          body: {
            collaborators,
          },
        };
        updateDraftData(payload);
      },
      onError: () => {
        enqueueSnackbar("Failed to update checklist item!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    });

  const { mutate: addChecklistItem, isPending: loadingAddCheckList } =
    useMutation({
      mutationFn: addNewChecklist,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["draft_checkList"],
        });
        queryClient.invalidateQueries({
          queryKey: ["drafts"],
        });
        reset();
        if (openAssignDialog) handleCloseAssignDialog();
        if (openDeleteDialog) handleCloseDeleteDialog();
        enqueueSnackbar("New checklist item added successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: () => {
        enqueueSnackbar("Failed to add new checklist item!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    });

  const { mutate: partialUpdateDraft, isPending } = useMutation({
    mutationKey: ["update_draft_partialy", openDeleteDialog],
    mutationFn: updateActiveStatus,
    onSuccess() {
      enqueueSnackbar("Checklist updated successfully!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      if (openDeleteDialog) {
        handleCloseDeleteDialog();
      }
      queryClient.invalidateQueries({
        queryKey: ["draft_checkList"],
      });
      queryClient.invalidateQueries({
        queryKey: ["drafts"],
      });
    },
  });

  const handleAddCheckList = (payload: any) => {
    if (userIsOwner) {
      addChecklistItem({ ...payload, draft: draftId });
    } else {
      enqueueSnackbar("You are not owner of this draft", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  };

  const handleActiveStatus = (value: boolean, body: any) => {
    if (isExternal) return;
    if (body.id) {
      const payload = {
        id: body?.id,
        body: {
          active_status: value,
        },
      };
      partialUpdateDraft(payload);
    } else {
      const payload = {
        ...body,
        active_status: true,
        draft: draftId,
      };
      addChecklistItem(payload);
    }
  };

  const handleAssignee = (body: any) => {
    if (body?.assignee_type === "internal") {
      let index = collaborators?.findIndex(
        (collaborator: any) => collaborator === body.id
      );
      if (index === -1) {
        setCollaborators((prev: any) => [...prev, body.id]);
      }
    }
    if (passPropData?.id) {
      const payload = {
        id: passPropData?.id,
        body: {
          active_status: passPropData?.active_status,
          draft: passPropData?.draft,
          checklist: passPropData?.checklist?.id,
          created_by: passPropData?.created_by,
          ...body,
        },
      };
      updateChecklistItem(payload);
    } else {
      const payload = {
        ...body,
        draft: draftId,
        ...passPropData,
      };
      addChecklistItem(payload);
    }
  };

  const handleDeleteChecklist = (id: string) => {
    if (id) {
      const payload = {
        id: id,
        body: {
          deleted_status: true,
        },
      };
      partialUpdateDraft(payload);
    } else {
      const payload = {
        ...deleteData,
        draft: draftId,
        deleted_status: true,
      };
      addChecklistItem(payload);
    }
  };

  const handleOpenDeleteDialog = (id: string, data: any) => {
    setOpenDeleteDialog(true);
    setDeleteChecklistId(id);
    setDeleteData(data);
  };

  const handleOpenAssigneeDialog = (data: any) => {
    setOpenAssignDialog(true);
    setPassPropData(data);
  };

  const handleOpenUploadDialog = (listItem: any) => {
    const supportDocArray = listItem?.support_doc?.map((item: any) => {
      const { file_name, ...otherDetails } = item;
      return {
        name: item?.file_name,
        ...otherDetails,
      };
    });
    setSupportDocList(supportDocArray);
    setChecklistId(listItem?.id);
    setChecklistItemData(listItem);
    setOpenUploadDialog(true);
  };

  return (
    <React.Fragment>
      <Stack spacing={2} margin="15px">
        {!isExternal && (
          <Accordion
            sx={{
              background: "#88305F24",
              boxShadow: "none",
              borderRadius: "5px",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack direction="row" spacing={1}>
                <AddIcon />
                <Typography>Add another checklist item</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <AddNewChecklist
                handleSubmit={handleSubmit}
                control={control}
                contractTypeId={contractTypeId}
                handleAddCheckList={handleAddCheckList}
                loadingAddCheckList={loadingAddCheckList}
                loadingCreateCheckList={loadingAddCheckList}
              />
            </AccordionDetails>
          </Accordion>
        )}
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography fontSize="14px">
            Upload files against each to complete items
          </Typography>

          <Typography fontSize="14px">
            {checked?.length}/{getDraftCheckList?.length || 0} completed
          </Typography>
        </Grid>
        {isLoadingCheckList ? (
          <LinearProgress />
        ) : (
          <Checklist
            setOpenUploadDialog={setOpenUploadDialog}
            handleOpenUploadDialog={handleOpenUploadDialog}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            handleOpenAssigneeDialog={handleOpenAssigneeDialog}
            handleActiveStatus={handleActiveStatus}
            checklistData={getDraftCheckList}
            isExternal={isExternal}
            userIsOwner={!userIsOwner}
          />
        )}
      </Stack>

      {openAssignDialog && (
        <AssignDialog
          open={openAssignDialog}
          onClose={handleCloseAssignDialog}
          propsData={passPropData}
          handleAssignee={handleAssignee}
          loadingUpdateItem={loadingUpdateItem}
          approversData={approversData}
        />
      )}
      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          handleDeleteChecklist={handleDeleteChecklist}
          deleteChecklistId={deleteChecklistId}
          loadingDeleteItem={isPending}
        />
      )}
      {openUploadDialog && (
        <UploadDialog
          open={openUploadDialog}
          onClose={handleCloseUploadDialog}
          checklistId={checklistId}
          documentList={supportDocList}
          isExternal={isExternal}
          checklistItemData={checklistItemData}
          draftId={draftId}
        />
      )}
    </React.Fragment>
  );
};

export default CheckListComponent;
