import { defaultPageSize } from "../../Constants/const";

export default interface SearchState {
  searchResult: SearchResult[];
  searchLoader: boolean;
  pageSize: number;
  count: number;
  uniqueFileIds: string[];
  countLoader: boolean;
}

export interface SearchResult {
  id: string;
  tilte: string;
  para: string;
  uploaded_on: string;
  uploaded_by: string;
}

export function defaultSearchResult(): SearchResult {
  return {
    id:"",
    tilte: "",
    uploaded_on: "",
    uploaded_by: "",
    para:""
  };
}
export function defaultSearchState(): SearchState {
  return {
    searchResult: [],
    searchLoader: false,
    pageSize: defaultPageSize,
    count: 0,
    uniqueFileIds: [],
    countLoader: false,
  };
}
