export const UPLOADING = "Uploading";
export const UPLOADED = "Uploaded";
export const FAILED = "Failed";
export const PROCESSING = "Processing";
export const DONE = "Done";
export const defaultPageSize = 15;

export const reminderFrequencyType: ReminderFrequencyType = {
  Never: "never",
  Everyday: "EVERYDAY",
  "Every Week": "every_week",
  "Every Month": "every_month",
};

export const reminderFrequencyTypeKey: ReminderFrequencyType = {
  NEVER: "Never",
  EVERYDAY: "Everyday",
  EVERY_WEEK: "Every Week",
  EVERY_MONTH: "Every Month",
};

export interface ReminderFrequencyType {
  [key: string]: string;
  // [key1: string]: string;
  // [key2: string]: string;
  // [key3: string]: string;
}

export interface contractType {
  contractType: string;
  displayName: string;
}

export const Blank_Document_link = {
  TEMPLATE_BLANK_DOC:
    "https://riverus-clm-testing-stylus1.s3.us-west-2.amazonaws.com/requisition_forms/requisition_approval_form_template.docx",
  SUPPORT_BLANK_DOC:
    "https://riverus-clm-testing-stylus1.s3.us-west-2.amazonaws.com/blank_docs/support+document%2Ccontract+party+draft+-+templates.docx",
    NOTES_BLANK_DOC:"/blank_docs/notes_document.docx"
};

