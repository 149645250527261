import React, { useEffect, useRef, useMemo, useState } from "react";

import WebViewer from "@pdftron/webviewer";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { draftStatus } from "../State/DraftState";
import { getTodayDate } from "./Helper";

const DocxViewerComponent = (props: any) => {
  const user_data = useMemo(() => getLocalStorage("user_profile"), []);
  const external_email = useMemo(() => getLocalStorage("external_email"), []);

  const documentContainer = useRef<HTMLDivElement>(null);
  const {
    fileLink,
    setInstance,
    dragOver,
    setAddedSignatureFields,
    drop,
    draftDetails,
    instance,
    isExternal,
    setDocumentLoading,
  } = props;

  const [signatureAnnot, setSignatureAnnot] = useState<any | null>();

  useEffect(() => {
    if (fileLink && documentContainer.current) {
      setDocumentLoading?.(true);

      WebViewer.WebComponent(
        {
          path: "/webviewer",
          fullAPI: true,
          initialDoc: fileLink,
          css: "/styles/webviewer.css",
          disabledElements: [
            "header",
            "toolsHeader",
            "linkButton",
            "annotationCommentButton",
            "contextMenuPopup",
          ],
          licenseKey:
            "Riverus Technology Solutions Pvt. Ltd (riverus.in):OEM:Riverus::B+:AMS(20240530):A65548BC0477C80A0360B13AB9C2527160610FEBB9361A37E36C05DA5CF50ED654BA31F5C7",
        },
        documentContainer.current
      ).then((instance) => {
        const { UI, Core } = instance;
        setInstance(instance);
        const { documentViewer, Annotations, annotationManager } = Core;

        instance.UI.annotationPopup.update([{ type: "ac" }]);

        const callback = () => {
          // unsubscribe immediatly after invoking
          documentViewer.removeEventListener("documentLoaded", callback);
        };

        documentViewer.addEventListener("documentLoaded", async () => {
          var FitMode = instance.UI.FitMode;
          instance.UI.setFitMode(FitMode.FitWidth);
          setDocumentLoading?.(false);
          callback();
        });

        const doc = documentContainer.current;
        if (doc) {
          doc.addEventListener("dragover", dragOver);
          doc.addEventListener("drop", (e: any) => {
            drop(e, documentViewer);
          });
        }

        Annotations.setCustomCreateSignHereElementHandler(function (
          tool,
          { annotation, originalCreateSignHereElement }
        ) {
          const signHereElement = originalCreateSignHereElement(tool);
          signHereElement.innerHTML = "Click Here To Sign";
          return signHereElement;
        });

        annotationManager.addEventListener(
          "annotationChanged",
          (annotations, action, { imported }) => {
            if (draftDetails.status === draftStatus.SIGNATURE_PENDING) {
              annotations.forEach((annot: any) => {
                annot.NoMove = true;
                annot.NoResize = true;
                annot.NoRotate = true;
                annot.ReadOnly = true;
              });
            }

            annotations.forEach((annot: any) => {
              if (annot.Subject?.toLowerCase() === "signature") {
                annot.NoMove = true;
                annot.NoResize = true;
                annot.NoRotate = true;
                annot.setCustomData(
                  "user",
                  annot.getCustomData("user") || user_data.id
                );
                if (!annot.getCustomData("signedDate")) {
                  annot.setCustomData("signedDate", getTodayDate());
                  setSignatureAnnot(annot);
                }
              }
            });

            if (imported && action === "add") {
              annotations.forEach(function (annot: any) {
                if (annot instanceof Annotations.WidgetAnnotation) {
                  if (isExternal) {
                    if (annot.getCustomData("user") !== external_email) {
                      annot.Hidden = true;
                      annot.Listable = false;
                    }
                  } else if (annot.getCustomData("user") !== user_data.id) {
                    annot.Hidden = true;
                    annot.Listable = false;
                  }
                }
              });
            }
          }
        );

        if (!draftDetails?.open_collab) {
          UI.disableElements(["textPopup"]);
        }
      });
    }
  }, [
    fileLink,
    documentContainer,
    setInstance,
    dragOver,
    draftDetails,
    drop,
    setAddedSignatureFields,
    user_data,
    isExternal,
    external_email,
    setDocumentLoading,
  ]);

  useEffect(() => {
    if (!instance) return;
    if (signatureAnnot) {
      const { Annotations, documentViewer } = instance.Core;
      const annotationManager = documentViewer.getAnnotationManager();

      const id = isExternal ? external_email : user_data.id;
      annotationManager
        .getAnnotationsList()
        .filter(
          (annot: any) =>
            annot instanceof Annotations.SignatureWidgetAnnotation &&
            annot.getCustomData("user") === id
        )
        .forEach((annot: any) => {
          if (annot.isSignedByAppearance()) return;
          const annotCopy = annotationManager.getAnnotationCopy(signatureAnnot);
          annotCopy.PageNumber = annot.getPageNumber();
          annotCopy.X = annot.getX();
          annotCopy.Y = annot.getY();
          annotationManager.addAnnotation(annotCopy);
          annot.sign(annotCopy);
        });

      setSignatureAnnot(null);
    }
  }, [external_email, instance, isExternal, signatureAnnot, user_data.id]);

  return (
    <div
      ref={documentContainer}
      id="document-viewer"
      style={{ height: "82vh" }}
    />
  );
};

export default DocxViewerComponent;
