import "./Design/index";

import * as serviceWorker from "./serviceWorker";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { applyMiddleware, createStore } from "redux";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import App from "./App/Container/appCon";
import ClausErrorManagementWatcher from "./UniversalComponents/Modals/DocumentLibraryClauseErrorModal/Saga/clauserrorManagementSaga";
import ClauseComponentWatcher from "./DocumentView/Component/ClauseComponent/Saga/clauseComponentSaga";
import DocumentWatcher from "./DocumentView/Saga/documentSaga";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import SnackbarCloseButton from "./RiverusUI/Components/SnakbarCloseButton";
import { SnackbarProvider } from "notistack";
import TaskManagementWatcher from "./UniversalComponents/Modals/TaskManagementModal/Saga/taskManagementSaga";
import { ThemeProvider } from "@mui/material";
import aggregateWatcher from "./Aggregates/Saga/AggregatesSaga";
import analysisWatcher from "./Analysis/Saga/analysisSaga";
import clauseLibraryWatcher from "./ClauseLibrary/Saga/clauseLibrarySaga";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "@redux-saga/core";
import dashboardWatcher from "./Dashboard/Saga/dashboardSaga";
import documentLibraryWatcher from "./DocumentLibrary/Saga/documentLibrarySaga";
import heimdallWatcher from "./UniversalComponents/HeimdallChild/Saga/heimdallSaga";
import historyWatcher from "./History/Saga/historySaga";
import { mainReducer } from "./Utils/MainReducer/mainReducer";
import newAnalysisWatcher from "./NewAnalysis/Saga/newAnalysisSaga";
import newDashboardWatcher from "./NewDashboard/Saga/newDashboardSaga";
import notificationWatcher from "./UniversalComponents/Notification/Saga/notificationSaga";
import reportsWatcher from "./Reports/Saga/reportsSaga";
import searchModuleWatcher from "./SearchModule/Saga/searchModuleSaga";
import searchPageWatcher from "./Search/Saga/SearchSaga";
import taskManagementPageWatcher from "./TaskManagement/Saga/taskManagementPageSaga";
import theme from "./RiverusUI/Theme/theme";
import uploadWatcher from "./Upload/Saga/uploadSaga";

const sagaMiddleWare = createSagaMiddleware();
export const store = createStore(
  mainReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleWare))
);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

sagaMiddleWare.run(heimdallWatcher);
sagaMiddleWare.run(uploadWatcher);
sagaMiddleWare.run(DocumentWatcher);
sagaMiddleWare.run(searchPageWatcher);
sagaMiddleWare.run(searchModuleWatcher);
sagaMiddleWare.run(historyWatcher);
sagaMiddleWare.run(analysisWatcher);
sagaMiddleWare.run(dashboardWatcher);
sagaMiddleWare.run(aggregateWatcher);

sagaMiddleWare.run(TaskManagementWatcher);

sagaMiddleWare.run(documentLibraryWatcher);
sagaMiddleWare.run(reportsWatcher);
sagaMiddleWare.run(taskManagementPageWatcher);
sagaMiddleWare.run(clauseLibraryWatcher);
sagaMiddleWare.run(notificationWatcher);
sagaMiddleWare.run(newAnalysisWatcher);
sagaMiddleWare.run(newDashboardWatcher);
sagaMiddleWare.run(ClausErrorManagementWatcher);
sagaMiddleWare.run(ClauseComponentWatcher);

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider
            maxSnack={5}
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
          >
            <App />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
