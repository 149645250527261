import { Button, Stack, StackProps, Typography } from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { getPara } from "./utils/ClauseTypeUtils";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import { handleSeeMoreAndLess } from "../../../Draft/Component/Helper";

interface IProps extends StackProps {
  clauseItem: any;
  onClick: VoidFunction;
  index: number;
  sentenceData?: any;
  isActive: boolean;
  deleteClause: VoidFunction;
  subTitle?: string;
  sentenceLevel?: boolean;
  phraseLevel?: boolean;
  para?: string;
}

const ClauseCard: FC<IProps> = ({
  clauseItem,
  index,
  sentenceData,
  isActive,
  deleteClause,
  subTitle,
  sentenceLevel,
  phraseLevel,
  para,
  ...rest
}) => {
  const [showFullText, setShowFullText] = useState<any>({
    id: "",
    show: false,
  });

  const handleToggle = (id: string) => {
    setShowFullText({
      id: id,
      show: !showFullText?.show,
    });
  };

  const getText = useCallback(() => {
    if (para) {
      return para;
    } else if (sentenceLevel) {
      return (
        clauseItem.sentence ||
        getPara(
          sentenceData,
          clauseItem.para_id,
          clauseItem.start_sentence_id,
          clauseItem.table && clauseItem.table[0]?.row,
          clauseItem.table && clauseItem.table[0]?.column
        )
      );
    } else if (phraseLevel) {
      return clauseItem.phrase;
    } else {
      return (
        clauseItem?.para ||
        getPara(
          sentenceData,
          clauseItem.para_id,
          clauseItem.table && clauseItem.table[0]?.row,
          clauseItem.table && clauseItem.table[0]?.column
        )
      );
    }
  }, [clauseItem, phraseLevel, sentenceData, sentenceLevel, para]);

  return (
    <Stack
      sx={{
        background: isActive ? "#FFFAFA" : "#FFF7FA",
        padding: "8px 16px",
        margin: "8px 0",
        borderRadius: "12px",
        border: isActive ? "1px solid #88305F" : "none",
        cursor: "pointer",
      }}
      gap="8px"
      {...rest}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography fontWeight="600" fontSize="14px">{`#${
          index + 1
        }`}</Typography>
        <Button
          sx={{ padding: 0 }}
          onClick={deleteClause}
          startIcon={<DeleteSweepOutlinedIcon />}
        >
          Remove
        </Button>
      </Stack>
      {subTitle && (
        <Typography variant="body1" fontWeight={600}>
          {subTitle}
        </Typography>
      )}
      <Typography variant="body1">
        {showFullText?.id === clauseItem?.para_id && showFullText?.show
          ? getText()
          : handleSeeMoreAndLess(getText())}
        {getText()?.split(/\s+/)?.length > 20 && (
          <span
            onClick={() => handleToggle(clauseItem?.para_id)}
            style={{ cursor: "pointer" }}
          >
            {showFullText?.id === clauseItem?.para_id && showFullText?.show
              ? " See less"
              : "See more"}
          </span>
        )}
      </Typography>
    </Stack>
  );
};

export default ClauseCard;
