import AxiosGateWay from "../../../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import axios from "axios";
import {
  getKeyCloakRealmFromLS,
  getLocalStorage,
} from "../../../../Authentication/Actions/authentication";
import { SITE_API_BY_REALM_NAME } from "../../../../Configuration/global";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

export default class ClauseComponentApi {
  public static getClausesByType = async (
    fileID: string,
    clauseType: string
  ) => {
    const accessToken = await getLocalStorage("accessToken");
    const URL = `${SITEAPI}insights/${fileID}/${clauseType}/`;
    return axios
      .get(URL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => response)
      .catch((error) => console.error(error));
  };

  public static postClauseDataByType = async (
    fileID: string,
    type: string,
    diff: any
  ) => {
    const URL = `${SITEAPI}insights/${fileID}/${type}/`;
    return AxiosGateWay.patch(URL, diff)
      .then((response) => response)
      .catch((error) => console.error(error));
  };
}
