import * as changesets from "json-diff-ts";

import { CLAUSE_DATA } from "./utils/ClauseTypeUtils";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";

export default function TermClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddStartDates, setShowAddStartDates] = useState(false);
  const [showAddEndDates, setShowAddEndDates] = useState(false);
  const [showAddDuration, setShowAddDuration] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "term");
  }, [fileId, hasData]);

  const data = React.useMemo(() => {
    if (clauseData?.term) {
      let term = clauseData.term;
      if (!clauseData.term?.raw_content.term) {
        term = {
          ...term,
          raw_content: {
            ...term.raw_content,
            term: [],
          },
        };
      }
      if (!clauseData.term?.raw_content.end) {
        term = {
          ...term,
          raw_content: {
            ...term.raw_content,
            end: [],
          },
        };
      }
      if (!clauseData.term.raw_content.start) {
        term = {
          ...term,
          raw_content: {
            ...term.raw_content,
            start: [],
          },
        };
      }
      if (!clauseData.term.raw_content.duration) {
        term = {
          ...term,
          raw_content: {
            ...term.raw_content,
            duration: [],
          },
        };
      }
      return term;
    }
  }, [clauseData?.term]);

  const updates = useMemo(
    () => updatedClauseData?.term || {},
    [updatedClauseData]
  );

  const term = useMemo(() => {
    if (updates?.term) {
      let sortData = updates.term.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const duration = useMemo(() => {
    if (updates?.duration) {
      let sortData = updates.duration.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const start_date = useMemo(() => {
    if (updates?.start) {
      let sortData = updates.start.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const end_date = useMemo(() => {
    if (updates?.end) {
      let sortData = updates.end.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const effective_date = useMemo(() => {
    if (updates?.effective_date) {
      let sortData = updates.effective_date.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = useCallback(
    (item: any) => {
      let term_parent = term?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let term_duration = duration?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let term_start_date = start_date?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.sentence_id
      );
      let term_end_date = end_date?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let updatedData = {
        ...updates,
        term: term_parent,
        duration: term_duration,
        start: term_start_date,
        end: term_end_date,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "term", diff, updatedData);
      }
    },
    [
      term,
      duration,
      start_date,
      end_date,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteStartDate = useCallback(
    (item: any) => {
      let term_start_date = start_date?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        start: term_start_date,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "term", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, start_date, data, fileId]
  );

  const deleteEndDate = useCallback(
    (item: any) => {
      let term_end_date = end_date?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        end: term_end_date,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "term", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, end_date, data, fileId]
  );

  const deleteDuration = useCallback(
    (item: any) => {
      let term_duration = duration?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        duration: term_duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "term", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, duration, data, fileId]
  );

  return (
    <>
      {showAdd ? (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="term"
          savedInsight={!isNullOrUndefined(term) ? term : []}
          savedParentClauseDataPoint={term}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      ) : (
        <ClauseHeader
          title="Clause Text"
          buttonText="term clause"
          onClick={() => handleAddEdit(props, "Term clause", term, setShowAdd)}
        />
      )}
      <Scrollable maxHeight={200}>
        {term?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "term", index });
              props.onClickHighlight(index, item, "Term clause");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === "term" && activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
          />
        ))}
      </Scrollable>
      {!showAddStartDates ? (
        <ClauseHeader
          title="Start Date"
          buttonText="start date"
          onClick={() =>
            handleAddEdit(
              props,
              "Term Start Dates",
              start_date,
              setShowAddStartDates
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Term Start Dates"
          savedInsight={!isNullOrUndefined(start_date) ? start_date : []}
          savedParentClauseDataPoint={start_date}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAddStartDates(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="term"
        />
      )}
      {start_date?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "start_date", index });
            props.onClickHighlight(
              index,
              item,
              CLAUSE_DATA.term_clause["start_date"]
            );
          }}
          index={index}
          clauseItem={item}
          sentenceData={props?.sentenceData}
          isActive={
            activeClause?.type === "start_date" && activeClause?.index === index
          }
          deleteClause={() => deleteStartDate(item)}
          subTitle={item?.normalized_date}
        />
      ))}
      {!showAddEndDates ? (
        <ClauseHeader
          title="End Date"
          buttonText="end date"
          onClick={() =>
            handleAddEdit(props, "Term End Dates", end_date, setShowAddEndDates)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Term End Dates"
          savedInsight={!isNullOrUndefined(end_date) ? end_date : []}
          savedParentClauseDataPoint={end_date}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAddEndDates(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="term"
        />
      )}
      {end_date?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "end_date", index });
            props.onClickHighlight(
              index,
              item,
              CLAUSE_DATA.term_clause["end_date"]
            );
          }}
          index={index}
          clauseItem={item}
          sentenceData={props?.sentenceData}
          isActive={
            activeClause?.type === "end_date" && activeClause?.index === index
          }
          deleteClause={() => deleteEndDate(item)}
          subTitle={item?.normalized_date}
        />
      ))}
      {!showAddDuration ? (
        <ClauseHeader
          title="Duration"
          buttonText="duration"
          onClick={() =>
            handleAddEdit(props, "Term Duration", duration, setShowAddDuration)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Term Duration"
          savedInsight={!isNullOrUndefined(duration) ? duration : []}
          savedParentClauseDataPoint={duration}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAddDuration(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="term"
        />
      )}
      {duration?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "duration", index });
            props.onClickHighlight(
              index,
              item,
              CLAUSE_DATA.term_clause["duration"]
            );
          }}
          index={index}
          clauseItem={item}
          sentenceData={props?.sentenceData}
          isActive={
            activeClause?.type === "duration" && activeClause?.index === index
          }
          deleteClause={() => deleteDuration(item)}
          subTitle={item?.date}
        />
      ))}
    </>
  );
}
