import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import React from "react";
import { useSnackbar } from "notistack";

function SnackbarCloseButton({
  snackbarKey,
}: {
  snackbarKey: number | string;
}) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      onClick={() => closeSnackbar(snackbarKey)}
      sx={{ color: "white" }}
    >
      <CloseIcon />
    </IconButton>
  );
}

export default SnackbarCloseButton;
