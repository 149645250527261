import * as changesets from "json-diff-ts";

import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function ExclusivityClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "exclusivity");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.exclusivity && clauseData.exclusivity,
    [clauseData?.exclusivity]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.exclusivity || {},
    [updatedClauseData]
  );

  const exclusivity = React.useMemo(() => {
    if (updates?.exclusivity) {
      let sortData = updates.exclusivity.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let value = exclusivity?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        exclusivity: value,
      };
      const diff = changesets.diff(data?.raw_content, updatedData);
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "exclusivity", diff, updatedData);
      }
    },
    [data, fileId, exclusivity, postClauseDataByType]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="exclusivity"
          onClick={() =>
            handleAddEdit(props, "exclusivity", exclusivity, setShowAdd)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="exclusivity"
          savedInsight={!isNullOrUndefined(exclusivity) ? exclusivity : []}
          savedParentClauseDataPoint={exclusivity}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      {exclusivity?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "exclusivity", index });
            props.onClickHighlight(index, item, "exclusivity");
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === "exclusivity" &&
            activeClause?.index === index
          }
          deleteClause={() => deleteClause(item)}
          para={
            item.para ||
            getPara(
              props?.sentenceData,
              item.para_id,
              item.sentence_id,
              item.table && item.table[0]?.row,
              item.table && item.table[0]?.column
            )
          }
        />
      ))}
    </>
  );
}
