import colors from "./colors";

const checkboxTheme= {
  styleOverrides: {
    root: {
      "&.Mui-checked":{
        color:colors?.riPrimary?.main ,
      }
    },
  },
}
export default checkboxTheme;
