import axios from "axios";
import { getLocalStorage } from "../Authentication/Actions/authentication";
import { useKeycloak } from "@react-keycloak/web";

const BASE_URL = process.env.REACT_APP_RIVERUS_CLM_API;

export const authApi = axios.create({
  baseURL: BASE_URL,
});

authApi.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = getLocalStorage("accessToken");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

authApi.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (error) => {
    const { keycloak } = useKeycloak();

    const originalRequest = error.config;
    const errMessage = error.response.data.message as string;
    if (error.response.status === 401) {
      keycloak.logout();
    }
    if (errMessage.includes("not logged in") && !originalRequest._retry) {
      originalRequest._retry = true;
      return authApi(originalRequest);
    }
    return Promise.reject(error);
  }
);
