import React, { FC, useCallback, useState } from "react";
import CustomModal from "../RiverusUI/Components/CustomModal";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postStampFile } from "../Services/Stamp";
import { useSnackbar } from "notistack";
import UploadDocComponent from "../RiverusUI/Components/UploadDocComponent";

interface IProps {
  open: boolean;
  onClose: VoidFunction;
}

const UploadStamp: FC<IProps> = (props) => {
  const { open, onClose } = props;
  const [files, setFile] = useState<any>([]);
  const methods = useForm();
  const { handleSubmit, control } = methods;

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: addStampMutation } = useMutation({
    mutationKey: ["add-stamp"],
    mutationFn: postStampFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stamp-bank-list"] });
      queryClient.invalidateQueries({ queryKey: ["stamp-bank"] });
      onClose();
    },
    onError: () => {
      const message = "Failed to Upload File!";
      enqueueSnackbar(message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const onSubmit = useCallback(() => {
    addStampMutation({ file: files[0] });
  }, [addStampMutation, files]);

  return (
    <CustomModal
      maxWidth="md"
      title="Upload a CSV file"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ minWidth: "600px", padding: "30px 10px" }} gap={2}>
            <UploadDocComponent
              label="Upload CSV File"
              control={control}
              name="stamp-file"
              allowedFileTypes={[".csv"]}
              files={files}
              setFiles={setFile}
            />
            <Button
              variant="contained"
              type="submit"
              disabled={files.length === 0}
            >
              Submit
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default UploadStamp;
