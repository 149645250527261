interface formControlLabelProps {
  styleOverrides?: {};
  defaultProps?: {};
}

const formControlLabelTheme:formControlLabelProps = {
  styleOverrides: {
    root: {
      textTransform: "capitalize",
      padding: 2,
    },
    label: {
      marginLeft: "10px",
    },
  },
};

export default formControlLabelTheme;
