import * as changesets from "json-diff-ts";

import React, { useEffect, useState } from "react";
import { getPara } from "./utils/ClauseTypeUtils";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { handleAddEdit } from "./Components/ClauseComponent";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function NoticeClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "notice");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.notice && clauseData.notice,
    [clauseData]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.notice || {},
    [updatedClauseData]
  );

  const notice = React.useMemo(() => {
    if (updates?.notice) {
      let sortData = updates.notice.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let value = notice?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        notice: value,
      };
      const diff = changesets.diff(data?.raw_content || {}, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "notice", diff, updatedData);
      }
    },
    [updates, data, fileId, notice, postClauseDataByType]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="notice"
          onClick={() => handleAddEdit(props, "notice", notice, setShowAdd)}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={props.toBeEdited}
          savedInsight={notice ? notice : []}
          savedParentClauseDataPoint={notice}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}

      <Scrollable maxHeight={200}>
        {notice?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "notice", index });
              props.onClickHighlight(index, item, "notice");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "notice" && activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>
    </>
  );
}
