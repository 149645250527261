import { Box, Button, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  fetchContractPreSignUrl,
  fetchSignatoriesList,
  updateSignatoriesList,
} from "../../../Services/signatureTab";
import {
  fetchContractPreSignUrlExternal,
  fetchSignatoriesListExternal,
  getS3PresignedUrlExternal,
  updateSignatoriesListExternal,
  upload_file_in_s3_bucket_external,
} from "../../../ExternalUserFlow/Services/Draft";
import {
  getAuthCredentials,
  updateWithSignRefreshToken,
} from "../../../Services/signatureTab";
import {
  getS3PresignedUrl,
  upload_file_in_s3_bucket,
} from "../../../Services/Draft";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import AddSignatories from "./AddSignatories";
import AddedSignatoriesList from "./AddedSignatoriesList";
import CryptoJS from "crypto-js";
import ListSkeleton from "../../../RiverusUI/Components/Skeleton/ListSkeleton";
import ReusableConfirmationModal from "../../../RiverusUI/Components/ReusableConfirmationModal";
import SendIcon from "@mui/icons-material/Send";
import SignatoriesList from "./SignatoriesList";
import SignatureMethod from "./SignatureMethod";
import colors from "../../../RiverusUI/Theme/colors";
import { draftStatus } from "../../State/DraftState";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";
import { isRiverusAdmin } from "../../../DataDictionary/DDUtils";
import { useSnackbar } from "notistack";
import { userTypes } from "./StaticData";
import { applyFields, removeAnnotation } from "../Helper";
import StampPaper from "./StampPaper";

interface Props {
  draftData: any;
  instance: any;
  dropPoint: any;
  addedSignatureFields: boolean;
  setAddedSignatureFields: Dispatch<SetStateAction<boolean>>;
  updateDraftData: any;
  setRedirect?: Dispatch<SetStateAction<string>>;
  isExternal?: boolean;
  createDraft: any;
  editModeDisabled?: boolean;
}

const SignatureTab: React.FC<Props> = ({
  draftData,
  instance,
  dropPoint,
  addedSignatureFields,
  setAddedSignatureFields,
  updateDraftData,
  setRedirect,
  isExternal,
  createDraft,
  editModeDisabled,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [status, setStatus] = useState<string>();
  const [isSignatoryAdded, setIsSignatoryAdded] = useState<boolean>(false);
  const [signedDocument, setSignedDocument] = useState<any>();
  const [uploadedFileData, setUploadedFileData] = useState<any>();
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [signRefreshTokenData, setSignRefreshTokenData] = useState<any>();
  const [sendDraftConfirmation, setSendDraftConfirmation] =
    useState<boolean>(false);
  const [tempSignatories, setTempSignatories] = useState<any>([]);
  const [isDocumentSigned, setDocumentSigned] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const SparkMD5 = require("spark-md5");
  const signatories = useRef<any[]>([]);
  const XFDF = useRef<any>(null);

  const methods = useForm();
  const { control, watch, resetField, reset, handleSubmit } = methods;

  const user_type = watch("user_type") || "";
  const captured_method = watch("captured_method") || "";
  const email = watch("email") || "";
  const name = watch("name") || "";

  const qs = require("querystring");

  const signatoriesList = useMemo(
    () => draftData?.signatories || [],
    [draftData?.signatories]
  );

  const savedSignRefreshToken = localStorage.getItem("signRefreshToken");

  const signRefreshToken = useMemo(
    () => savedSignRefreshToken,
    [savedSignRefreshToken]
  );

  // login user data
  const user_data = useMemo(() => getLocalStorage("user_profile"), []);

  //login user is owner
  const userIsOwner = useMemo(
    () => draftData?.owners?.find((owner: any) => owner?.id === user_data?.id),
    [draftData, user_data]
  );

  // login user is signatory
  const userIsSignatory = useMemo(
    () =>
      draftData?.signatories?.find(
        (signatory: any) => signatory?.id === user_data?.id
      ),
    [draftData?.signatories, user_data?.id]
  );

  const handleCloseConfirmation = () => {
    setOpenConfirmationModal(false);
  };

  // set default value
  useEffect(() => {
    if (draftData?.signature_method) {
      reset({ captured_method: draftData?.signature_method });
      setDisabled(true);
    }
  }, [reset, draftData]);

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      let reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      let file = signedDocument;
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [SparkMD5, signedDocument]
  );

  const { data: signatoriesData, isLoading } = useQuery({
    queryKey: ["signatories_list", isExternal],
    queryFn: async () => {
      const response = isExternal
        ? await fetchSignatoriesListExternal()
        : await fetchSignatoriesList();
      let groups = response.results.map((data: any) => ({
        ...data,
        name: data.first_name + " " + data.last_name,
      }));
      return groups;
    },
  });

  // update api
  const { mutate: updateSignatory } = useMutation({
    mutationKey: ["update_signatory", isExternal],
    mutationFn: isExternal
      ? updateSignatoriesListExternal
      : updateSignatoriesList,
    onSuccess: (response) => {
      handleUserType();
      if (response?.data?.signature_method) {
        setDisabled(true);
      }
      queryClient.invalidateQueries({
        queryKey: ["signatories_list"],
      });
      queryClient.invalidateQueries({
        queryKey: isExternal
          ? ["get_draft_by_id_for_external"]
          : ["get_draft_by_id"],
      });
      enqueueSnackbar("Signatory data updated successfully!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      if (status === draftStatus?.SIGNATURE_ABORTED) {
        const payload = {
          id: draftData?.id,
          body: {
            status: status,
            link: uploadedFileData?.presigned_url?.file_path,
            version: draftData?.version - 1,
          },
        };
        updateDraftData(payload);
      }

      handleCloseConfirmation();
    },
    onError: () => {
      enqueueSnackbar("Failed to update signatory data!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  // move draft stylus to umbrella
  const { mutate: uploadDocToUmbrella } = useMutation({
    mutationKey: ["upload_draft_document", signedDocument, isExternal],
    mutationFn: isExternal
      ? fetchContractPreSignUrlExternal
      : fetchContractPreSignUrl,
    onSuccess: (response) => {
      setUploadedFileData(response?.data);
      if (response.data) {
        let reader = new FileReader();
        let file = signedDocument;
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadFileInUmbrellaS3Bucket({
            presignedPostData: response?.data?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
          reader.readAsDataURL(file);
          reader.onload = async () => {
            const hexHash = SparkMD5.hash(reader.result);
            let stylusFile = {
              file_hash: hexHash,
              file_name: draftData?.contractName + ".pdf",
              file_size: file?.size,
              file_type: "committed_draft",
              creation_type: isRiverusAdmin() ? "system" : "custom",
              type: file?.type,
            };
            setStatus(draftStatus?.CONTRACT_EXECUTED_SUCCESSFULLY);
            uploadDocument(stylusFile);
          };
        }
      }
    },
    onError: () => {
      enqueueSnackbar("Failed to upload document!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  //checking all signatories has signed or not
  //if signed then move to umbrella
  const isContractExecuted = React.useCallback(() => {
    if (
      signatoriesList?.length > 0 &&
      draftData?.status.toLowerCase().includes("signature pending")
    ) {
      let totalCount = 0;
      let signedCount = 0;
      signatoriesList.forEach((data: any) => {
        totalCount += 1;
        if (data?.signed_date) {
          signedCount += 1;
        }
      });
      if (signedCount === totalCount) {
        let reader = new FileReader();
        let file = signedDocument;
        if (file) {
          reader.readAsDataURL(file);
          reader.onload = async () => {
            const hexHash = SparkMD5.hash(reader.result);
            let file_ = {
              file_hash: hexHash,
              file_name: draftData?.contractName + ".pdf",
              file_size: file.size,
              uploaded_type: "Contract",
              type: file.type,
              refContract_id: file.id,
              groups: draftData?.groups,
              contract_type: [draftData?.contractType?.id],
            };
            uploadDocToUmbrella(file_);
          };
        }
      }
    }
  }, [
    SparkMD5,
    draftData,
    signatoriesList,
    signedDocument,
    uploadDocToUmbrella,
  ]);

  // update signed date
  const updateSignedDate = React.useCallback(() => {
    let signedUserIndex = -1;
    if (isExternal) {
      signedUserIndex = signatoriesList.findIndex(
        (user: any) =>
          user?.user_type === "external" &&
          user?.email === getLocalStorage("external_email")
      );
    } else {
      signedUserIndex = signatoriesList.findIndex(
        (user: any) => user?.id === userIsSignatory?.id
      );
    }
    const updatedSignatoriesList = [...signatoriesList];
    updatedSignatoriesList[signedUserIndex].signed_date =
      new Date().toISOString();
    const payload = {
      id: draftData?.id,
      body: {
        signatories: updatedSignatoriesList,
      },
    };
    updateSignatory(payload);
  }, [
    draftData,
    isExternal,
    signatoriesList,
    updateSignatory,
    userIsSignatory,
  ]);

  const { mutate: uploadFileInS3Bucket } = useMutation({
    mutationKey: ["upload_draft_to_s3"],
    mutationFn: isExternal
      ? upload_file_in_s3_bucket_external
      : upload_file_in_s3_bucket,
    onSuccess: () => {
      if (
        status === draftStatus?.CONTRACT_EXECUTED_SUCCESSFULLY ||
        isSignatoryAdded
      ) {
        const draftPayload = {
          version: draftData?.version + 1,
          link: uploadedFileData?.presigned_url?.file_path,
          status: status,
          version_type: draftData?.versionType,
          draftID: draftData?.draftID,
          collaborators: draftData?.collaborators.map((data: any) => data.id),
          approvers: draftData?.approvers.map((data: any) => data.id),
          owners: draftData?.owners.map((data: any) => data.id),
          contractType: draftData?.contractType.id,
          contractName: draftData?.contractName,
          groups: draftData?.groups,
          createFrom: draftData?.createFrom,
          earlier_draft_link: draftData?.earlier_draft_link,
          executed_contract_link: draftData?.executed_contract_link,
          support_document_link: draftData?.support_document_link,
          signatories: draftData?.signatories.length
            ? draftData.signatories
            : tempSignatories,
          signature_method: draftData?.signature_method,
        };
        createDraft(draftPayload);
        setIsSignatoryAdded(false);
      } else if (status !== draftStatus?.SIGNATURE_ABORTED) {
        updateSignedDate();
        isContractExecuted();
      }
    },
    onError: () => {
      enqueueSnackbar("Failed to upload document!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const { mutate: uploadFileInUmbrellaS3Bucket } = useMutation({
    mutationKey: ["upload_draft_to_umbrella_s3"],
    mutationFn: isExternal
      ? upload_file_in_s3_bucket_external
      : upload_file_in_s3_bucket,
    onSuccess: () => {
      enqueueSnackbar("Draft successfully updated in Umbrella!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
    onError: () => {
      enqueueSnackbar("Failed to upload document in Umbrella!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const { mutate: uploadDocument } = useMutation({
    mutationKey: ["upload_draft_document"],
    mutationFn: isExternal ? getS3PresignedUrlExternal : getS3PresignedUrl,
    onSuccess: (response) => {
      setUploadedFileData(response?.data);
      if (response.data) {
        let file = signedDocument;
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadFileInS3Bucket({
            presignedPostData: response?.data?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: () => {
      enqueueSnackbar("Failed to upload document!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  // reset field
  const handleUserType = React.useCallback(() => {
    resetField("name");
    resetField("email");
    resetField("select_user");
  }, [resetField]);

  const selectedAssignee = useMemo(
    () => signatoriesData?.filter((data: any) => data.id === name),
    [signatoriesData, name]
  );

  const addSignField = (type: string, point?: any) => {
    if (!instance) return;
    const { documentViewer, Annotations } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const fieldManager = annotationManager.getFieldManager();

    const doc = documentViewer.getDocument();
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);
    if (!!point?.x && page?.first == null) {
      return; //don't add field to an invalid page location
    }
    const page_idx =
      page.first !== null ? page.first : documentViewer.getCurrentPage();
    const page_info = doc.getPageInfo(page_idx);
    const page_point = displayMode.windowToPage(point, page_idx);
    const zoom = documentViewer.getZoomLevel();
    let field = new Annotations.Forms.Field(Date.now() + "s", {
      type: "Sig",
    });
    var signAnnot = new Annotations.FreeTextAnnotation();
    signAnnot.PageNumber = page_idx;
    const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
    signAnnot.Rotation = rotation;
    if (rotation === 270 || rotation === 90) {
      signAnnot.Width = 25.0 / zoom;
      signAnnot.Height = 200.0 / zoom;
    } else {
      signAnnot.Width = 200.0 / zoom;
      signAnnot.Height = 25.0 / zoom;
    }
    signAnnot.X = (page_point.x || page_info.width / 2) - signAnnot.Width / 2;
    signAnnot.Y = (page_point.y || page_info.height / 2) - signAnnot.Height / 2;
    signAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));

    signAnnot.custom = {
      name: `Sign here ${email ? name : selectedAssignee?.[0].first_name}`,
    };

    // set the type of annot
    signAnnot.setContents(signAnnot.custom.name);
    signAnnot.FontSize = "" + 16.0 / zoom + "px";
    signAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
    signAnnot.TextColor = new Annotations.Color(0, 165, 228);
    signAnnot.StrokeThickness = 1;
    signAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
    signAnnot.TextAlign = "center";

    signAnnot.Author = annotationManager.getCurrentUser();
    signAnnot.setCustomData("value", email || name);
    signAnnot.setCustomData("type", type);

    fieldManager.addField(field);

    annotationManager.deselectAllAnnotations();
    annotationManager.addAnnotation(signAnnot, true);
    annotationManager.redrawAnnotation(signAnnot);
    annotationManager.selectAnnotation(signAnnot);
  };

  // call presigned url
  const updateDoc = React.useCallback(
    (blob: any, refId: boolean = true) => {
      let reader = new FileReader();
      let file = blob;
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          let file_ = {
            file_hash: hexHash,
            file_name: draftData?.contractName + ".pdf",
            file_size: file.size,
            file_type: "committed_draft",
            creation_type: isRiverusAdmin() ? "system" : "custom",
            type: file.type,
            ...(refId
              ? {
                  ref_draft_id: draftData.id,
                }
              : {}),
          };
          uploadDocument(file_);
        };
      }
    },
    [SparkMD5, draftData, uploadDocument]
  );

  const uploadForSigning = React.useCallback(
    async (refId: boolean = true) => {
      if (!instance) return;
      const { documentViewer, annotationManager } = instance.Core;
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations({
        widgets: true,
        fields: true,
      });
      const data = await doc.getFileData({ xfdfString });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: "application/pdf" });
      setSignedDocument(blob);
      updateDoc(blob, refId);
    },
    [instance, updateDoc]
  );

  // user log in
  const isLoggedInUser = React.useCallback(
    (id: string, email?: string, user_type?: string) => {
      if (
        user_type === "external" &&
        email === getLocalStorage("external_email") &&
        isExternal
      ) {
        return true;
      } else if (id === user_data?.id && !isExternal) {
        return true;
      }
      return false;
    },
    [isExternal, user_data?.id]
  );

  //after signing the doc successfully
  const handleConfirmation = async () => {
    try {
      if (!instance) return;
      if (isDocumentSigned) {
        let xfdfArr: any[] = [];
        const { Core } = instance;
        const { annotationManager } = Core;
        const currentXFDF = await annotationManager.exportAnnotations({
          widgets: false,
          fields: false,
        });
        XFDF.current = currentXFDF;
        xfdfArr = [...xfdfArr, currentXFDF];
        if (draftData) {
          let signatory = draftData.signatories;
          signatories.current = [...signatory];
          if (signatory.length > 0) {
            let XFDF = signatory
              .map((data: any) => data.xfdf)
              .filter((data: any) => data);
            xfdfArr = [...xfdfArr, ...XFDF];
          }
        }
        mergingAnnotations(xfdfArr);
      } else {
        const { documentViewer, annotationManager } = instance.Core;
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations({
          widgets: true,
          fields: true,
        });
        const data = await doc.getFileData({ xfdfString });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: "application/pdf" });
        setSignedDocument(blob);
        updateDoc(blob);
      }
      setAddedSignatureFields(false);
    } catch (error) {
      console.error(error);
    }
  };

  const mergingAnnotations = async (xfdfArr: any[]) => {
    try {
      if (!instance) return;
      const { Core } = instance;
      const { documentViewer, PDFNet } = Core;
      const doc = await documentViewer.getDocument().getPDFDoc();
      doc.initSecurityHandler();
      let i;
      for (i = 0; i < xfdfArr.length; i++) {
        let fdfDoc = await PDFNet.FDFDoc.createFromXFDF(xfdfArr[i]);
        await doc.fdfMerge(fdfDoc);
        await doc.flattenAnnotations();
      }
      const docbuf = await doc.saveMemoryBuffer(
        PDFNet.SDFDoc.SaveOptions.e_linearized
      );
      const blob = new Blob([docbuf], {
        type: "application/pdf",
      });
      setSignedDocument(blob);
      updateDoc(blob);
    } catch (error) {
      console.error(error);
    }
  };

  const declineSignature = React.useCallback(async () => {
    const payload = {
      id: draftData?.id,
      body: {
        signatories: [],
      },
    };
    await removeAnnotation(instance);
    await uploadForSigning();
    updateSignatory(payload);
    await setStatus(draftStatus?.SIGNATURE_ABORTED);
  }, [draftData?.id, instance, updateSignatory, uploadForSigning]);

  const onSubmit = (data: any) => {
    if (!instance) return;
    const { documentViewer } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const annotationsList = annotationManager.getAnnotationsList();
    const index = annotationsList.findIndex((annotations: any) =>
      data.user_type === "internal"
        ? annotations.getCustomData("value") === data.name
        : annotations.getCustomData("value") === data.email
    );
    let updatedSignatoriesList: any[] = [];
    const isAlreadySignatory = tempSignatories.some(
      (user: any) => user.id === data.name
    );
    if (index === -1) {
      enqueueSnackbar("Please add signatory box for!", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      return;
    } else if (isAlreadySignatory) {
      enqueueSnackbar("This signatory is already present!", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      return;
    } else {
      if (!draftData?.signature_method) {
        enqueueSnackbar("Please select and save signature method", {
          variant: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      } else {
        if (data.user_type === "internal") {
          const internalUserData = signatoriesData.find(
            (user: any) => user.id === data.name
          );
          updatedSignatoriesList = [
            ...tempSignatories,
            {
              ...internalUserData,
              assigned_role: ["Signatories"],
              assigned_date: new Date().toLocaleDateString("en-GB"),
              captured_method: draftData?.signature_method,
              user_type: "internal",
            },
          ];
        } else {
          const externalUserData = {
            assigned_date: new Date().toLocaleDateString("en-GB"),
            email: data.email,
            assigned_role: ["Signatories"],
            name: data.name,
            user_type: "external",
            captured_method: draftData?.signature_method,
          };
          updatedSignatoriesList = [...tempSignatories, externalUserData];
        }
        setTempSignatories(updatedSignatoriesList);
        reset();
      }
    }
  };

  useQuery({
    queryKey: ["refresh_token"],
    queryFn: async () => await updateWithSignRefreshToken(signRefreshTokenData),
    select: (result: any) => {
      localStorage.setItem("signAccessToken", result.data.access_token);
    },
    throwOnError: (error: any) => {
      if (error.response.status) {
        let statusCode = error.response.status;
        if (statusCode === 401) {
          // Invalidate tokens for starting again for refesh token
          localStorage.removeItem("signAccessToken");
          localStorage.removeItem("signRefreshToken");
        }
      }
      return false;
    },
    enabled: !!signRefreshTokenData && !isExternal,
  });

  const { data: authCredentialsData } = useQuery({
    queryKey: ["auth_credentials"],
    queryFn: async () => await getAuthCredentials(),
    enabled: !isExternal,
  });

  const credentialsClient = React.useMemo(() => {
    if (authCredentialsData) {
      const clientIdData = authCredentialsData?.data?.client_id;
      const clientSecretData = authCredentialsData?.data?.client_secret;
      const base64Key = authCredentialsData?.data?.client_key;
      const decryptData = (encryptedData: string) => {
        const key = CryptoJS.enc.Base64.parse(base64Key);
        const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
        const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
        const ciphertext = CryptoJS.lib.WordArray.create(
          dataBytes.words.slice(4)
        );
        const cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Hex.parse(ciphertext.toString()),
        });
        const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
          iv: CryptoJS.enc.Hex.parse(iv.toString()),
        });
        const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
        return decryptedText;
      };
      return {
        id: decryptData(clientIdData),
        secret: decryptData(clientSecretData),
      };
    }
  }, [authCredentialsData]);

  useEffect(() => {
    if (
      signRefreshToken &&
      credentialsClient?.id &&
      credentialsClient?.secret
    ) {
      const data = {
        grant_type: "refresh_token",
        client_id: credentialsClient?.id,
        client_secret: credentialsClient?.secret,
        refresh_token: signRefreshToken,
      };
      const formData = qs.stringify(data);
      setSignRefreshTokenData(formData);
    }
  }, [signRefreshToken, credentialsClient, qs]);

  const handleSendDraftToSignatory = async () => {
    await applyFields(instance);
    await uploadForSigning(false);
    setIsSignatoryAdded(true);
    setStatus(draftStatus?.SIGNATURE_PENDING);
    setSendDraftConfirmation(true);
    setOpenConfirmationModal(false);
  };

  const handleDeleteTempSignatory = (tempSignatory: any) => {
    let filterDeletedArray = tempSignatories;
    if (tempSignatory.user_type === "external") {
      filterDeletedArray = tempSignatories?.filter(
        (item: any) =>
          item.email !== tempSignatory.email || item.user_type !== "external"
      );
    } else {
      filterDeletedArray = tempSignatories?.filter(
        (item: any) => item?.id !== tempSignatory.id
      );
    }
    setTempSignatories(filterDeletedArray);
  };

  const handleSignatureMethods = () => {
    const payload = {
      id: draftData?.id,
      body: {
        signature_method: captured_method,
      },
    };
    if (!captured_method) {
      enqueueSnackbar("Please select signature method", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } else {
      updateSignatory(payload);
    }
    return captured_method;
  };

  if (isLoading) {
    return (
      <Box px={2}>
        <ListSkeleton />
      </Box>
    );
  }
  return (
    <>
      <Box
        marginY={2}
        marginX={1}
        padding={2}
        height="100%"
        borderRadius="10px"
        sx={{ backgroundColor: colors?.riPrimary[10] }}
      >
        {!isExternal &&
          userIsOwner &&
          !draftData?.status
            .toLowerCase()
            .includes("contract executed successfully") && (
            <StampPaper id={draftData.id} status={draftData?.status} />
          )}
        {draftData?.signatories?.length === 0 ? (
          <>
            {!isExternal &&
              userIsOwner &&
              !draftData?.status
                .toLowerCase()
                .includes("contract executed successfully") && (
                <>
                  <SignatureMethod
                    control={control}
                    disabled={disabled}
                    handleSignatureMethods={handleSignatureMethods}
                    credentialsClientId={credentialsClient?.id}
                  />

                  <FormProvider {...methods}>
                    <AddSignatories
                      signatoriesData={signatoriesData}
                      userTypes={userTypes}
                      user_type={user_type}
                      onSubmit={onSubmit}
                      handleUserType={handleUserType}
                      control={control}
                      handleSubmit={handleSubmit}
                      addField={addSignField}
                      dropPoint={dropPoint}
                    />
                  </FormProvider>
                </>
              )}

            {tempSignatories?.length > 0 && (
              <>
                {tempSignatories?.map((tempSignatory: any, index: number) => (
                  <AddedSignatoriesList
                    key={index}
                    tempSignatory={tempSignatory}
                    handleDeleteTempSignatory={handleDeleteTempSignatory}
                  />
                ))}
                <Stack alignItems="end">
                  <Button
                    variant="contained"
                    startIcon={<SendIcon />}
                    onClick={() => setOpenConfirmationModal(true)}
                  >
                    Send draft to signatories
                  </Button>
                </Stack>
              </>
            )}
          </>
        ) : (
          <SignatoriesList
            signatoriesList={signatoriesList}
            isLoggedInUser={isLoggedInUser}
            addedSignatureFields={addedSignatureFields}
            draftData={draftData}
            handleConfirmation={handleConfirmation}
            setRedirect={setRedirect}
            isExternal={isExternal}
            sendDraftConfirmation={sendDraftConfirmation}
            editModeDisabled={editModeDisabled}
            instance={instance}
            setAddedSignatureFields={setAddedSignatureFields}
            setDocumentSigned={setDocumentSigned}
            declineSignature={declineSignature}
          />
        )}
      </Box>

      <ReusableConfirmationModal
        open={openConfirmationModal}
        onClose={handleCloseConfirmation}
        onConfirm={handleSendDraftToSignatory}
        title="Send draft to signatories"
        confirmBtnText="Yes, Send to signatories"
        cancelBtnText="Cancel, Go back"
        children={
          <Typography>
            Are you sure? You will not be able to add any more signatories or
            modify the draft further
          </Typography>
        }
      />
    </>
  );
};

export default SignatureTab;
