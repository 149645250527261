import * as changesets from "json-diff-ts";

import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function NonSolicitationClause({
  getClauseDataByType,
  clauseData,
  fileId,
  updatedClauseData,
  postClauseDataByType,
  durationList,
  hasData,
  ...props
}: ClauseComponentInterface) {
  const [showAdd, setShowAdd] = useState(false);
  const [showAddDuration, setShowAddDuration] = useState(false);
  const [durationId, setDurationId] = useState<number>(-1);
  const [editDuration, setEditDuration] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  const data = React.useMemo(
    () => clauseData?.nonSolicitation && clauseData.nonSolicitation,
    [clauseData?.nonSolicitation]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "non_solicitation");
  }, [fileId, hasData]);

  const updates = React.useMemo(
    () => updatedClauseData?.nonSolicitation || {},
    [updatedClauseData]
  );

  const nonSolicitation = React.useMemo(() => {
    if (updates?.non_solicitation) {
      let sortData = updates.non_solicitation.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const nonSolicitationDuration = React.useMemo(() => {
    if (updates?.duration) {
      let sortData = updates.duration.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      sortData.map((data: any) => {
        const index = durationList.findIndex(
          (list) => list.durationTypeId === data.duration_type_id
        );
        const typeIndex = durationList.findIndex(
          (list) => list.durationType === data.duration_type
        );
        if (index > -1) {
          const type = durationList[index].durationType;
          data.duration_type = type;
        }
        if (!data.duration_type_id && typeIndex > -1) {
          const id = durationList[typeIndex].durationTypeId;
          data.duration_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [durationList, updates.duration]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let non_solicitation = nonSolicitation?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let duration = nonSolicitationDuration?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        non_solicitation,
        duration,
      };
      const diff = changesets.diff(data?.raw_content || {}, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_solicitation", diff, updatedData);
      }
    },
    [
      nonSolicitation,
      nonSolicitationDuration,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteDuration = React.useCallback(
    (item: any) => {
      let duration = nonSolicitationDuration?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        duration,
      };
      const diff = changesets.diff(data?.raw_content || {}, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_solicitation", diff, updatedData);
      }
    },
    [
      nonSolicitationDuration,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const editPhrase = React.useCallback(
    (data: any, index: number) => {
      let duration = nonSolicitationDuration.slice();
      duration[index] = data;
      let updatedData = {
        ...updates,
        duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_solicitation", diff, updatedData);
      }
    },
    [fileId, nonSolicitationDuration, postClauseDataByType, updates]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="non-solicitation"
          onClick={() =>
            handleAddEdit(
              props,
              "Non-solicitation clause",
              nonSolicitation,
              setShowAdd
            )
          }
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="non_solicitation"
          savedInsight={nonSolicitation?.length > 0 ? nonSolicitation : []}
          savedParentClauseDataPoint={nonSolicitation}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          contractData={props.contractData}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="non_solicitation"
        />
      )}
      {nonSolicitation?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "non_solicitation", index });
            props.onClickHighlight(index, item, "non_solicitation");
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === "non_solicitation" &&
            activeClause?.index === index
          }
          deleteClause={() => deleteClause(item)}
        />
      ))}
      {!showAddDuration ? (
        <ClauseHeader
          title="Duration"
          buttonText="duration"
          onClick={() =>
            handleAddEdit(
              props,
              "Non Solicitation Duration",
              nonSolicitationDuration,
              setShowAddDuration
            )
          }
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="Non Solicitation Duration"
          savedInsight={
            !isNullOrUndefined(nonSolicitationDuration)
              ? nonSolicitationDuration
              : []
          }
          savedParentClauseDataPoint={nonSolicitationDuration}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAddDuration(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="non_solicitation"
        />
      )}
      {nonSolicitationDuration &&
        nonSolicitationDuration.map((durationData: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "duration", index });
              props.onClickHighlight(
                index,
                durationData,
                "Non Compete Duration"
              );
            }}
            index={index}
            clauseItem={durationData}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "duration" && activeClause?.index === index
            }
            deleteClause={() => deleteDuration(durationData)}
            phraseLevel
            subTitle={`${durationData.duration_value} ${durationData.duration_type}`}
          />
        ))}
    </>
  );
}
