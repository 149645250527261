export const convertPDF = async (Core: any, fileURL: any) => {
  // perform the conversion with no optional parameters
  let file;
  try {
    const buf = await Core.officeToPDFBuffer(fileURL);

    //optionally save the blob to a file or upload to a server
    const blob = new Blob([buf], { type: "application/pdf" });
    file = blob;
  } catch (error) {
    console.error("Error", error);
    file = undefined;
  }
  return file;
};

export const downloadFile = (blob: any, fileName: string, fileExt: string) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${fileName}.${fileExt}`;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

export const highlightedSentence = (
  sentence: string,
  instance: any
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (!instance) {
      reject("PDFTron instance not available");
      return;
    }

    const { Core } = instance;
    const { documentViewer, Annotations, annotationManager } = Core;
    const annotations = annotationManager.getAnnotationsList();
    const searchMode =
      instance.Core.Search.Mode.PAGE_STOP | instance.Core.Search.Mode.HIGHLIGHT;

    const searchOptions = {
      fullSearch: true,
      onResult: (result: any) => {
        if (result.resultCode === instance.Core.Search.ResultCode.FOUND) {
          const highlight = new Annotations.TextHighlightAnnotation();
          result.quads.forEach((quad: any) => {
            highlight.Quads.push(quad.getPoints());
          });

          highlight.PageNumber = result.pageNum;
          annotationManager.addAnnotation(highlight);
          annotationManager.redrawAnnotation(highlight);
          annotationManager.drawAnnotations({ pageNumber: result.pageNum });

          if (documentViewer.getCurrentPage() !== result.pageNum) {
            documentViewer.setCurrentPage(result.pageNum, true);
          }
        }
      },
      onDocumentEnd: () => {
        resolve(); // Resolve the promise once highlighting is completed
      },
    };

    documentViewer.textSearchInit(sentence, searchMode, searchOptions);
    annotationManager.deleteAnnotations(annotations);
  });
};

export const getTodayDate = (type?: string) => {
  const timeElapsed = Date.now();
  const date = new Date(timeElapsed);

  var time = date.toLocaleString([], { hour: "2-digit", minute: "2-digit" });

  var fullTime = date.toLocaleString([], { hour12: true });

  const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear(),
  ];

  switch (type) {
    case "full-date-and-time":
      return `${day}/${month}/${year} ${fullTime}`;
    case "full-date":
      return `${day}/${month}/${year}`;
    case "full-time":
      return `${fullTime}`;
    case "month":
      return month;
    case "day":
      return day;
    case "year":
      return year;
    default:
      return `${day}/${month}/${year} ${time}`;
  }
};

export const applyFields = async (instance: any) => {
  if (!instance) return;
  const { Annotations, documentViewer } = instance.Core;
  const annotationManager = documentViewer.getAnnotationManager();
  const fieldManager = annotationManager.getFieldManager();
  const annotationsList = annotationManager.getAnnotationsList();
  const annotsToDelete: any = [];
  const annotsToDraw: any = [];

  await Promise.all(
    annotationsList.map(async (annot: any, index: number) => {
      let inputAnnot;
      let field;

      if (annot.getCustomData("type") === "SIGNATURE") {
        field = new Annotations.Forms.Field(
          `${annot.getContents()}-${Date.now()}-${index} ${annot.getCustomData(
            "value"
          )}`,
          {
            type: "Sig",
          }
        );
        inputAnnot = new Annotations.SignatureWidgetAnnotation(field, {
          appearance: "_DEFAULT",
          appearances: {
            _DEFAULT: {
              Normal: {
                data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC",
                offset: {
                  x: 100,
                  y: 100,
                },
              },
            },
          },
        });

        // set position
        inputAnnot.PageNumber = annot.getPageNumber();
        inputAnnot.X = annot.getX() + 78;
        inputAnnot.Y = annot.getY() - 30;
        inputAnnot.rotation = annot.Rotation;
        if (annot.Rotation === 0 || annot.Rotation === 180) {
          inputAnnot.Width = annot.getWidth();
          inputAnnot.Height = annot.getHeight();
        } else {
          inputAnnot.Width = annot.getHeight();
          inputAnnot.Height = annot.getWidth();
        }
        inputAnnot.setCustomData("user", annot.getCustomData("value"));
      } else {
        return;
      }

      annot.TextColor = new Annotations.Color(0, 0, 0);
      annot.FillColor = new Annotations.Color(255, 255, 255);
      annot.FontSize = "16px";
      annot.StrokeThickness = 0;
      annot.setCustomData("user", annot.getCustomData("value"));

      annotsToDelete.length &&
        annotationManager.deleteAnnotations(annotsToDelete, null, true);
      // draw the annotation the viewer
      annotationManager.addAnnotation(inputAnnot);
      fieldManager.addField(field);
      annotsToDraw.push(inputAnnot);
    })
  );

  // refresh viewer
  await annotationManager.drawAnnotationsFromList(annotsToDraw);
};

export const removeAnnotation = async (instance: any) => {
  if (!instance) return;
  const { documentViewer } = instance.Core;
  const annotationManager = documentViewer.getAnnotationManager();
  const annots = annotationManager.getAnnotationsList();
  const annotsToDraw: any = [];

  annotationManager.deleteAnnotations(annots);

  // delete old annotations
  // refresh viewer
  await annotationManager.drawAnnotationsFromList(annotsToDraw);
};

export  const handleSeeMoreAndLess = (text: string) => {
  const words = text?.split(/\s+/);
  if (words?.length > 20) {
    const sentence = words?.slice(0, 20)?.join(" ");
    return `${sentence}...`;
  } else {
    return words?.join(" ");
  }
};