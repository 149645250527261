const switchTheme = {
  styleOverrides: {
    root: {
      width: "52px",
      height: "32px",
      padding: 0,
      margin: "10px",
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "primary",
        //border: '6px solid #fff',
      },
    },

    switchBase: {
      padding: "0px 3px",
      marginTop: "4px",
      transitionDuration: "300ms",

      ":checked": {
        // background:'primary',
        transform: "translateX(16px)",
        track: {
          // opacity: 1,
          border: 0,
        },
      },
    },

    thumb: {
      width: "24px",
      height: "24px",
      background: "white",
    },

    track: {
      borderRadius: "23px",
      background: "primary",
    },
  },
};

export default switchTheme;
