import { Box, Stack, Typography } from "@mui/material";
import * as changesets from "json-diff-ts";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import RadioButtonGroup from "../../../../../RiverusUI/Components/RadioButtonGroup";
import { ClauseType } from "../../../../Constants/ClauseType";
import {
  LinkSentenceRequest,
  SentencesData,
  editedSentences,
  sentenceInfo,
  tableInfo,
} from "../../../../State/documentState";
import {
  getClauseDataFormat,
  getClauseObjects,
} from "../../../ClauseComponent/utils/ClauseTypeUtils";
import {
  getSentencesFromChild,
  getTableCellsFromChild,
} from "../../../Utils/docUtils";
import SaveAndCancelSentence from "../SaveAndCancelSentence";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresentSentences: (presentValue: LinkSentenceRequest) => void;
  savedPresent: string;
  dataPointName: string;
  savedPresentData: any;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  onClose: any;
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseData: any;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  otherClauses: (type: string) => void;
}

const getAddedAndDeletedSentences = (
  changedLinkedSentences: sentenceInfo[] | null,
  previousLinkedSentences: sentenceInfo[]
) => {
  let addedSentences: sentenceInfo[] = [];
  let deletedSentences: sentenceInfo[] = [];

  if (previousLinkedSentences.length !== 0) {
    if (changedLinkedSentences !== null) {
      if (changedLinkedSentences.length !== 0) {
        //get newly added elements
        for (let i = 0; i < changedLinkedSentences.length; i++) {
          let addedExists = false;
          for (let j = 0; j < previousLinkedSentences.length; j++) {
            if (
              changedLinkedSentences[i].paraId ===
              previousLinkedSentences[j].paraId
            ) {
              if (
                changedLinkedSentences[i].sentenceId ===
                previousLinkedSentences[j].sentenceId
              ) {
                addedExists = true;
                break;
              }
            }
          }
          if (!addedExists) {
            addedSentences.push({
              paraId: changedLinkedSentences[i].paraId,
              sentenceId: changedLinkedSentences[i].sentenceId,
              rowId: -1,
              columnId: -1,
            });
          }
        }

        //get deleted elements
        for (let i = 0; i < previousLinkedSentences.length; i++) {
          let deletedExists = false;
          for (let j = 0; j < changedLinkedSentences.length; j++) {
            if (
              previousLinkedSentences[i].paraId ===
              changedLinkedSentences[j].paraId
            ) {
              if (
                previousLinkedSentences[i].sentenceId ===
                changedLinkedSentences[j].sentenceId
              ) {
                deletedExists = true;
                break;
              }
            }
          }
          if (!deletedExists) {
            deletedSentences.push({
              paraId: previousLinkedSentences[i].paraId,
              sentenceId: previousLinkedSentences[i].sentenceId,
              rowId: previousLinkedSentences[i].rowId,
              columnId: previousLinkedSentences[i].columnId,
            });
          }
        }
      } else if (changedLinkedSentences.length === 0) {
        for (let i = 0; i < previousLinkedSentences.length; i++) {
          deletedSentences.push({
            paraId: previousLinkedSentences[i].paraId,
            sentenceId: previousLinkedSentences[i].sentenceId,
            rowId: previousLinkedSentences[i].rowId,
            columnId: previousLinkedSentences[i].columnId,
          });
        }
      }
    }
  } else {
    if (changedLinkedSentences) {
      if (changedLinkedSentences.length) {
        //adding for first time, newly added elements
        for (let i = 0; i < changedLinkedSentences.length; i++) {
          addedSentences.push(changedLinkedSentences[i]);
        }
      }
    }
  }

  let addedDeletedSentences: editedSentences = {
    upsert: addedSentences,
    deleted: deletedSentences,
  };

  return addedDeletedSentences;
};

const getAddedAndDeletedTableCells = (
  changedLinkedTableCells: tableInfo[] | null,
  previousLinkedTableCells: tableInfo[]
) => {
  let addedTableCells: tableInfo[] = [];
  let deletedTableCells: tableInfo[] = [];
  if (previousLinkedTableCells.length && previousLinkedTableCells) {
    if (changedLinkedTableCells && changedLinkedTableCells.length) {
      //newly added
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        let addedCellExists = false;
        for (let j = 0; j < previousLinkedTableCells.length; j++) {
          if (
            changedLinkedTableCells[i].paraId ===
              previousLinkedTableCells[j].paraId &&
            changedLinkedTableCells[i].rowId ===
              previousLinkedTableCells[j].rowId &&
            changedLinkedTableCells[i].columnId ===
              previousLinkedTableCells[j].columnId
          ) {
            addedCellExists = true;
            break;
          }
        }
        if (addedCellExists === false) {
          addedTableCells.push({
            paraId: changedLinkedTableCells[i].paraId,
            rowId: changedLinkedTableCells[i].rowId,
            columnId: changedLinkedTableCells[i].columnId,
          });
        }
      }

      //deleted elements
      for (let i = 0; i < previousLinkedTableCells.length; i++) {
        let deletedCellExists = false;
        for (let j = 0; j < changedLinkedTableCells.length; j++) {
          if (
            previousLinkedTableCells[i].paraId ===
              changedLinkedTableCells[j].paraId &&
            previousLinkedTableCells[i].rowId ===
              changedLinkedTableCells[j].rowId &&
            previousLinkedTableCells[i].columnId ===
              changedLinkedTableCells[j].columnId
          ) {
            deletedCellExists = true;
            break;
          }
        }
        if (!deletedCellExists) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else {
      //all deleted
      for (let i = 0; i < previousLinkedTableCells.length; i++) {
        deletedTableCells.push({
          paraId: previousLinkedTableCells[i].paraId,
          rowId: previousLinkedTableCells[i].rowId,
          columnId: previousLinkedTableCells[i].columnId,
        });
      }
    }
  } else if (changedLinkedTableCells && changedLinkedTableCells.length) {
    //newly added
    for (let i = 0; i < changedLinkedTableCells.length; i++) {
      addedTableCells.push(changedLinkedTableCells[i]);
    }
  }

  //Convert table cells json to sentence level json
  let upsertSentences: sentenceInfo[] = [];
  let deletedSentences: sentenceInfo[] = [];

  for (let i = 0; i < addedTableCells.length; i++) {
    upsertSentences.push({
      paraId: addedTableCells[i].paraId,
      sentenceId: -1,
      rowId: addedTableCells[i].rowId,
      columnId: addedTableCells[i].columnId,
    });
  }

  for (let i = 0; i < deletedTableCells.length; i++) {
    deletedSentences.push({
      paraId: deletedTableCells[i].paraId,
      sentenceId: -1,
      rowId: deletedTableCells[i].rowId,
      columnId: deletedTableCells[i].columnId,
    });
  }

  let editedTableCellsAsSentence: editedSentences = {
    upsert: upsertSentences,
    deleted: deletedSentences,
  };
  return editedTableCellsAsSentence;
};

const mergeEditedSentenceCallback = (
  firstEditedSentences: editedSentences,
  secondEditedSentences: editedSentences
) => {
  let mergedAddedSentences: sentenceInfo[] = firstEditedSentences.upsert.concat(
    secondEditedSentences.upsert
  );
  let mergedDeletedSentences: sentenceInfo[] =
    firstEditedSentences.deleted.concat(secondEditedSentences.deleted);

  let mergedEditedSentences: editedSentences = {
    upsert: mergedAddedSentences,
    deleted: mergedDeletedSentences,
  };
  return mergedEditedSentences;
};

const EditPresentSentence: React.FC<Props> = ({
  editOptionSelected,
  saveHighlightedDataPoint,
  dataPointName,
  savedPresentData,
  savedHighlightedSentences,
  saveHighlightedSentences,
  savedHighlightedTableCells,
  saveHighlightedTableCells,
  onClose,
  fileId,
  clauseType,
  postClauseDataByType,
  sentenceData,
  updatedClauseData,
  clauseDataByType,
  updatedClauseDataByType,
  parentClauseType,
  otherClauses,
}) => {
  const { control, watch } = useForm({
    defaultValues: {
      isPresent: "Yes",
    },
  });
  const clauseName = dataPointName?.replace(/_/g, " ");

  const clauseOptions = [
    {
      value: "Yes",
      title: `${clauseName} is present`,
    },
    {
      value: "No",
      title: `${clauseName} is not present`,
    },
  ];

  const isPresent = watch("isPresent") || "";

  const saveHighlightedDataPointCallback = useCallback(() => {
    saveHighlightedDataPoint(dataPointName);
  }, [dataPointName, saveHighlightedDataPoint]);

  const addOrRemovePresent = useCallback(
    (action: string): LinkSentenceRequest => {
      let tempPresentList: LinkSentenceRequest = { data: "", mode: "" };
      //edited sentences
      let previousLinkedSentences = getSentencesFromChild(savedPresentData);
      let editedSentencesObject = getAddedAndDeletedSentences(
        savedHighlightedSentences,
        previousLinkedSentences
      );

      //edited table cells
      let previousLinkedTableCells = getTableCellsFromChild(savedPresentData);
      let editedTableCellsObject = getAddedAndDeletedTableCells(
        savedHighlightedTableCells,
        previousLinkedTableCells
      );

      //merge both
      let mergeEditedSentences = mergeEditedSentenceCallback(
        editedTableCellsObject,
        editedSentencesObject
      );

      if (action === "add") {
        if (savedHighlightedSentences || savedHighlightedTableCells) {
          tempPresentList = {
            data: isPresent,
            mode: "manual",
            editedSentences: mergeEditedSentences,
          };
        } else {
          tempPresentList = {
            data: isPresent,
            mode: "retain",
          };
        }
      }
      return tempPresentList;
    },
    [
      savedHighlightedSentences,
      savedHighlightedTableCells,
      savedPresentData,
      isPresent,
    ]
  );

  const editPresent = useCallback(() => {
    let addSentenceRequest = addOrRemovePresent("add");
    let newData = updatedClauseDataByType;

    if (isPresent === "Yes") {
      if (addSentenceRequest?.editedSentences?.upsert) {
        let addedData = addSentenceRequest.editedSentences.upsert;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
        }
      }
      if (addSentenceRequest?.editedSentences?.deleted) {
        let deletedData = addSentenceRequest.editedSentences.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            "remove",
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(clauseType as ClauseType, newData);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }

    if (parentClauseType === "termination") {
      if (diff.length > 0) {
        postClauseDataByType(
          fileId,
          "termination_at_convenience",
          diff,
          newData
        );
      }
    }

    onClose();
  }, [
    updatedClauseData,
    fileId,
    clauseDataByType,
    addOrRemovePresent,
    clauseType,
    postClauseDataByType,
    sentenceData,
    isPresent,
  ]);

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} mb={1} textTransform="capitalize">
        Add / Edit {dataPointName}
      </Typography>

      <Stack width="100%" spacing={2}>
        <RadioButtonGroup
          row
          required
          name="isPresent"
          options={clauseOptions}
          valueKey="value"
          control={control}
        />
        <Stack width="100%">
          <Typography variant="body2" fontWeight={700}>
            How to add a sentence to {clauseName}?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Select "
            <span style={{ textTransform: "capitalize" }}>{clauseName}</span> Is
            Present"
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on "Edit Linked Sentences(s)" button.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            4). Click on the desired text or the link icon on the box around the
            text that you want to add as {clauseName}.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            5). Click on Save.
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body2" fontWeight={700}>
            How to remove a sentence from {clauseName} clause?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Hover over the highlighted text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on the bin icon against the highlighted sentence you want
            to remove from {clauseName}.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Click on Save.
          </Typography>
        </Stack>
        <SaveAndCancelSentence
          enableHighlightOption={isPresent === "Yes"}
          dataPointName={dataPointName}
          editOptionSelected={editOptionSelected}
          editDataPoint={() => {
            editPresent();
            saveHighlightedSentences(null);
            saveHighlightedTableCells(null);
            editOptionSelected(false);
          }}
          savedHighlightedSentences={savedHighlightedSentences}
          saveHighlightedDataPoint={saveHighlightedDataPointCallback}
          enableSaveBtn={true}
          saveHighlightedSentences={(
            savedHighlightedSentences: sentenceInfo[] | null
          ) => saveHighlightedSentences(savedHighlightedSentences)}
          savedHighlightedTableCells={savedHighlightedTableCells}
          saveHighlightedTableCells={(
            savedHighlightedTableCells: tableInfo[] | null
          ) => saveHighlightedTableCells(savedHighlightedTableCells)}
          previouslyLinkedSentences={
            getSentencesFromChild(savedPresentData).length > 0
              ? getSentencesFromChild(savedPresentData)
              : undefined
          }
          previouslyLinkedTableCells={
            getTableCellsFromChild(savedPresentData).length > 0
              ? getTableCellsFromChild(savedPresentData)
              : undefined
          }
          onClose={onClose}
          otherClauses={(type: string) => otherClauses(type)}
        />
      </Stack>
    </Box>
  );
};

export default EditPresentSentence;
