import { ChipProps, Stack, TextFieldProps } from "@mui/material";

import AutocompleteElement from "./AutoComplete";
import { FormInputProps } from "../Interfaces/FormInputProps";
import React from "react";

interface IProps extends FormInputProps {
  isMultiselect?: boolean;
  chipStyle?: any;
  name: string;
  control: any;
  renderCustomComponent?: (value: string, props?: ChipProps) => React.ReactNode;
  loading?: boolean;
  showCheckbox?: boolean;
  defaultValue?: any;
  required?: boolean;
  labelKey?: string;
  valueKey?: string;
  textFieldProps?: Omit<TextFieldProps, "name" | "required" | "label">;
  fixedValues?: any;
  readOnly?: boolean;
  canCreateNew?: boolean;
  addNewValue?: (value: string) => void;
}

export default function RISelectComponent({
  isMultiselect = false,
  onChange,
  label,
  options,
  chipStyle,
  name,
  loading = false,
  showCheckbox = true,
  control,
  renderCustomComponent,
  defaultValue,
  required = false,
  fixedValues,
  readOnly,
  canCreateNew,
  addNewValue,
  ...props
}: IProps) {
  return (
    <AutocompleteElement
      readOnly={readOnly}
      label={label}
      name={name}
      multiple={isMultiselect}
      options={options || []}
      matchId
      showCheckbox={showCheckbox}
      control={control}
      loading={loading}
      required={required}
      fixedValues={fixedValues}
      canCreateNew={canCreateNew}
      addNewValue={addNewValue}
      autocompleteProps={{
        renderTags: (value, getTagProps) =>
          renderCustomComponent ? (
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {value.map((option: any, index) =>
                renderCustomComponent(option, { ...getTagProps({ index }) })
              )}
            </Stack>
          ) : null,
      }}
      {...props}
    />
  );
}
