import * as React from "react";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  Control,
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from "react-hook-form";
import { TextField } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const StyledInputRoot = styled("div")(
  () => `
  font-weight: 400;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
);

const StyledInput = styled("input")(
  () => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  outline: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  border: none;
  text-align: center;

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled("button")(
  () => `
  font-size: 0.875rem;
  background-color: #88305F;
  color: #FFFFFF;
  border-radius: 20px 0 0 20px;
  width: 40px;
  height: 40px;
  display: flex;
  border: none;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: #88305F;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
    border-radius: 0 20px 20px 0;
  }
`
);

const NumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: "increment",
          type: "button",
        },
        decrementButton: {
          children:
            props.value === 1 ? (
              <DeleteOutlineOutlinedIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            ),
          type: "button",
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

export type NumberInputElementProps<T extends FieldValues = FieldValues> = Omit<
  NumberInputProps,
  "name"
> & {
  validation?: ControllerProps<T>["rules"];
  name: Path<T>;
  control?: Control<T>;
  /**
   * You override the MUI's TextField component by passing a reference of the component you want to use.
   *
   * This is especially useful when you want to use a customized version of TextField.
   */
  component?: typeof TextField;
  deleteQuantity?: VoidFunction;
};

const ControlledQuantityInput = (props: NumberInputElementProps) => {
  const { name, control, validation, required, deleteQuantity, ...rest } =
    props;

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <NumberInput
          {...rest}
          value={value || 1}
          onChange={(ev: any, newValue: any) => {
            const min = props.min || 0;
            if (newValue <= min) {
              deleteQuantity?.();
            } else {
              onChange(newValue);
              if (typeof rest.onChange === "function") {
                rest.onChange(ev, newValue);
              }
            }
          }}
          onInputChange={(event) => {
            onChange(event.target.value);
            if (typeof rest.onInputChange === "function") {
              rest.onInputChange(event);
            }
          }}
          onBlur={onBlur}
          required={required}
          error={!!error}
          inputRef={ref}
          {...rest}
        />
      )}
    />
  );
};

export default ControlledQuantityInput;
