import * as changesets from "json-diff-ts";

import React, { useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function LimitationClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    currencyList,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddAmount, setShowAddAmount] = useState(false);
  const [editAmount, setEditAmount] = useState(false);
  const [amountId, setAmountId] = useState<number>(-1);
  const [isChildDeleted, setChildDeleted] = React.useState(true);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "limited_liability");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.limitedLiability && clauseData.limitedLiability,
    [clauseData?.limitedLiability]
  );

  const updates = useMemo(
    () => updatedClauseData?.limitedLiability || {},
    [updatedClauseData]
  );

  const limitedLiability = useMemo(() => {
    if (updates?.limited_liability) {
      let sortData = updates.limited_liability.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const amountString = useMemo(() => {
    if (updates?.amount_string) {
      let sortData = updates.amount_string.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const amounts = useMemo(() => {
    if (updates?.amounts) {
      let sortData = updates.amounts.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      sortData.map((data: any) => {
        const index = currencyList.findIndex(
          (list) => list.currencyTypeId === data.currency_type_id
        );
        const typeIndex = currencyList.findIndex(
          (list) => list.currencyName === data.currency
        );
        if (index > -1) {
          const currency = currencyList[index].currencyName;
          data.currency = currency;
        }
        if (!data.currency_type_id && typeIndex > -1) {
          const id = currencyList[typeIndex].currencyTypeId;
          data.currency_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [currencyList, updates.amounts]);

  const isAmountStringDataSame = (addedData: any, amountString: any) => {
    if (
      addedData.para_id === amountString.para_id &&
      addedData.sentence_id === amountString.sentence_id
    ) {
      if (addedData.table) {
        if (
          addedData.table[0].row === amountString.table[0].row &&
          addedData.table[0].column === amountString.table[0].column
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const combineAmount = React.useMemo(() => {
    let newData: any[] = [];
    amountString?.map((stringData: any) => {
      amounts?.map((amount: any) => {
        if (isAmountStringDataSame(amount, stringData)) {
          stringData.currency = amount.currency;
          stringData.value = amount.value;
          stringData.total = amount.total;
          newData.push(stringData);
        }
      });
      let index: number = newData.findIndex(
        (data) =>
          data.para_id === stringData.para_id &&
          data.sentence_id === stringData.sentence_id &&
          data.table[0].row === stringData.table[0].row &&
          data.table[0].column === stringData.table[0].column
      );
      if (index === -1) {
        newData.push(stringData);
      }
    });
    return newData;
  }, [amounts, amountString]);

  React.useEffect(() => {
    if (data && data.edited_content === null && isChildDeleted) {
      let updatedData = {
        ...updates,
        amount_string: [],
        amounts: [],
      };
      if (limitedLiability && limitedLiability.length) {
        amountString?.map((amount: any) => {
          let index = limitedLiability.findIndex(
            (item: any) =>
              item.para_id !== amount.para_id ||
              item.sentence_id !== amount.sentence_id
          );
          if (index > -1) {
            amountString?.splice(index, 1);
          }
        });
        amounts?.map((amount: any) => {
          let index = limitedLiability.findIndex(
            (item: any) =>
              item.para_id !== amount.para_id ||
              item.sentence_id !== amount.sentence_id
          );
          if (index > -1) {
            amounts?.splice(index, 1);
          }
        });
        setChildDeleted(false);
        updatedData = {
          ...updates,
          amount_string: amountString,
          amounts,
        };
      }
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "limited_liability", diff, updatedData);
        getClauseDataByType(fileId, "limited_liability");
      }
    }
  }, [data, amountString, amounts, hasData, limitedLiability, isChildDeleted]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let limited_liability = limitedLiability?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let amount_string = amountString?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let amountData = amounts.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        limited_liability,
        amount_string,
        amounts: amountData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "limited_liability", diff, updatedData);
      }
    },
    [
      limitedLiability,
      amountString,
      amounts,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteAmount = React.useCallback(
    (item: any) => {
      let amount_string = amountString?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let amountData = amounts.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        amount_string,
        amounts: amountData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "limited_liability", diff, updatedData);
      }
    },
    [
      amountString,
      amounts,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const editPhrase = React.useCallback(
    (data: any, index: number) => {
      let amount_string = amountString.slice();
      let amountData = amounts.slice();
      let amountIndex = amountData.findIndex(
        (amount: any) =>
          amount.para_id === amount_string[index].para_id &&
          amount.sentence_id === amount_string[index].sentence_id &&
          amount.table[0].row === amount_string[index].table[0].row &&
          amount.table[0].row === amount_string[index].table[0].row
      );
      if (amountIndex !== -1) {
        amountData[amountIndex] = data;
      } else {
        amountData.push(data);
      }
      let updatedData = {
        ...updates,
        amounts: amountData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "limited_liability", diff, updatedData);
      }
    },
    [amountString, amounts, fileId, postClauseDataByType, updates]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="limited liability"
          onClick={() =>
            handleAddEdit(
              props,
              "limited_liability",
              limitedLiability,
              setShowAdd
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="limited_liability"
          savedInsight={
            !isNullOrUndefined(limitedLiability) ? limitedLiability : []
          }
          savedParentClauseDataPoint={limitedLiability}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      <Scrollable maxHeight={200}>
        {limitedLiability?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "liability", index });
              props.onClickHighlight(index, item, "Limitation Of Liability");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "liability" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
          />
        ))}
      </Scrollable>
      {!showAddAmount ? (
        <ClauseHeader
          title="Amount"
          buttonText="amount"
          onClick={() =>
            handleAddEdit(
              props,
              "Limitation Of Liability Amount",
              amountString,
              setShowAddAmount
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Limitation Of Liability Amount"
          savedInsight={!isNullOrUndefined(amountString) ? amountString : []}
          savedParentClauseDataPoint={amountString}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddAmount(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="limited_liability"
        />
      )}
      {combineAmount?.map((amountString: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "amount", index });

            props.onClickHighlight(
              index,
              amountString,
              "Limitation Of Liability Amount"
            );
          }}
          index={index}
          clauseItem={amountString}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === "amount" && activeClause?.index === index
          }
          deleteClause={() => deleteAmount(amountString)}
          para={amountString.amountString}
          subTitle={`${amountString.currency} ${amountString.total}`}
        />
      ))}
    </>
  );
}
