import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as changesets from "json-diff-ts";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import RISelectComponent from "../../../../../RiverusUI/Components/SelectComponent";
import { AddTag, getTags } from "../../../../../Services/DocumentLibrary";
import { ClauseType } from "../../../../Constants/ClauseType";
import {
  EventData,
  LinkEventRequest,
  SentencesData,
  editedEvent,
  eventInfo,
  phraseInfo,
  tableInfo,
} from "../../../../State/documentState";
import { getClauseDataFormat } from "../../../ClauseComponent/utils/ClauseTypeUtils";
import {
  deleteEventFromEventArray,
  getHighlightedEventsFromChild,
  getPhraseEventsFromEventArray,
  getTableCellEventsFromEventArray,
} from "../../../Utils/docUtils";

interface Props {
  dataPointName: string;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editOptionSelected: (editOptionSelected: boolean) => void;
  savedInsight: any;
  editPresent: (newLinkedEventsRequest: LinkEventRequest) => void;
  savedHighlightedPhrases: phraseInfo[] | null;
  saveHighlightedPhrases: (
    savedHighlightedPhrases: phraseInfo[] | null
  ) => void;
  phraseEditOptionSelected: boolean;
  savePhraseEditOption: (phraseEditOptionSelected: boolean) => void;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  saveDeletePhrase: (
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ) => void;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  saveAddPhrase: (
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  savedEvents: EventData[];
  newEventData: EventData;
  listEvents: (event: string) => void;
  createNewEvent: (name: string, eventPoint: string) => void;
  savedHighlightedEvents: eventInfo[] | null;
  saveHighlightedEvents: (savedHighlightedEvents: eventInfo[] | null) => void;
  eventInAddEdit: eventInfo | null;
  saveEventInAddEdit: (eventInAddEdit: eventInfo | null) => void;
  eventEditingStatus: boolean;
  saveEventEditingStatus: (eventEditingStatus: boolean) => void;
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  onClose: VoidFunction;
  clauseData: any;
  canAddTags?: boolean;
}

interface State {
  eventInAddMode: eventInfo | null;
}

const EditEvent: React.FC<Props> = (props) => {
  const {
    dataPointName,
    saveHighlightedDataPoint,
    editOptionSelected,
    savedInsight,
    saveHighlightedPhrases,
    savePhraseEditOption,
    saveDeletePhrase,
    saveAddPhrase,
    saveHighlightedTableCells,
    newEventData,
    listEvents,
    savedHighlightedEvents,
    saveHighlightedEvents,
    eventInAddEdit,
    saveEventInAddEdit,
    eventEditingStatus,
    saveEventEditingStatus,
    fileId,
    clauseType,
    postClauseDataByType,
    sentenceData,
    clauseDataByType,
    updatedClauseDataByType,
    parentClauseType,
    onClose,
    canAddTags,
  } = props;

  const [state, setState] = useState<State>({
    eventInAddMode: null,
  });

  const { control, watch, resetField } = useForm();
  const queryClient = useQueryClient();
  const selectedEvent = watch("selectEvent") || "";

  const { data: allEvents, isLoading: eventLoading } = useQuery({
    queryKey: ["get_all_events", parentClauseType],
    queryFn: async () => await getTags(parentClauseType),
    select: (response: any) => response?.results,
    enabled: canAddTags,
  });

  const { mutate: addNewEvent } = useMutation({
    mutationKey: ["add_new_Events", parentClauseType],
    mutationFn: AddTag,
    onSuccess: () => {
      enqueueSnackbar("Event added successfully!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      queryClient.invalidateQueries({
        queryKey: ["get_all_events", parentClauseType],
      });
    },
    onError: (error: any) => {
      const message =
        error.response.data?.__all__?.[0] || "Failed to add Event!";
      enqueueSnackbar(message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const setEvent = (event: any) => {
    let newEventInAddMode: eventInfo = {
      eventHighlightId: -1,
      eventId: event?.id,
      eventName: event?.name,
      paraId: -1,
      sentenceId: -1,
      startWordId: -1,
      endWordId: -1,
      rowId: -1,
      columnId: -1,
      phrase: "",
      id: "",
    };
    setState({
      eventInAddMode: newEventInAddMode,
    });
  };

  useEffect(() => {
    if (selectedEvent) {
      const selectedEventData = allEvents?.find(
        (item: any) => item?.name === selectedEvent
      );
      setEvent(selectedEventData);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (eventInAddEdit !== null && eventInAddEdit.eventId === "-1") {
      if (state?.eventInAddMode !== eventInAddEdit) {
        setState((prevState: State) => ({
          ...prevState,
          eventInAddMode: eventInAddEdit,
        }));
      }
    }
  }, [eventInAddEdit, state?.eventInAddMode]);

  useEffect(() => {
    if (newEventData !== null) {
      listEvents(parentClauseType);
    }
  }, [newEventData, parentClauseType]);

  useEffect(() => {
    const eventHighlights = getHighlightedEventsFromChild(savedInsight);
    if (eventHighlights === null || eventHighlights.length === 0) {
      saveHighlightedEvents(null);
    } else {
      saveHighlightedEvents(eventHighlights);
    }
  }, [savedInsight]);

  const addOrRemovePhrase = (action: string, eventInfo: eventInfo | null) => {
    const { eventInAddMode } = state;
    saveAddPhrase(null, false);
    saveDeletePhrase(null, false);
    saveHighlightedTableCells(null);
    saveEventInAddEdit(null);
    saveEventEditingStatus(false);
    savePhraseEditOption(false);

    if (action === "add") {
      if (eventInfo !== null) {
        let tempHiglightedEvents =
          savedHighlightedEvents !== null ? savedHighlightedEvents : [];
        let addedHighlightedEvent = eventInAddMode;
        if (addedHighlightedEvent !== null) {
          addedHighlightedEvent.eventHighlightId = tempHiglightedEvents.length;
          tempHiglightedEvents.push(addedHighlightedEvent);
          saveHighlightedEvents(tempHiglightedEvents);
        }
        resetField("selectEvent");
        setState((prevState: State) => ({
          ...prevState,
          eventInAddMode: null,
        }));
      }
    } else if (action === "remove") {
      if (eventInfo !== null) {
        if (savedHighlightedEvents !== null) {
          let tempFilteredEvents = deleteEventFromEventArray(
            eventInfo,
            savedHighlightedEvents
          );
          saveHighlightedEvents(tempFilteredEvents);
        }
      }
    }
  };

  const onCancel = () => {
    onClose();
    saveHighlightedTableCells(null);
    saveHighlightedPhrases(null);
    saveDeletePhrase(null, false);
    saveAddPhrase(null, false);
    savePhraseEditOption(false);
    saveEventInAddEdit(null);
    saveEventEditingStatus(false);
    saveHighlightedEvents(null);
    editOptionSelected(false);
    setState((prevState: State) => ({
      ...prevState,
      eventInAddMode: null,
    }));
  };

  const linkToPhrase = (eventInfo: eventInfo) => {
    savePhraseEditOption(true); //confirm
    saveEventEditingStatus(true);
    saveDeletePhrase(null, false);
    saveAddPhrase(null, false);
    saveHighlightedDataPoint(dataPointName);
    saveEventInAddEdit(eventInfo);
    editOptionSelected(true);
  };

  const linkPhraseToEvent = (eventString: eventInfo | null) => {
    if (eventString !== null) {
      if (
        eventEditingStatus === true &&
        eventInAddEdit !== null &&
        eventString.eventHighlightId === eventInAddEdit.eventHighlightId
      ) {
        if (eventInAddEdit.paraId !== null && eventInAddEdit.paraId > -1) {
          return (
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Typography fontSize="14px" color="#C1C1C1">
                Edit linked phrase
              </Typography>
              <Typography fontSize="14px" color="#C1C1C1">
                1
              </Typography>
            </Stack>
          );
        } else {
          return (
            <Stack width="100%" spacing={1}>
              <Typography fontSize="14px" color="#C1C1C1">
                Link to phrase
              </Typography>
            </Stack>
          );
        }
      }
      if (
        (eventInAddEdit !== null &&
          eventInAddEdit.eventHighlightId !== eventString.eventHighlightId) ||
        eventInAddEdit === null
      ) {
        if (
          eventString.eventName !== "" &&
          (eventString.paraId === null || eventString.paraId === -1)
        ) {
          return (
            <Button
              sx={{ color: "#88305F", padding: 0 }}
              onClick={() => linkToPhrase(eventString)}
            >
              Link to phrase
            </Button>
          );
        }
      }
    }
  };

  const getSaveStatus = () => {
    let isSavedEventsLinked: boolean = false;
    if (savedHighlightedEvents !== null && savedHighlightedEvents.length > 0) {
      if (
        savedHighlightedEvents.findIndex(
          (event) => event.paraId === null || event.paraId === -1
        ) === -1
      ) {
        isSavedEventsLinked = true;
      }
    } else if (
      savedHighlightedEvents === null ||
      savedHighlightedEvents.length === 0
    ) {
      isSavedEventsLinked = true;
    }
    let isNewEventsLinked = false;
    if (state?.eventInAddMode !== null) {
      if (
        state?.eventInAddMode.paraId !== null &&
        state?.eventInAddMode.paraId !== -1
      ) {
        isNewEventsLinked = true;
      }
    } else if (state?.eventInAddMode === null) {
      isNewEventsLinked = true;
    }
    if (isSavedEventsLinked === true && isNewEventsLinked === true) {
      return true;
    }
    return false;
  };

  const editPresents = (newEventRequest: LinkEventRequest) => {
    let updatedData = updatedClauseDataByType;
    let newData = updatedData;

    if (newEventRequest.editedEvents?.upsert) {
      let addedData = newEventRequest.editedEvents.upsert;
      for (var i = 0; i < addedData.length; i++) {
        if (addedData[i].sentenceId === addedData[i].sentenceId) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
        }
      }
    }
    if (newEventRequest?.editedEvents?.deleted) {
      let deletedData = newEventRequest.editedEvents.deleted;
      for (let i = 0; i < deletedData.length; i++) {
        newData = getClauseDataFormat(
          "remove",
          clauseType as ClauseType,
          deletedData[i],
          newData,
          sentenceData
        );
      }
    }
    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType as ClauseType,
        diff,
        newData
      );
    }
  };

  const getAddedDeletedEventPhrases = (
    previousEventPhrases: eventInfo[],
    changedEventPhrases: eventInfo[]
  ) => {
    let addedEventPhrases: eventInfo[] = [];
    let deletedEventPhrases: eventInfo[] = [];

    if (previousEventPhrases.length !== 0) {
      if (changedEventPhrases !== null && changedEventPhrases.length > 0) {
        //get newly added phrases
        for (let i = 0; i < changedEventPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < previousEventPhrases.length; j++) {
            if (
              changedEventPhrases[i].eventId ===
                previousEventPhrases[j].eventId &&
              changedEventPhrases[i].paraId ===
                previousEventPhrases[j].paraId &&
              previousEventPhrases[j].startWordId ===
                changedEventPhrases[i].endWordId &&
              previousEventPhrases[j].endWordId ===
                changedEventPhrases[i].endWordId
            ) {
              exists = true;
              break;
            }
          }
          if (exists === false) {
            addedEventPhrases.push(changedEventPhrases[i]);
          }
        }

        //get deleted phrases
        for (let i = 0; i < previousEventPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < changedEventPhrases.length; j++) {
            if (
              previousEventPhrases[i].eventId ===
                changedEventPhrases[j].eventId &&
              previousEventPhrases[i].paraId ===
                changedEventPhrases[j].paraId &&
              previousEventPhrases[i].startWordId ===
                changedEventPhrases[j].startWordId &&
              previousEventPhrases[i].endWordId ===
                changedEventPhrases[j].endWordId
            ) {
              exists = true;
              break;
            }
          }
          if (exists === false) {
            deletedEventPhrases.push(previousEventPhrases[i]);
          }
        }
      } else if (changedEventPhrases.length === 0) {
        for (let i = 0; i < previousEventPhrases.length; i++) {
          deletedEventPhrases.push(previousEventPhrases[i]);
        }
      }
    } else {
      if (changedEventPhrases !== null && changedEventPhrases.length > 0) {
        for (let i = 0; i < changedEventPhrases.length; i++) {
          addedEventPhrases.push(changedEventPhrases[i]);
        }
      }
    }

    let editedEventPhrases: editedEvent = {
      upsert: addedEventPhrases,
      deleted: deletedEventPhrases,
      bi: "",
    };
    return editedEventPhrases;
  };

  const getAddedDeletedEventTableCells = (
    previousEventTableCells: eventInfo[],
    changedEventTableCells: eventInfo[]
  ) => {
    let addedEventTableCells: eventInfo[] = [];
    let deletedEventTableCells: eventInfo[] = [];

    if (previousEventTableCells.length > 0) {
      if (changedEventTableCells.length > 0) {
        //newly added
        for (let i = 0; i < changedEventTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousEventTableCells.length; j++) {
            if (
              changedEventTableCells[i].eventId ===
                previousEventTableCells[j].eventId &&
              changedEventTableCells[i].paraId ===
                previousEventTableCells[j].paraId &&
              changedEventTableCells[i].rowId ===
                previousEventTableCells[j].rowId &&
              changedEventTableCells[i].columnId ===
                previousEventTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedEventTableCells.push(changedEventTableCells[i]);
          }
        }

        //deleted elements
        for (let i = 0; i < previousEventTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedEventTableCells.length; j++) {
            if (
              previousEventTableCells[i].eventId ===
                changedEventTableCells[j].eventId &&
              previousEventTableCells[i].paraId ===
                changedEventTableCells[j].paraId &&
              previousEventTableCells[i].rowId ===
                changedEventTableCells[j].rowId &&
              previousEventTableCells[i].columnId ===
                changedEventTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (deletedCellExists === false) {
            deletedEventTableCells.push(previousEventTableCells[i]);
          }
        }
      } else {
        //previous deleted
        for (let i = 0; i < previousEventTableCells.length; i++) {
          deletedEventTableCells.push(previousEventTableCells[i]);
        }
      }
    } else {
      //all newly added
      if (changedEventTableCells.length > 0) {
        for (let i = 0; i < changedEventTableCells.length; i++) {
          addedEventTableCells.push(changedEventTableCells[i]);
        }
      }
    }

    let editedEventTableCells: editedEvent = {
      upsert: addedEventTableCells,
      deleted: deletedEventTableCells,
      bi: "",
    };
    return editedEventTableCells;
  };

  const mergeAddedDeletedHighlightedEvent = (
    firstHighlightedEvents: editedEvent,
    secondHighlightedEvents: editedEvent
  ) => {
    let upsertHighlightedEvents: eventInfo[] =
      firstHighlightedEvents.upsert.concat(secondHighlightedEvents.upsert);
    let deletedHighlightedEvents: eventInfo[] =
      firstHighlightedEvents.deleted.concat(secondHighlightedEvents.deleted);

    let mergeHighlightedEvents: editedEvent = {
      upsert: upsertHighlightedEvents,
      deleted: deletedHighlightedEvents,
      bi: "event",
    };
    return mergeHighlightedEvents;
  };

  const getTempPhrase = () => {
    let tempHighlightedEvents =
      savedHighlightedEvents !== null ? savedHighlightedEvents : [];
    if (
      state?.eventInAddMode !== null &&
      state?.eventInAddMode.eventName !== ""
    ) {
      tempHighlightedEvents.push(state?.eventInAddMode);
    }

    let oldHighlightedEvents = getHighlightedEventsFromChild(savedInsight);
    let changedHighlightedEvents = tempHighlightedEvents;
    let oldHighlightedEventPhrases =
      getPhraseEventsFromEventArray(oldHighlightedEvents);
    let changedHighlightedEventPhrases = getPhraseEventsFromEventArray(
      changedHighlightedEvents
    );
    let oldHighlightedEventTableCells =
      getTableCellEventsFromEventArray(oldHighlightedEvents);
    let changedHighlightedEventTableCells = getTableCellEventsFromEventArray(
      changedHighlightedEvents
    );

    let editedEventPhrases = getAddedDeletedEventPhrases(
      oldHighlightedEventPhrases,
      changedHighlightedEventPhrases
    );
    let editedEventTableCells = getAddedDeletedEventTableCells(
      oldHighlightedEventTableCells,
      changedHighlightedEventTableCells
    );

    let addedDeletedHighlightedEvents = mergeAddedDeletedHighlightedEvent(
      editedEventPhrases,
      editedEventTableCells
    );

    let newEventRequest: LinkEventRequest = {
      editedEvents: addedDeletedHighlightedEvents,
      mode: "manual",
    };

    return newEventRequest;
  };

  const onSave = () => {
    let tempPhraseRequest: LinkEventRequest = getTempPhrase();
    editPresents(tempPhraseRequest);
    onCancel();
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add /Edit {dataPointName}
      </Typography>
      <Stack
        className="edit-clause-select"
        spacing={2}
        width="100%"
        alignItems="start"
      >
        {savedHighlightedEvents !== null &&
          savedHighlightedEvents.length > 0 &&
          savedHighlightedEvents.map((eventIter, i) => (
            <Stack spacing={1} width="100%" alignItems="start">
              <Stack
                spacing={1}
                direction="row"
                width="100%"
                alignItems="center"
              >
                <Typography fontSize="14px">{i + 1}</Typography>
                <input
                  type="text"
                  className="tag-input"
                  value={eventIter.eventName}
                  style={{ width: "100%" }}
                  readOnly
                  onChange={() => {}}
                />
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => addOrRemovePhrase("remove", eventIter)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Stack>
              {linkPhraseToEvent(eventIter)}
            </Stack>
          ))}
        <Stack width="100%" alignItems="center" spacing={2} direction="row">
          <Stack width="100%">
            {canAddTags && (
              <RISelectComponent
                name="selectEvent"
                control={control}
                label="Events"
                options={allEvents}
                loading={eventLoading}
                valueKey="name"
                canCreateNew
                addNewValue={(value) =>
                  addNewEvent({ name: value, type: parentClauseType })
                }
              />
            )}
          </Stack>
          <IconButton
            disabled={state?.eventInAddMode === null}
            sx={{ padding: 0 }}
            onClick={() => addOrRemovePhrase("add", state?.eventInAddMode)}
          >
            <AddIcon />
          </IconButton>
        </Stack>
        {linkPhraseToEvent(state?.eventInAddMode)}
        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to add events ?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Select any option using the above field.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Click on "Link To Phrase".
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Highlight and copy the desired phrase.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Click on the copy icon.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            6). Confirm your selection by clicking on the Save button below.
          </Typography>
        </Stack>

        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={() => onSave()}
            disabled={!getSaveStatus()}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => onCancel()}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditEvent;
