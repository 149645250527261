import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import * as changesets from "json-diff-ts";
import React, { useEffect, useState } from "react";
import { ClauseType } from "../../../../Constants/ClauseType";
import {
  LinkPhraseRequest,
  SentencesData,
  editedPhrases,
  phraseInfo,
  tableInfo,
} from "../../../../State/documentState";
import { getClauseDataFormat } from "../../../ClauseComponent/utils/ClauseTypeUtils";
import {
  deletePhraseFromPhraseArray,
  filterPhrasesFromPhrasesArray,
  filterTableCellsFromPhraseArray,
  getPhrasesFromChild,
  isTableCell,
  phraseBiMap,
  phraseLevelMapping,
} from "../../../Utils/docUtils";
interface Props {
  dataPointName: string;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editOptionSelected: (editOptionSelected: boolean) => void;
  savedInsight: any;
  editPhrasesRequest: (newPhraseRequest: LinkPhraseRequest) => void;
  savedHighlightedPhrases: phraseInfo[] | null;
  saveHighlightedPhrases: (
    savedHighlightedPhrases: phraseInfo[] | null
  ) => void;
  phraseEditOptionSelected: boolean;
  savePhraseEditOption: (phraseEditOptionSelected: boolean) => void;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  saveDeletePhrase: (
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ) => void;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  saveAddPhrase: (
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  onClose: VoidFunction;
  clauseData: any;
}

const EditPhrase: React.FC<Props> = (props) => {
  const [isAddingPhrase, setIsAddingPhrase] = useState(false);
  const [currentEditingPhrase, setCurrentEditingPhrase] =
    useState<phraseInfo | null>(null);

  useEffect(() => {
    let oldPhrases = getPhrasesFromChild(props.savedInsight, props.clauseType);
    props.saveHighlightedPhrases(oldPhrases);
    props.saveHighlightedDataPoint(props.dataPointName);
  }, []);

  useEffect(() => {
    if (props.phraseInAddMode !== null) {
      setIsAddingPhrase(false);
    }
  }, [props.phraseInAddMode]);

  const getAddedAndDeletedPhrases = (
    previousLinkedPhrases: phraseInfo[],
    changedLinkedPhrases: phraseInfo[]
  ): editedPhrases => {
    let addedPhrases: phraseInfo[] = [];
    let deletedPhrases: phraseInfo[] = [];
    if (previousLinkedPhrases.length !== 0) {
      if (changedLinkedPhrases !== null && changedLinkedPhrases.length !== 0) {
        //get newly added phrases
        for (let i = 0; i < changedLinkedPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < previousLinkedPhrases.length; j++) {
            if (
              changedLinkedPhrases[i].paraId === previousLinkedPhrases[j].paraId
            ) {
              if (
                changedLinkedPhrases[i].startWordId ===
                  previousLinkedPhrases[j].startWordId &&
                changedLinkedPhrases[i].endWordId ===
                  previousLinkedPhrases[j].endWordId
              ) {
                exists = true;
                break;
              }
            }
          }
          if (exists === false) {
            addedPhrases.push(changedLinkedPhrases[i]);
          }
        }

        //get Deleted phrases
        for (let i = 0; i < previousLinkedPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < changedLinkedPhrases.length; j++) {
            if (
              previousLinkedPhrases[i].paraId === changedLinkedPhrases[j].paraId
            ) {
              if (
                previousLinkedPhrases[i].startWordId ===
                  changedLinkedPhrases[j].startWordId &&
                previousLinkedPhrases[i].endWordId ===
                  changedLinkedPhrases[j].endWordId
              ) {
                exists = true;
                break;
              }
            }
          }
          if (exists === false) {
            deletedPhrases.push(previousLinkedPhrases[i]);
          }
        }
        //all deleted
      } else if (changedLinkedPhrases.length === 0) {
        for (let i = 0; i < previousLinkedPhrases.length; i++) {
          deletedPhrases.push(previousLinkedPhrases[i]);
        }
      }
    } else {
      //newly added
      if (changedLinkedPhrases !== null && changedLinkedPhrases.length !== 0) {
        for (let i = 0; i < changedLinkedPhrases.length; i++) {
          addedPhrases.push(changedLinkedPhrases[i]);
        }
      }
    }
    let biType = phraseBiMap[props.dataPointName];

    let tempEditedPhrases: editedPhrases = {
      upsert: addedPhrases,
      deleted: deletedPhrases,
      bi: biType,
    };
    return tempEditedPhrases;
  };

  const getEditedTableCellPhrases = (
    previousLinkedTableCells: phraseInfo[],
    changedLinkedTableCells: phraseInfo[]
  ) => {
    let addedTableCells: phraseInfo[] = [];
    let deletedTableCells: phraseInfo[] = [];
    if (previousLinkedTableCells.length > 0) {
      if (changedLinkedTableCells.length > 0) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId ===
                previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId ===
                previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId ===
                previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push(changedLinkedTableCells[i]);
          }
        }

        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId ===
                changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId ===
                changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId ===
                changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (deletedCellExists === false) {
            deletedTableCells.push(previousLinkedTableCells[i]);
          }
        }
      } else {
        //previous deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push(previousLinkedTableCells[i]);
        }
      }
    } else {
      //all newly added
      if (changedLinkedTableCells.length > 0) {
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          addedTableCells.push(changedLinkedTableCells[i]);
        }
      }
    }

    let editedTableCells: editedPhrases = {
      upsert: addedTableCells,
      deleted: deletedTableCells,
      bi: "",
    };
    return editedTableCells;
  };

  const mergePhrases = (
    firstEditedPhraseArray: editedPhrases,
    secondEditedPhraseArray: editedPhrases
  ) => {
    let upsertPhrases: phraseInfo[] = firstEditedPhraseArray.upsert.concat(
      secondEditedPhraseArray.upsert
    );
    let deletedPhrases: phraseInfo[] = firstEditedPhraseArray.deleted.concat(
      secondEditedPhraseArray.deleted
    );
    let biType = phraseBiMap[props.dataPointName];
    let mergedPhrases: editedPhrases = {
      upsert: upsertPhrases,
      deleted: deletedPhrases,
      bi: biType,
    };
    return mergedPhrases;
  };

  const linkToPhrase = () => {
    props.savePhraseEditOption(true);
    setIsAddingPhrase(true);
    setCurrentEditingPhrase(null);
    props.saveDeletePhrase(null, false);
    props.saveHighlightedTableCells(null);
    props.editOptionSelected(true);
    props.saveHighlightedDataPoint(props.dataPointName);
  };

  const editLinkedPhraseOnDoc = (phraseInfo: phraseInfo) => {
    let deletePhraseElement = "";

    if (isTableCell(phraseInfo)) {
      let tempTableCell: tableInfo = {
        paraId: phraseInfo.paraId,
        rowId: phraseInfo.rowId !== null ? phraseInfo.rowId : -1,
        columnId: phraseInfo.columnId !== null ? phraseInfo.columnId : -1,
      };
      deletePhraseElement =
        "p" +
        phraseInfo.paraId +
        ";r" +
        phraseInfo.rowId +
        ";c" +
        phraseInfo.columnId;
      props.saveHighlightedTableCells([tempTableCell]);
    } else {
      deletePhraseElement =
        "p" + phraseInfo.paraId + ";w" + phraseInfo.startWordId;
      props.saveHighlightedTableCells(null);
    }
    let phraseElement = document.getElementById(deletePhraseElement);
    phraseElement !== undefined &&
      phraseElement !== null &&
      phraseElement.scrollIntoView({ block: "center" }); //scroll to linked phrase
    document.documentElement.style.scrollBehavior = "smooth";

    props.saveDeletePhrase(phraseInfo, true);
    props.saveHighlightedDataPoint(props.dataPointName);
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(phraseInfo);
    props.editOptionSelected(true);
  };

  const linkPhraseOnDoc = (phraseInfo: phraseInfo | null) => {
    if (phraseInfo === null) {
      return (
        <Stack alignItems="start" spacing={1} width="100%">
          <Button
            sx={{
              color:
                isAddingPhrase !== true
                  ? "#88305F"
                  : props.phraseEditOptionSelected === true
                  ? "#C1C1C1"
                  : "#88305F",
              padding: 0,
            }}
            onClick={() => linkToPhrase()}
          >
            Link phrase
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Button
            sx={{
              color:
                currentEditingPhrase === phraseInfo ? "#C1C1C1" : "#88305F",
              padding: 0,
            }}
            onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
          >
            Edit linked phrase
          </Button>
          <Typography fontSize="14px">{phraseInfo === null ? 0 : 1}</Typography>
        </Stack>
      );
    }
  };

  const addOrRemovePhrase = (action: string, phraseInfo: phraseInfo | null) => {
    if (action === "add") {
      props.saveAddPhrase(null, false);
      props.saveDeletePhrase(null, false);
      props.saveHighlightedTableCells(null);
      let tempPhrases = props.savedHighlightedPhrases;
      if (tempPhrases !== null && phraseInfo !== null) {
        tempPhrases.push(phraseInfo);
        props.saveHighlightedPhrases(tempPhrases);
      } else {
        if (phraseInfo === null) {
          props.saveHighlightedPhrases(phraseInfo);
        } else {
          props.saveHighlightedPhrases([phraseInfo]);
        }
      }
      setIsAddingPhrase(false);
      setCurrentEditingPhrase(null);
    } else if (action === "remove") {
      if (phraseInfo) {
        let tempPhrases = deletePhraseFromPhraseArray(
          phraseInfo,
          props.savedHighlightedPhrases
        );
        props.saveHighlightedPhrases(
          tempPhrases.length === 0 ? null : tempPhrases
        );
      } else {
        props.saveHighlightedPhrases(null);
      }
      props.saveDeletePhrase(null, false);
      props.saveHighlightedTableCells(null);
    }
    props.savePhraseEditOption(false);
  };

  const getPhraseEdit = () => {
    let { savedHighlightedPhrases, phraseInAddMode } = props;
    if (
      savedHighlightedPhrases !== null &&
      savedHighlightedPhrases.length > 0
    ) {
      return (
        <>
          {savedHighlightedPhrases.map((phraseIter: any) => (
            <Stack width="100%" spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
                spacing={1}
              >
                <textarea
                  rows={2}
                  cols={50}
                  name="text"
                  maxLength={50}
                  className="tag-input"
                  value={phraseIter.phrase}
                  style={{
                    minHeight: "131px",
                    lineHeight: "15px",
                    width: "80%",
                  }}
                  readOnly
                  onChange={() => {}}
                ></textarea>

                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => addOrRemovePhrase("remove", phraseIter)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Stack>

              {linkPhraseOnDoc(phraseIter)}
            </Stack>
          ))}
          <Stack width="100%" spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              spacing={1}
            >
              <textarea
                rows={2}
                cols={50}
                name="text"
                maxLength={50}
                className="tag-input"
                readOnly
                value={phraseInAddMode !== null ? phraseInAddMode.phrase : ""}
                style={{
                  minHeight: "131px",
                  lineHeight: "15px",
                  width: "80%",
                }}
                onChange={() => {}}
              ></textarea>
              <IconButton
                disabled={phraseInAddMode === null}
                sx={{ padding: 0 }}
                onClick={() => addOrRemovePhrase("add", phraseInAddMode)}
              >
                <AddIcon />
              </IconButton>
            </Stack>
            {linkPhraseOnDoc(phraseInAddMode)}
          </Stack>
        </>
      );
    } else {
      return (
        <Stack spacing={1} width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            spacing={1}
          >
            <textarea
              rows={3}
              cols={70}
              name="text"
              maxLength={50}
              className="tag-input"
              value={phraseInAddMode !== null ? phraseInAddMode.phrase : ""}
              style={{ minHeight: "131px", lineHeight: "15px", width: "80%" }}
            ></textarea>
            <IconButton
              disabled={phraseInAddMode === null}
              sx={{ padding: 0 }}
              onClick={() => addOrRemovePhrase("add", phraseInAddMode)}
            >
              <AddIcon />
            </IconButton>
          </Stack>
          {linkPhraseOnDoc(phraseInAddMode)}
        </Stack>
      );
    }
  };

  const onCancel = () => {
    props.onClose();
    props.saveDeletePhrase(null, false);
    props.saveAddPhrase(null, false);
    props.saveHighlightedPhrases(null);
    props.savePhraseEditOption(false);
    props.editOptionSelected(false);
    props.saveHighlightedTableCells(null);
  };

  const getTempPhrase = () => {
    let tempPhraseRequest: LinkPhraseRequest = {
      mode: "",
      editedPhrases: { upsert: [], deleted: [], bi: "" },
    };

    let oldPhrasesArray = getPhrasesFromChild(
      props.savedInsight,
      props.clauseType
    );

    let oldPhrases = filterPhrasesFromPhrasesArray(oldPhrasesArray);
    let oldTableCells = filterTableCellsFromPhraseArray(oldPhrasesArray);
    let changedPhrasesArray =
      props.savedHighlightedPhrases !== null
        ? props.savedHighlightedPhrases
        : [];
    if (props.phraseInAddMode !== null) {
      changedPhrasesArray.push(props.phraseInAddMode);
    }
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: "",
    };

    let changedPhrases = filterPhrasesFromPhrasesArray(changedPhrasesArray);
    let changedTableCells =
      filterTableCellsFromPhraseArray(changedPhrasesArray);

    let editedPhrases = getAddedAndDeletedPhrases(oldPhrases, changedPhrases);
    let editedTableCells = getEditedTableCellPhrases(
      oldTableCells,
      changedTableCells
    );
    addedDeletedPhrases = mergePhrases(editedPhrases, editedTableCells);

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: "manual",
    };

    return tempPhraseRequest;
  };

  const onSave = () => {
    let tempPhraseRequest = getTempPhrase();
    const {
      clauseType,
      updatedClauseDataByType,
      sentenceData,
      clauseDataByType,
      updatedClauseData,
      postClauseDataByType,
      fileId,
      parentClauseType,
      clauseData,
    } = props;

    let updatedData = updatedClauseDataByType;
    let newData = updatedData;

    let addedData = tempPhraseRequest.editedPhrases.upsert;

    for (var i = 0; i < addedData.length; i++) {
      if (addedData[i].startSentenceId === addedData[i].endSentenceId) {
        newData = getClauseDataFormat(
          "add",
          clauseType as ClauseType,
          addedData[i],
          newData,
          sentenceData
        );
      }
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType as ClauseType,
        diff,
        newData
      );
    }

    props.editPhrasesRequest(tempPhraseRequest);
    props.saveDeletePhrase(null, false);
    props.saveAddPhrase(null, false);
    props.savePhraseEditOption(false);
    props.saveHighlightedPhrases(null);
    props.editOptionSelected(false);
    props.onClose();
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600}>
        Add/edit {phraseLevelMapping[props.dataPointName]}
      </Typography>
      <Stack className="edit-clause-select" spacing={2}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ paddingRight: "20px" }}
        >
          {getPhraseEdit()}
        </Grid>
        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to link a phrase ?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Click on "Link Phrase".
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Select and copy the desired phrase.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the copy icon.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Click on the + icon besides the box where text is copied.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            6). Confirm your selection by clicking on the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditPhrase;
