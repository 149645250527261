import React, { useCallback, useEffect, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import RISelectComponent from "../../../RiverusUI/Components/SelectComponent";
import {
  addStampForDraft,
  fetchStampDenominations,
  fetchStamps,
  fetchState,
} from "../../../Services/Stamp";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AddOutlined } from "@mui/icons-material";
import ControlledQuantityInput from "../../../RiverusUI/Components/FormInputControl/QuantityInput";
import { useSnackbar } from "notistack";
import { draftStatus } from "../../State/DraftState";
import StampTable from "../../../StampBank/StampTable";

interface IProps {
  id: string;
  status: string;
}

const StampPaper = (props: IProps) => {
  const { id, status } = props;
  const methods = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { control, handleSubmit, watch, reset } = methods;
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "denominations",
  });

  const watchDenominations = useWatch({
    name: "denominations",
    control,
  });

  const state = watch("state") || "";

  useEffect(() => {
    replace({ denomination: "", count: 1 });
  }, [replace, state]);

  const { data: stateData, isLoading: stateLoading } = useQuery({
    queryKey: ["StateList"],
    queryFn: fetchState,
  });

  const stateName = useMemo(() => {
    if ((stateData as any)?.results) {
      return (stateData as any).results.filter(
        (data: any) => data.id === state
      )?.[0]?.name;
    } else {
      return "";
    }
  }, [stateData, state]);

  const { data: denominations } = useQuery({
    queryKey: ["stamp-bank-list", stateName],
    queryFn: () => fetchStampDenominations(`?state=${stateName}`),
    enabled: !!stateName,
  });

  const { data: draftStamps, isLoading } = useQuery({
    queryKey: ["draft-stamps"],
    queryFn: () => fetchStamps(`?draft=${id}`),
    enabled: !!id,
  });

  const { mutate: addStamp } = useMutation({
    mutationFn: addStampForDraft,
    onSuccess: () => {
      reset();
      replace({ denominations: "", count: 1 });
      enqueueSnackbar("Stamp added successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      queryClient.invalidateQueries({ queryKey: ["draft-stamps"] });
    },
  });

  const totalDenominationsValue = useMemo(() => {
    let count = 0;
    watchDenominations?.map((data: any) => {
      count = count + data.denomination * data.count;
    });
    if (draftStamps?.results?.length) {
      draftStamps?.results?.map((data: any) => {
        count = count + data.denomination;
      });
    }
    return count;
  }, [draftStamps, watchDenominations]);

  const getDenomination = useCallback(
    (field: any) => {
      return denominations?.filter(
        (data: any) => data.denomination === field.denomination
      )[0];
    },
    [denominations]
  );

  const renderMessage = useCallback(
    (index: number, denominations: any) => {
      if (denominations?.[index]?.denomination) {
        const selectedDenomination = getDenomination(denominations[index]);
        return `${
          selectedDenomination?.total - denominations[index].count
        } stamp paper remaining`;
      }
      return "";
    },
    [getDenomination]
  );

  const getMax = useCallback(
    (index: number, denominations: any) => {
      if (denominations?.[index]?.denomination) {
        const selectedDenomination = getDenomination(denominations[index]);
        return selectedDenomination?.total || 1;
      }
      return 1;
    },
    [getDenomination]
  );

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      draft: id,
    };
    const index = data.denominations.findIndex(
      (data: any) => data.denomination === ""
    );
    if (index === -1) {
      addStamp(payload);
    } else {
      enqueueSnackbar("Please add denomination", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  };

  return (
    <Accordion className="according-class" sx={{ mb: "14px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className="clause-heading"
      >
        Stamp paper (optional)
        {totalDenominationsValue > 0 && <span>: &#x20B9;</span>}
        {totalDenominationsValue > 0 && totalDenominationsValue}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0 0 16px" }}>
        {status === draftStatus?.COMMITTED_FOR_SIGNATURE &&
        draftStamps?.results?.length === 0 ? (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={2}>
                <RISelectComponent
                  required
                  name="state"
                  control={control}
                  label="Select State(province)"
                  options={(stateData as any)?.results}
                  loading={stateLoading}
                />
                {state && (
                  <Stack gap={1}>
                    {fields.map((field, index) => (
                      <>
                        <Stack direction="row" gap={2}>
                          <Box sx={{ flex: 1 }}>
                            <RISelectComponent
                              key={field.id}
                              label="select denomination"
                              options={denominations || []}
                              labelKey="denomination"
                              valueKey="denomination"
                              control={control}
                              name={`denominations[${index}].denomination`}
                            />
                          </Box>
                          <ControlledQuantityInput
                            key={field.id}
                            name={`denominations[${index}].count`}
                            control={control}
                            min={0}
                            max={getMax(index, watchDenominations)}
                            deleteQuantity={() => remove(index)}
                          />
                        </Stack>
                        <Typography>
                          {renderMessage(index, watchDenominations)}
                        </Typography>
                      </>
                    ))}
                    <Stack direction="row" justifyContent="space-between">
                      <Button
                        variant="text"
                        startIcon={<AddOutlined />}
                        onClick={() => {
                          append({
                            denomination: "",
                            count: 1,
                          });
                        }}
                        sx={{ whiteSpace: "nowrap", padding: 0 }}
                      >
                        Add another stamp paper denomination
                      </Button>
                      <Button variant="text" type="submit">
                        Save
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </form>
          </FormProvider>
        ) : (
          <StampTable
            data={draftStamps}
            isLoading={isLoading}
            showAction={false}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default StampPaper;
