import buttonTheme from "./button";
import checkboxTheme from "./checkbox";
import chipTheme from "./chips";
import formControlLabelTheme from "./formControlLlabel";
import radioTheme from "./radio";
import modalTheme from "./modal";
import drawerTheme from "./drawer";
import rangeTheme from "./range";
import switchTheme from "./switch";
import tabTheme from "./tab";
import tabsTheme from "./tabs";
import tooltipTheme from "./tooltip";
import textFieldTheme from "./textField";

const components = {
  MuiButton: buttonTheme,
  MuiSwitch: switchTheme,
  MuiSlider:rangeTheme,
  MuiTab: tabTheme,
  MuiTabs:tabsTheme,
  MuiCheckbox: checkboxTheme,
  MuiFormControlLabel:formControlLabelTheme,
  MuiRadio:radioTheme,
  MuiTooltip: tooltipTheme,
  MuiChip: chipTheme,
  MuiTextField:textFieldTheme,
  MuiModal: modalTheme,
  MuiDrawer: drawerTheme,
};

export default components;
