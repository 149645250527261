export const userTypes = [
  {
    value: "external",
    title: "External users (counterparty)",
  },
  {
    value: "internal",
    title: "Internal user",
  },
];

export const captureMethods = [
  {
    id: 1,
    value: "stylus",
    label: "Stylus (Riverus)",
  },
  {
    id: 2,
    value: "NDSL Adhar",
    label: "NDSL Adhar",
  },
  {
    id: 3,
    value: "docusign",
    label: "Docusign",
  },
  {
    id: 4,
    value: "adobe",
    label: "Adobe",
  },
];