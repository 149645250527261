import * as changesets from "json-diff-ts";

import React from "react";
import { ClauseType } from "../../../../Constants/ClauseType";
import {
  LinkParaRequest,
  SentencesData,
  editedParas,
  paraInfo,
  tableInfo,
} from "../../../../State/documentState";
import {
  getClauseDataFormat,
  getClauseObjects,
} from "../../../ClauseComponent/utils/ClauseTypeUtils";
import {
  getParasFromChild,
  getTableCellsFromChild,
  paraBiMap,
} from "../../../Utils/docUtils";

import { Box, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import RadioButtonGroup from "../../../../../RiverusUI/Components/RadioButtonGroup";
import SaveOrCancel from "../saveOrCancel";

interface Props {
  mode?: string;
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresent: (newParasRequest: LinkParaRequest) => void;
  savedPresent: string;
  dataPointName: string;
  highlightedId: number[] | null;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedPresentData: any;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  onClose: any;
  fileId: string;
  clauseType: string;
  clauseData: any;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
}

const EditLinkPara: React.FC<Props> = ({
  editOptionSelected,
  saveHighlightedDataPoint,
  editPresent,
  dataPointName,
  highlightedId,
  saveHighlightedId,
  savedPresentData,
  savedHighlightedTableCells,
  saveHighlightedTableCells,
  onClose,
  fileId,
  clauseType,
  postClauseDataByType,
  sentenceData,
  clauseDataByType,
  updatedClauseDataByType,
  parentClauseType,
  mode,
}) => {
  const { control, watch } = useForm({
    defaultValues: {
      isPresent: "Yes",
    },
  });

  const clauseName = dataPointName?.replace(/_/g, " ");
  const clause_name = clauseName.includes("clause")
    ? clauseName
    : `${clauseName} clause`;

  const clauseOptions = [
    {
      value: "Yes",
      title: `${clause_name} is present`,
    },
    {
      value: "No",
      title: `${clause_name} is not present`,
    },
  ];

  const isPresent = watch("isPresent") || "";

  const saveHighlightedDataPoints = (highlight?: boolean) => {
    saveHighlightedDataPoint(dataPointName);
  };

  const getAddedAndDeletedParas = (
    previousParas: number[],
    changedParas: number[]
  ) => {
    let addedParas: paraInfo[] = [];
    let deletedParas: paraInfo[] = [];
    if (previousParas.length !== 0) {
      if (changedParas.length !== 0) {
        //get newly added elements
        for (let i = 0; i < changedParas.length; i++) {
          let exists = false;
          for (let j = 0; j < previousParas.length; j++) {
            if (changedParas[i] === previousParas[j]) {
              exists = true;
              break;
            }
          }
          if (exists === false) {
            addedParas.push({
              paraId: changedParas[i],
              rowId: -1,
              columnId: -1,
            });
          }
        }

        //get deleted elements
        for (let i = 0; i < previousParas.length; i++) {
          let exists = false;
          for (let j = 0; j < changedParas.length; j++) {
            if (previousParas[i] === changedParas[j]) {
              exists = true;
              break;
            }
          }
          if (exists === false) {
            deletedParas.push({
              paraId: previousParas[i],
              rowId: -1,
              columnId: -1,
            });
          }
        }
      } else if (changedParas.length === 0) {
        //all previous paras deleted
        for (let i = 0; i < previousParas.length; i++) {
          deletedParas.push({
            paraId: previousParas[i],
            rowId: -1,
            columnId: -1,
          });
        }
      }
    } else {
      //adding for first time
      if (changedParas.length !== null) {
        if (changedParas.length !== 0) {
          for (let i = 0; i < changedParas.length; i++) {
            addedParas.push({
              paraId: changedParas[i],
              rowId: -1,
              columnId: -1,
            });
          }
        }
      }
    }

    let addedDeletedParas: editedParas = {
      upsert: addedParas,
      deleted: deletedParas,
      bi: "",
    };

    return addedDeletedParas;
  };

  const getAddedAndDeletedTableCells = (
    previousLinkedTableCells: tableInfo[],
    changedLinkedTableCells: tableInfo[] | null
  ) => {
    let addedTableCells: tableInfo[] = [];
    let deletedTableCells: tableInfo[] = [];
    if (
      previousLinkedTableCells.length !== 0 &&
      previousLinkedTableCells !== null
    ) {
      if (
        changedLinkedTableCells !== null &&
        changedLinkedTableCells.length !== 0
      ) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId ===
                previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId ===
                previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId ===
                previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push({
              paraId: changedLinkedTableCells[i].paraId,
              rowId: changedLinkedTableCells[i].rowId,
              columnId: changedLinkedTableCells[i].columnId,
            });
          }
        }

        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId ===
                changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId ===
                changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId ===
                changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (deletedCellExists === false) {
            deletedTableCells.push({
              paraId: previousLinkedTableCells[i].paraId,
              rowId: previousLinkedTableCells[i].rowId,
              columnId: previousLinkedTableCells[i].columnId,
            });
          }
        }
      } else {
        //all deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else if (
      changedLinkedTableCells !== null &&
      changedLinkedTableCells.length !== 0
    ) {
      //newly added
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        addedTableCells.push(changedLinkedTableCells[i]);
      }
    }

    //Convert table cells json to para level json
    let upsertParas: paraInfo[] = [];
    let deletedParas: paraInfo[] = [];

    for (let i = 0; i < addedTableCells.length; i++) {
      upsertParas.push({
        paraId: addedTableCells[i].paraId,
        rowId: addedTableCells[i].rowId,
        columnId: addedTableCells[i].columnId,
      });
    }

    for (let i = 0; i < deletedTableCells.length; i++) {
      deletedParas.push({
        paraId: deletedTableCells[i].paraId,
        rowId: deletedTableCells[i].rowId,
        columnId: deletedTableCells[i].columnId,
      });
    }

    let editedTableCellsAsPara: editedParas = {
      upsert: upsertParas,
      deleted: deletedParas,
      bi: "",
    };
    return editedTableCellsAsPara;
  };

  const mergeEditedPara = (
    firstEditedParas: editedParas,
    secondEditedParas: editedParas
  ) => {
    let mergedAddedParas: paraInfo[] = firstEditedParas.upsert.concat(
      secondEditedParas.upsert
    );
    let mergedDeletedParas: paraInfo[] = firstEditedParas.deleted.concat(
      secondEditedParas.deleted
    );

    let mergeEditedParas: editedParas = {
      upsert: mergedAddedParas,
      deleted: mergedDeletedParas,
      bi: paraBiMap[dataPointName],
    };
    return mergeEditedParas;
  };

  const addOrRemovePresent = (action: string) => {
    let tempPresentList: LinkParaRequest = {
      data: "",
      mode: "",
      editedParas: { upsert: [], deleted: [], bi: "" },
    };

    let previousParas = getParasFromChild(savedPresentData);
    let changedParas: number[] = [];
    if (highlightedId !== null) {
      changedParas = highlightedId;
    } else {
      changedParas = [];
    }

    let previousTableCells = getTableCellsFromChild(savedPresentData);
    let changedLinkedTableCells = savedHighlightedTableCells;
    //edited Paras
    let editedParas: editedParas = getAddedAndDeletedParas(
      previousParas,
      changedParas
    );
    //edited Table cells
    let editedTablesCells: editedParas = getAddedAndDeletedTableCells(
      previousTableCells,
      changedLinkedTableCells
    );
    //merge both
    let mergeEditedParas: editedParas = mergeEditedPara(
      editedParas,
      editedTablesCells
    );

    if (action === "add") {
      if (highlightedId !== null || savedHighlightedTableCells !== null) {
        tempPresentList = {
          data: isPresent,
          mode: "manual",
          editedParas: mergeEditedParas,
        };
      } else {
        tempPresentList = {
          data: isPresent,
          mode: "retain",
        };
      }
    }
    return tempPresentList;
  };

  const editPresents = () => {
    let newParasRequest = addOrRemovePresent("add");
    editPresent(newParasRequest);
    let newData = updatedClauseDataByType;

    if (isPresent === "Yes") {
      if (newParasRequest.editedParas?.upsert) {
        let addedData = newParasRequest.editedParas.upsert;
        for (var i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
        }
      }
      if (newParasRequest?.editedParas?.deleted) {
        let deletedData = newParasRequest.editedParas.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            "remove",
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      if (mode) {
        const dispute_resolution = newData?.dispute_resolution?.filter(
          (data: any) => {
            let modes = data.modes.filter((item: any) => item.key !== mode);
            data.modes = modes;
            return data;
          }
        );
        newData = {
          ...newData,
          dispute_resolution,
        };
      } else {
        newData = getClauseObjects(clauseType, newData);
      }
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }

    onClose();
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} textTransform="capitalize">
        Add / Edit {clause_name}
      </Typography>
      <Stack direction="column" className="edit-clause-select" spacing={2}>
        <RadioButtonGroup
          row
          required
          name="isPresent"
          options={clauseOptions}
          valueKey="value"
          control={control}
        />
        <Stack width="100%">
          <Typography variant="body2" fontWeight={700}>
            How to add a paragraph to{" "}
            <span style={{ textTransform: "capitalize" }}>{clause_name}</span>?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Select "
            <span style={{ textTransform: "capitalize" }}>{clause_name}</span>{" "}
            Is Present"
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on "Edit Linked Paragraph(s)" button.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            4). Click on the desired text or the link icon on the box around the
            text that you want to add as {clause_name}.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            5). Click on Save.
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body2" fontWeight={700}>
            How to remove a paragraph from {clause_name}?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Hover over the highlighted text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on the bin icon against the highlighted paragraph you want
            to remove from {clause_name}.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Click on Save.
          </Typography>
        </Stack>
        <SaveOrCancel
          enableHighlightOption={isPresent === "Yes"}
          dataPointName={dataPointName}
          editOptionSelected={editOptionSelected}
          editDataPoint={() => {
            editPresents();
            saveHighlightedId(null);
            editOptionSelected(false);
            saveHighlightedTableCells(null);
            onClose();
          }}
          highlightedId={highlightedId}
          saveHighlightedDataPoint={(highlight?: boolean) =>
            saveHighlightedDataPoints(highlight)
          }
          enableSaveBtn={true}
          saveHighlightedId={(highlightedId: number[] | null) => {
            saveHighlightedId(highlightedId);
          }}
          previouslyLinkedPara={
            getParasFromChild(savedPresentData)?.length > 0
              ? getParasFromChild(savedPresentData)
              : undefined
          }
          previouslyLinkedTableCells={
            getTableCellsFromChild(savedPresentData)?.length > 0
              ? getTableCellsFromChild(savedPresentData)
              : undefined
          }
          savedHighlightedTableCells={savedHighlightedTableCells}
          saveHighlightedTableCells={(
            savedHighlightedTableCells: tableInfo[] | null
          ) => saveHighlightedTableCells(savedHighlightedTableCells)}
          handleSubmitSelectedUserGroups={() => {}}
          onClose={onClose}
        />
      </Stack>
    </Box>
  );
};

export default EditLinkPara;
