import * as changesets from "json-diff-ts";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { isNullOrUndefined } from "is-what";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import EditFeature from "../EditFeature/Container/cont";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";
import { handleAddEdit } from "./Components/ClauseComponent";
import ConsentAuthorityCard from "./ConsentAuthorityCard";
import ConsentRegulatoryCard from "./ConsentRegulatoryCard";
import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";

export default function ConsentClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
    editOptionSelected,
    childInEditId,
    onClickHighlight,
    sentenceData,
    toBeEdited,
    durationList,
    currencyList,
    contractData
  } = props;
  const data = React.useMemo(
    () => clauseData?.consent && clauseData.consent,
    [clauseData?.consent]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "consent");
  }, [fileId, hasData]);

  const [showAdd, setShowAdd] = useState(false);
  const [showAddRegulatory, setShowAddRegulatory] = useState(false);
  const [showAddAuthority, setShowAddAuthority] = useState<boolean>(false);
  const [authorityId, setAuthorityId] = useState<number>(-1);
  const [regulatoryId, setRegulatoryId] = useState<number>(-1);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  const updates = useMemo(
    () => updatedClauseData?.consent,
    [updatedClauseData]
  );

  const consent_clause = useMemo(() => {
    if (updates?.consent) {
      let sortData = updates.consent.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const consent_regulatory_bi = useMemo(() => {
    if (updates?.consent_regulatory_bi) {
      let sortData = updates.consent_regulatory_bi.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let consent = consent_clause?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        consent,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "consent", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, consent_clause, data, fileId]
  );

  const handleAuthority = useCallback(
    (consentData: any) => {
      const findAuthority = consent_regulatory_bi?.find(
        (authorityItem: any) =>
          consentData.para_id === authorityItem.para_id &&
          consentData.sentence_id === authorityItem.sentence_id
      );
      return findAuthority;
    },
    [consent_regulatory_bi]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="consent"
          onClick={() => {
            handleAddEdit(props, "Consent Clause", consent_clause, setShowAdd);
          }}
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="Consent Clause"
          savedInsight={
            !isNullOrUndefined(consent_clause) ? consent_clause : []
          }
          savedParentClauseDataPoint={consent_clause}
          editOptionSelected={(selected: boolean) => {
            editOptionSelected(selected);
          }}
          childInEditId={childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="consent"
        />
      )}
      <Scrollable maxHeight={200}>
        {consent_clause?.map((item: any, index: number) => (
          <React.Fragment key={index}>
            <ClauseCard
              key={index}
              onClick={() => {
                setActiveClause({ type: "consent", index });
                onClickHighlight(index, item, "consent");
              }}
              index={index}
              clauseItem={item}
              sentenceData={sentenceData}
              isActive={
                activeClause?.type === "consent" &&
                activeClause?.index === index
              }
              deleteClause={() => deleteClause(item)}
              para={item.sentence}
            />
            {showAddRegulatory && regulatoryId === index && (
              <EditFeature
                fileId={fileId}
                toBeEdited={toBeEdited}
                savedInsight={
                  !isNullOrUndefined(consent_clause) ? consent_clause : []
                }
                savedParentClauseDataPoint={consent_clause}
                editOptionSelected={(selected: boolean) => {
                  editOptionSelected(selected);
                }}
                childInEditId={childInEditId}
                durationList={durationList}
                currencyList={currencyList}
                contractData={contractData}
                onClose={() => {
                  setShowAddRegulatory(false);
                }}
                clauseDataByType={data}
                updatedClauseData={updates}
                postClauseDataByType={postClauseDataByType}
                updatedClauseDataByType={updates}
                parentClauseType={"consent"}
                clauseItem={item}
              />
            )}
            {item?.type ? (
              <ConsentRegulatoryCard
                heading="Regulatory"
                index={index}
                text={item?.type === "Regulatory" ? "Yes" : "No"}
                handleEdit={() => {
                  handleAddEdit(
                    props,
                    "Consent Regulatory",
                    consent_clause,
                    setShowAddRegulatory
                  );
                  setRegulatoryId(index);
                  setShowAddAuthority(false);
                }}
              />
            ) : (
              <ClauseHeader
                title="Regulatory"
                buttonText="regulatory"
                onClick={() => {
                  handleAddEdit(
                    props,
                    "Consent Regulatory",
                    consent_clause,
                    setShowAddRegulatory
                  );
                  setRegulatoryId(index);
                  setShowAddAuthority(false);
                }}
              />
            )}

            {showAddAuthority && authorityId === index && (
              <EditFeature
                fileId={fileId}
                toBeEdited={toBeEdited}
                savedInsight={
                  !isNullOrUndefined(consent_regulatory_bi)
                    ? consent_regulatory_bi
                    : []
                }
                savedParentClauseDataPoint={consent_regulatory_bi}
                editOptionSelected={(selected: boolean) => {
                  editOptionSelected(selected);
                }}
                childInEditId={childInEditId}
                durationList={durationList}
                currencyList={currencyList}
                contractData={contractData}
                onClose={() => {
                  setShowAddAuthority(false);
                }}
                clauseDataByType={data}
                updatedClauseData={updates}
                postClauseDataByType={postClauseDataByType}
                updatedClauseDataByType={updates}
                parentClauseType={"consent"}
                clauseItem={item}
              />
            )}

            {handleAuthority(item) ? (
              <ConsentAuthorityCard
                heading="Authority"
                index={index}
                authorityData={handleAuthority(item)}
                handleEdit={() => {
                  handleAddEdit(
                    props,
                    "Consent Authority",
                    consent_regulatory_bi,
                    setShowAddAuthority
                  );
                  setAuthorityId(index);
                  setShowAddRegulatory(false);
                }}
              />
            ) : (
              <ClauseHeader
                title="Authority"
                buttonText="authority"
                onClick={() => {
                  handleAddEdit(
                    props,
                    "Consent Authority",
                    consent_regulatory_bi,
                    setShowAddAuthority
                  );
                  setAuthorityId(index);
                  setShowAddRegulatory(false);
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Scrollable>
    </>
  );
}
