import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function EffectiveClause(props: ClauseComponentInterface) {
  const { getClauseDataByType, clauseData, fileId, hasData } = props;

  const data = React.useMemo(
    () => clauseData?.effectiveDate && clauseData.effectiveDate,
    [clauseData?.effectiveDate]
  );

  useEffect(() => {
    hasData && getClauseDataByType(props.fileId, "effective_date");
  }, [fileId, hasData]);

  const [showAdd, setShowAdd] = useState(false);
  const [showAddNotify, setShowAddNotify] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="effective date"
          onClick={() =>
            handleAddEdit(
              props,
              "insurance",
              data?.raw_content?.effective_date,
              setShowAdd
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={props.toBeEdited}
          savedInsight={
            !isNullOrUndefined(data?.raw_content?.effective_date)
              ? data?.raw_content?.effective_date
              : []
          }
          savedParentClauseDataPoint={data?.raw_content?.effective_date}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAdd(false);
          }}
          // onSubmit={(payload: any) => onUpdate(payload)}
        />
      )}
      {!showAddNotify ? (
        <ClauseHeader
          title="Notify"
          buttonText="notify"
          onClick={() =>
            handleAddEdit(
              props,
              "Effective Dates Notify",
              data?.raw_content?.effective_date,
              setShowAddNotify
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={props.toBeEdited}
          savedInsight={
            !isNullOrUndefined(data?.raw_content?.effective_date)
              ? data?.raw_content?.effective_date
              : []
          }
          savedParentClauseDataPoint={data?.raw_content?.effective_date}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAddNotify(false);
          }}
          //onSubmit={(payload: any) => onUpdate(payload)}
        />
      )}
      {data?.raw_content?.effective_date?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "effective_date", index });
            props.onClickHighlight(index, item, "effective date");
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === "effective_date" &&
            activeClause?.index === index
          }
          deleteClause={() => {}}
        />
      ))}
    </>
  );
}
