import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  createDraft,
  draftDeviation,
  editDraftData,
  fetchKDPTagDeviations,
  fetchMergeTagDeviations,
  fetchReferenceDocument,
  getDraftApprovals,
  getDraftById,
  getS3PresignedUrl,
  upload_file_in_s3_bucket,
} from "../../Services/Draft";
import { convertPDF, downloadFile } from "./Helper";
import { version0Tabs, version1Tabs } from "./StaticData";

import CommitOutlinedIcon from "@mui/icons-material/CommitOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { isRiverusAdmin } from "../../DataDictionary/DDUtils";
import { a11yProps } from "../../RiverusUI/Components/CustomTabPanel";
import CommitDraftIcon from "../../RiverusUI/Components/Icons/CommitDraftIcon";
import DraftHeader from "../../RiverusUI/Header/DraftHeader";
import DropdownButton from "../../RiverusUI/Header/DropdownButton";
import { riPrimary } from "../../RiverusUI/Theme/colors";
import { fetchExtractedData } from "../../Services/Insights";
import { getBlobDocument } from "../../Templates/Component/DocGenerate";
import { draftStatus } from "../State/DraftState";

const ClauseLibrary = lazy(
  () => import("../../Approvals/Component/ClauseLibrary/ClauseLibrary")
);
const ApprovalTab = lazy(() => import("./Approval/Approval"));
const ChecklistTab = lazy(() => import("./ChecklistTab/ChecklistTab"));
const ClauseLibraryTab = lazy(
  () => import("./ClauseLibraryTab/ClauseLibraryTab")
);
const CompleteDraft = lazy(() => import("./CompleteDraft/CompleteDraft"));
const DeviationTab = lazy(() => import("./DeviationTab/DeviationTab"));
const InsightsTab = lazy(() => import("./InsightsTab/InsightsTab"));
const LoadingInsights = lazy(() => import("./InsightsTab/LoadingInsights"));
const SignatureTab = lazy(() => import("./SignatureTab/SignatureTab"));
const PdfView = lazy(() => import("./PdfView"));
const DraftViewAlert = lazy(() => import("./DraftViewAlert"));
const GenerateDraftDialog = lazy(
  () => import("./DeviationTab/GenerateDraftDialog")
);
const UploadDraftDialog = lazy(
  () => import("./CompleteDraft/UploadDraftDialog")
);
const ReusableConfirmationModal = lazy(
  () => import("../../RiverusUI/Components/ReusableConfirmationModal")
);
const DrawerComponent = lazy(
  () => import("../../RiverusUI/Components/DrawerComponent")
);

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: "20px",
  paddingX: "15px",
};

const disabledButton = {
  color: "rgba(0, 0, 0, 0.26)",
  boxShadow: "none",
  background: "rgba(0, 0, 0, 0.12)",
  "&:hover": {
    color: "rgba(0, 0, 0, 0.26)",
    boxShadow: "none",
    background: "rgba(0, 0, 0, 0.12)",
  },
};

const EditDocumentSaveMenu: any[] = [
  {
    value: "save_draft",
    label: "Save Draft",
    startIcon: <SaveOutlinedIcon />,
  },
  {
    value: "save_and_commit",
    label: "Save and commit",
    startIcon: <CommitOutlinedIcon />,
  },
];

const SparkMD5 = require("spark-md5");

const ViewDraft: React.FC = () => {
  const { id, version } = useParams<{
    id: string;
    version: string;
  }>();
  const history = useHistory();

  const { search } = useLocation();
  const queryParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const queryParamsValue = queryParams.get("isDraftUpdated");

  const isDraftUpdated = React.useMemo(() => {
    if (queryParamsValue) {
      return queryParamsValue === "true" ? true : false;
    } else {
      return true;
    }
  }, [queryParamsValue]);

  const [addedSignatureFields, setAddedSignatureFields] =
    useState<boolean>(false);
  const [dropPoint, setDropPoint] = useState<any>();
  const [tabs, setTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<string>("complete_draft");
  const [openUploadDraftDialog, setOpenUploadDraftDialog] =
    useState<boolean>(false);
  const [documentLoading, setDocumentLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [openClauseLibrary, setOpenClauseLibrary] = useState<boolean>(false);

  const [uploadedFile, setUploadedFile] = useState<any>();
  const [instance, setInstance] = useState<any>();
  const [uploadedFileData, setUploadedFileData] = useState<any>();
  const [completeDraftFormData, setCompleteDraftFormData] = useState<any>([]);
  const [isFieldUpdated, setFieldUpdated] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [status, setStatus] = useState<string>();
  const [versionType, setVersionType] = useState<string>("major");
  const [isOpenMergeTagDialog, setIsOpenMergeTagDialog] =
    useState<boolean>(false);
  const [falseMergeTags, setFalseMergeTags] = useState<any[]>([]);
  const [allMergeTags, setAllMergeTags] = useState<any[]>([]);
  const [openCommitDraftModal, setOpenCommitDraftModal] =
    useState<boolean>(false);
  const [confirmationDescription, setConfirmationDescription] =
    useState<string>("");
  const [confirmationText, setConfirmationText] = useState<string>("");
  const [deviationType, setDeviationType] = useState<string>("");
  const [redirect, setRedirect] = useState<string>("");
  const [saveDraftLoading, setSaveDraftLoading] = useState<boolean>(false);
  const [insightsStatus, setInsightsStatus] = useState<any>({
    status: "Done",
    progress: 100,
  });
  const [dropDownList, setDropDownList] = useState<any[]>([]);
  const [viewDocDetails, setViewDocDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [moveContract, setMoveContract] = useState<boolean>(false);
  const [showDraftLockedAlert, setShowDraftLockedAlert] =
    useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const user_id = localStorage.getItem("user_id") || "";

  useEffect(() => {
    let ws = new WebSocket(
      process.env.REACT_APP_RIVERUS_CLM_WS_API +
        `ws/kdp_status/?token=${user_id}`
    );
    ws.onclose = () => {
      ws = new WebSocket(
        process.env.REACT_APP_RIVERUS_CLM_WS_API +
          `ws/kdp_status/?token=${user_id}`
      );
    };
    ws.onmessage = (event) => {
      let insights_status: any = JSON.parse(event.data)["message"];
      insights_status.map((data: any) => {
        if (data.id === id && data.type === "stylus") {
          setInsightsStatus({
            status: data.kdp_status,
            progress: parseInt(data.percentage),
          });
        }
      });
    };
  }, [id, user_id]);

  const { data: draftData } = useQuery({
    queryKey: ["get_draft_by_id", id],
    queryFn: async () => {
      const response = await getDraftById(id);
      return response as any;
    },
    enabled: !!id,
  });

  const canCommit = useMemo(() => {
    const getDraftOwner = draftData?.owners?.find(
      (item: any) => item?.id === user_id
    );
    if (getDraftOwner) {
      return true;
    } else {
      return false;
    }
  }, [draftData?.owners, user_id]);

  const { data: referenceDocData } = useQuery({
    queryKey: ["reference_doc_data", draftData?.draftID],
    queryFn: async () => {
      const response = await fetchReferenceDocument(draftData?.draftID);
      return response?.results;
    },
    enabled: !!draftData?.draftID,
  });

  useEffect(() => {
    if (draftData?.is_locked) {
      setShowDraftLockedAlert(true);
    }
  }, [draftData?.is_locked]);

  useEffect(() => {
    if (referenceDocData?.length > 0) {
      let referenceFileList: any[] = [];
      referenceDocData?.map((referenceFileData: any) => {
        referenceFileList.push({
          access_url: referenceFileData?.link,
          dataObject: referenceFileData,
        });
      });
      setDropDownList([
        {
          access_url: draftData?.access_url,
          dataObject: draftData,
        },
        ...referenceFileList,
      ]);
    } else if (draftData) {
      setDropDownList([
        {
          access_url: draftData?.access_url,
          dataObject: draftData,
        },
      ]);
      setViewDocDetails({
        access_url: draftData?.access_url,
        dataObject: draftData,
      });
    }
  }, [referenceDocData, draftData]);

  const { data: approvalData, isLoading: approvalLoading } = useQuery({
    queryKey: ["draft_approvals", draftData?.id, version],
    queryFn: async () => {
      let type =
        version === "0" ? "requisition_approvers" : "deviation_approvers";
      const response = await getDraftApprovals(draftData?.id, type);
      return response?.results;
    },
    enabled: !!draftData?.id,
  });

  const { data: extractedData } = useQuery({
    queryKey: ["clause_term", draftData?.id],
    queryFn: async () => {
      const response = await fetchExtractedData(draftData?.id);
      return response;
    },
    enabled: !!draftData?.id,
  });

  const disableGenerateButton = useMemo(() => {
    let disabled = false;
    if (approvalData?.length) {
      approvalData.map((approver: any) => {
        let approvedBy = approver.approvers.filter(
          (data: any) => data.status === "approved"
        );
        if (approver.approval_sequence === "any") {
          if (approvedBy.length) {
            disabled = false;
          } else {
            disabled = true;
          }
        } else {
          if (approvedBy.length === approver.approvers.length) {
            disabled = false;
          } else {
            disabled = true;
          }
        }
        return disabled;
      });
    }
    return disabled;
  }, [approvalData]);

  const editModeDisabled = useMemo(() => {
    if (
      viewDocDetails?.access_url?.includes("policy_note") ||
      viewDocDetails?.access_url?.includes("draft_notes") ||
      viewDocDetails?.access_url?.includes("note_for_approval")
    ) {
      return true;
    }
    return false;
  }, [viewDocDetails]);

  const collaboratorDisabled = useMemo(() => {
    if (
      draftData?.status?.toLowerCase().includes("signature") ||
      draftData?.status
        ?.toLowerCase()
        .includes("contract executed successfully") ||
      editModeDisabled
    ) {
      return true;
    }
    return false;
  }, [draftData?.status, editModeDisabled]);

  useEffect(() => {
    if (parseInt(version) === 0) {
      if (draftData?.createFrom === "template") {
        setTabs([
          { label: "Complete Draft", value: "complete_draft", notIn: 1 },
          ...version0Tabs,
        ]);
        setActiveTab("complete_draft");
      } else {
        setTabs(version0Tabs);
        setActiveTab("checklist");
      }
    } else if (
      (draftData?.status?.toLowerCase().includes("internal draft created") ||
        draftData?.status
          .toLowerCase()
          .includes("counter party draft created")) &&
      isDraftUpdated
    ) {
      setTabs([
        {
          label: "Issues",
          value: "issues",
        },
        {
          label: "Clause Library",
          value: "clause_library",
        },
        {
          label: "Insights",
          value: "insights",
        },
        ...version1Tabs,
      ]);
      setActiveTab("insights");
    } else if (
      (draftData?.status?.toLowerCase().includes("signature pending") ||
        draftData?.status
          .toLowerCase()
          .includes("contract executed successfully") ||
        draftData?.status.toLowerCase().includes("committed for signature")) &&
      isDraftUpdated
    ) {
      setTabs([
        {
          label: "Signatures",
          value: "signature",
        },
        ...version1Tabs,
      ]);
      setActiveTab("signature");
    } else {
      setTabs(version1Tabs);
      setActiveTab("insights");
    }
  }, [version, draftData, isDraftUpdated]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const fileLink = useMemo(() => draftData?.access_url, [draftData]);

  const handleCloseUploadDialog = () => {
    setOpenUploadDraftDialog(false);
  };

  const handleCloseCommitDraftModal = () => {
    setOpenCommitDraftModal(false);
    setConfirmationDescription("");
    setConfirmationText("");
  };

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      let reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      let file = uploadedFile?.[0];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [uploadedFile]
  );

  const { mutate: updateDraftData, isPending: updatingDraft } = useMutation({
    mutationFn: editDraftData,
    onSuccess: (response: any) => {
      queryClient.invalidateQueries({
        queryKey: ["get_draft_by_id"],
      });
      enqueueSnackbar("Successfully updated draft data!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      if (response?.data?.status === draftStatus?.DRAFT_UPLOAD_PENDING) {
        enqueueSnackbar(
          "Thank you. Please upload new versions from version drawer",
          {
            variant: "info",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        );
        history.push("/draftingreview");
      } else {
        redirect === "/draftingreview"
          ? history.push(redirect)
          : history.push(
              `/draft/${response.data.version}/${response?.data?.id}`
            );
        instance?.UI.loadDocument(response.data.access_url);
        setLoaded(true);
        setRedirect("");
      }
    },
    onError: () => {
      enqueueSnackbar("Failed to update draft data!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const { mutate: createDraftMutation, isPending: creatingDraft } = useMutation(
    {
      mutationKey: ["create_draft"],
      mutationFn: async (payload: any) => createDraft(payload),
      onSuccess: (response: any) => {
        queryClient.invalidateQueries({
          queryKey: ["get_draft_by_id"],
        });
        enqueueSnackbar("Draft edited successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        if (!redirect) {
          history.push(
            `/draft/${response?.data?.version}/${response?.data?.id}`
          );
          if (canEdit) {
            window.location.reload();
          }
          instance?.UI.loadDocument(response?.data?.access_url);
        }
        setLoaded(true);
        if (openCommitDraftModal) {
          handleCloseCommitDraftModal();
        }
      },
      onError: () => {
        enqueueSnackbar("Failed to edit Draft!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const { mutate: uploadDocInS3Bucket, isPending: uploadingDoc } = useMutation({
    mutationKey: [
      "upload_document_in_S3_bucket",
      // uploadedFileData,
      versionType,
    ],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: () => {
      const draftPayload = {
        version: draftData?.version + 1,
        link: uploadedFileData?.presigned_url?.file_path,
        version_type: versionType,
        draftID: draftData?.draftID,
        collaborators: draftData?.collaborators.map((data: any) => data.id),
        approvers: draftData?.approvers.map((data: any) => data.id),
        owners: draftData?.owners.map((data: any) => data.id),
        contractType: draftData?.contractType.id,
        contractName: draftData?.contractName,
        groups: draftData?.groups,
        createFrom: draftData?.createFrom,
        status: canEdit ? draftData?.status : status,
        earlier_draft_link: draftData?.earlier_draft_link,
        executed_contract_link: draftData?.executed_contract_link,
        support_document_link: draftData?.support_document_link,
      };
      createDraftMutation(draftPayload);
      setLoaded(false);
      handleCloseUploadDialog();
    },
    onError: () => {
      enqueueSnackbar("Failed to upload the document!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const { mutate: uploadDocument, isPending: loadingUploadDoc } = useMutation({
    mutationKey: ["upload_draft_document", uploadedFile],
    mutationFn: getS3PresignedUrl,
    onSuccess: (response) => {
      if (response?.data) {
        setIsLoading(false);
        setUploadedFileData(response?.data);
        let file = uploadedFile;
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };

          uploadDocInS3Bucket({
            presignedPostData: response?.data?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: (error: any) => {
      if (error?.response?.status === 403) {
        window.location.reload();
      } else {
        enqueueSnackbar("Failed to upload document!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }
    },
  });

  const generateDraft = useCallback(() => {
    setIsLoading(true);
    if (isFieldUpdated) {
      document.getElementById("save_draft_button")?.click();
    }

    let flatArr = completeDraftFormData.flatMap(
      (section: any) => section.field_data
    );
    let keyValuePair: any = flatArr.reduce((acc: any, curr: any) => {
      let key = curr.field_name.replaceAll(/{|}/g, "");
      if (curr.data_type?.toLowerCase() === "duration") {
        acc = { ...acc, [key]: curr.field_value + " " + curr.fieldLength };
      } else if (
        curr.data_type.toLowerCase() === "bulleted" ||
        curr.data_type.toLowerCase() === "numbered"
      ) {
        acc = {
          ...acc,
          [key]: JSON.parse(curr.field_value).filter(
            (data: any) => data["list"] !== ""
          ),
        };
      } else acc = { ...acc, [key]: curr.field_value };
      return acc;
    }, {});

    for (let key in keyValuePair) {
      if (keyValuePair[key] === "") {
        keyValuePair[key] = "{" + key + "}";
      }
    }

    getBlobDocument(keyValuePair, draftData.access_url, (response: any) => {
      setIsLoading(false);
      const file = response;
      file["name"] = draftData.contractName + ".docx";
      file["id"] = draftData.id;
      let reader = new FileReader();
      if (file) {
        setUploadedFile(file);
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          let file_ = {
            file_hash: hexHash,
            file_size: file.size,
            type: file.type,
            template_type: file["template_type"],
            file_type: "Contract_Draft",
            creation_type: isRiverusAdmin() ? "system" : "custom",
            file_name: file.name,
          };
          setStatus(draftStatus.INTERNAL_DRAFT_CREATED);
          uploadDocument(file_);
        };
      }
    });
  }, [completeDraftFormData, draftData, uploadDocument, isFieldUpdated]);

  //  signatureTab flow

  const handleDownload = React.useCallback(
    async (value: string) => {
      const { Core } = instance;
      if (value === "pdf") {
        const { Core } = instance;
        if (fileLink) {
          let file = await convertPDF(Core, fileLink);
          downloadFile(file, draftData?.contractName, "pdf");
        }
      } else {
        const doc = Core.documentViewer.getDocument();
        const annotationManager = Core.documentViewer.getAnnotationManager();
        const xfdfString = await annotationManager.exportAnnotations();
        const data = await doc.getFileData({
          // saves the document with annotations in it
          xfdfString,
        });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: "application/docx" });
        downloadFile(blob, draftData?.contractName, "docx");
      }
    },
    [draftData?.contractName, fileLink, instance]
  );

  const convertDocToPdf = async () => {
    setIsLoading(true);
    if (!instance) return;
    const { Core } = instance;
    if (fileLink) {
      let file = await convertPDF(Core, fileLink);
      let reader = new FileReader();
      if (file) {
        setUploadedFile(file);
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          let file_ = {
            file_hash: hexHash,
            file_name: draftData.contractName + ".pdf",
            file_size: file?.size,
            file_type: "committed_draft",
            creation_type: isRiverusAdmin() ? "system" : "custom",
            type: file?.type,
          };

          setStatus(draftStatus.COMMITTED_FOR_SIGNATURE);
          uploadDocument(file_);
        };
      }
    }
  };

  const handleEditingModeSaveDraft = async (value: any) => {
    const { documentViewer } = instance.Core;

    const doc = documentViewer.getDocument();
    const annotationManager = documentViewer.getAnnotationManager();

    const xfdfString = await annotationManager.exportAnnotations();
    const data = await doc.getFileData({
      // saves the document with annotations in it
      xfdfString,
    });
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/docx" });
    const file = blob;

    let reader = new FileReader();
    if (file) {
      setUploadedFile(file);

      reader.readAsDataURL(file);
      reader.onload = async () => {
        const hexHash = SparkMD5.hash(reader.result);
        let file_ = {
          file_hash: hexHash,
          file_name: draftData.contractName + ".docx",
          file_size: file.size,
          file_type:
            value === "save_draft" ? "committed_draft" : "Contract_Draft",
          creation_type: isRiverusAdmin() ? "system" : "custom",
          type: file.type,
        };
        setVersionType(value === "save_draft" ? "minor" : "major");
        uploadDocument(file_);
      };
    }
  };

  const handleDialogClose = () => {
    setIsOpenMergeTagDialog(false);
  };

  const { mutate: generateMergeTagIssue, isPending: generatingMergeTagIssue } =
    useMutation({
      mutationFn: draftDeviation,
      onSuccess: () => {
        if (isOpenMergeTagDialog) {
          handleDialogClose();
        }
        generateDraft();
      },
    });

  const handleGenerateDraft = () => {
    generateMergeTagIssue(allMergeTags);
  };

  const { mutate: mergeTagDeviations, isPending: isLoadingTagDeviation } =
    useMutation({
      mutationKey: ["merge_tag_deviation", id],
      mutationFn: fetchMergeTagDeviations,
      onSuccess: (response: any) => {
        setIsLoading(false);
        const allMergeTagsArray = response?.results;
        setAllMergeTags(allMergeTagsArray);
        const filterData = allMergeTagsArray?.filter(
          (item: any) => item?.error === false
        );
        setFalseMergeTags(filterData);
        if (filterData?.length > 0) {
          setIsOpenMergeTagDialog(true);
        } else if (allMergeTagsArray?.length > 0 && filterData?.length === 0) {
          generateMergeTagIssue(allMergeTagsArray);
        } else {
          generateDraft();
        }
      },
      onError: () => {
        setIsLoading(false);
      },
    });

  const handleGenerateDraftButton = (id: string) => {
    if (disableGenerateButton) {
      enqueueSnackbar("Please check the approval tab for pending approvals!", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } else {
      setIsLoading(true);
      mergeTagDeviations(id);
    }
  };

  const draftId =
    draftData?.createFrom === "template" && draftData?.version === 1
      ? draftData?.draftID
      : draftData?.id;

  useEffect(() => {
    if (draftData?.createFrom === "template" && draftData?.version === 1) {
      setDeviationType("merge_tag");
    } else {
      setDeviationType("kdp");
    }
  }, [draftData]);

  const { data: deviationsList, isLoading: loadingDeviations } = useQuery({
    queryKey: ["fetch_Kdp_deviations", draftId, deviationType],
    queryFn: async () => {
      const response = await fetchKDPTagDeviations(draftId, deviationType);
      return response?.results;
    },
    enabled: !!draftId && !!deviationType,
  });

  const { data: customDeviationList } = useQuery({
    queryKey: ["custom_deviations", draftId],
    queryFn: async () => {
      const response = await fetchKDPTagDeviations(draftId, "custom");
      return response?.results;
    },
    enabled: !!draftId,
  });

  const handleOpenCommitDraftModal = () => {
    queryClient.invalidateQueries({
      queryKey: ["get_draft_by_id"],
    });
    setDocumentLoading(true);
    const filteredDeviationsList = deviationsList?.filter((listItem: any) => {
      if (
        (listItem?.approval_status === "approval_pending" ||
          listItem?.approval_status === null) &&
        listItem?.status === "deviant"
      ) {
        if (
          listItem?.draft_datapoint ||
          listItem?.kdp_datapoint?.length > 0 ||
          listItem?.manual_datapoint?.length > 0
        ) {
          return listItem;
        } else if (
          !listItem?.draft_datapoint &&
          (!listItem?.manual_datapoint ||
            listItem?.manual_datapoint.length === 0) &&
          (!listItem?.kdp_datapoint || listItem?.kdp_datapoint.length === 0)
        ) {
          return listItem;
        }
      }
    });

    const filterCustomApprovalPending = customDeviationList?.filter(
      (listItem: any) => {
        if (
          listItem?.approval_status === "approval_pending" ||
          listItem?.approval_status === null
        ) {
          if (listItem?.custom_datapoint?.length > 0) {
            return listItem;
          }
        }
      }
    );

    if (
      filteredDeviationsList?.length > 0 ||
      filterCustomApprovalPending?.length > 0
    ) {
      setConfirmationDescription(
        "Some Issues are still not resolved or require approval. Please resolve all Issues before you can Commit draft your draft."
      );
      setConfirmationText("Resolve Issues");
    } else {
      setConfirmationDescription(
        "Are you sure? You will not be able to make any changes to the draft and it will be shared with the signatories for signing."
      );
      setConfirmationText("Commit draft");
    }
    setOpenCommitDraftModal(true);
  };

  const handleConfirmActionBtn = () => {
    if (confirmationText === "Commit draft") {
      convertDocToPdf();
      handleCloseCommitDraftModal();
    } else if (confirmationText === "Resolve Issues") {
      if (
        draftData?.status.toLowerCase().includes("internal draft created") ||
        draftData?.status.toLowerCase().includes("counter party draft created")
      ) {
        setActiveTab("issues");
        handleCloseCommitDraftModal();
      }
    }
  };

  const switchToDocxEdit = useCallback(() => {
    if (draftData?.is_locked) {
      setShowDraftLockedAlert(true);
    } else {
      setCanEdit(true);
    }
  }, [draftData?.is_locked]);

  const renderDeviation = React.useCallback(() => {
    if (insightsStatus.progress < 100) {
      return (
        <Stack
          spacing={1}
          sx={{
            height: "90%",
            overflowY: "auto",
            backgroundColor: "riTertiary.50",
            borderRadius: "24px",
            padding: "8px",
            mt: "8px",
          }}
        >
          <LoadingInsights
            progress={insightsStatus.progress}
            label="Automatically computing issues with the draft. They will be ready shortly."
          />
        </Stack>
      );
    } else {
      return (
        <DeviationTab
          draftData={draftData}
          instance={instance}
          setActiveTab={setActiveTab}
          handleOpenClauseLibraryDrawer={() => setOpenClauseLibrary(true)}
        />
      );
    }
  }, [draftData, insightsStatus, instance]);

  const handleMoveForward = useCallback(() => {
    if (disableGenerateButton) {
      enqueueSnackbar("Please check the approval tab for pending approvals!", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: ["get_draft_by_id"],
      });
      setTimeout(() => {
        if (draftData?.createFrom === "counter_party") {
          setMoveContract(true);
        } else {
          setIsLoading(false);
          const payload = {
            id: draftData?.id,
            body: {
              status: draftStatus?.DRAFT_UPLOAD_PENDING,
            },
          };
          updateDraftData(payload);
        }
      }, 1000);
    }
  }, [
    disableGenerateButton,
    draftData,
    enqueueSnackbar,
    queryClient,
    updateDraftData,
  ]);

  useEffect(() => {
    if (moveContract && !documentLoading) {
      setIsLoading(true);
      setMoveContract(false);
      let keyValuePair = {
        nameofthedraft: draftData?.contractName,
        contracttypefield: draftData?.contractType?.name,
        createcontractfromfield: "createFrom",
        selecttemplatefield: draftData?.createFrom,
        groups: draftData?.groups,
        collaborators:
          draftData?.collaborators && draftData?.collaborators?.length
            ? draftData?.collaborators?.map(
                (value: any) => value.first_name + " " + value.last_name
              )
            : [],
        signatories: draftData?.signatories?.map(
          (value: any) => value.first_name + " " + value.last_name
        ),
        approvers: draftData?.approvalTypes?.length
          ? draftData?.approvalTypes[0]?.approvers.map(
              (value: any) => value.first_name + " " + value.last_name
            )
          : [],
      };

      getBlobDocument(keyValuePair, draftData?.access_url, (response: any) => {
        const file = response;
        file["name"] = draftData.contractName + ".docx";
        file["id"] = draftData.id;
        let reader = new FileReader();
        if (file) {
          setUploadedFile(file);
          reader.readAsDataURL(file);
          reader.onload = async () => {
            const hexHash = SparkMD5.hash(reader.result);
            let file_ = {
              file_hash: hexHash,
              file_size: file.size,
              type: file.type,
              template_type: file["template_type"],
              file_type: "Contract_Draft",
              creation_type: isRiverusAdmin() ? "system" : "custom",
              file_name: file.name,
            };
            setStatus(draftStatus?.COUNTER_PARTY_DRAFT_CREATED);
            uploadDocument(file_);
          };
        }
      });
    }
  }, [draftData, moveContract, uploadDocument, documentLoading]);

  return (
    <React.Fragment>
      <Box>
        <DraftHeader
          version={parseInt(version)}
          name={draftData?.contractName}
          handleDownload={handleDownload}
          renderAction={() =>
            version === "0" ? (
              draftData?.createFrom === "template" ? (
                <Stack direction="row">
                  <LoadingButton
                    type="submit"
                    id="save_draft_button"
                    form="complete_draft_form"
                    loading={saveDraftLoading || updatingDraft}
                    disabled={!isDraftUpdated}
                  >
                    Save Draft
                  </LoadingButton>
                  {isDraftUpdated && (
                    <LoadingButton
                      variant="contained"
                      onClick={() => handleGenerateDraftButton(id)}
                      loading={
                        isLoadingTagDeviation ||
                        generatingMergeTagIssue ||
                        loadingUploadDoc ||
                        uploadingDoc ||
                        isLoading ||
                        updatingDraft
                      }
                      sx={disableGenerateButton ? disabledButton : {}}
                    >
                      Generate Draft
                    </LoadingButton>
                  )}
                </Stack>
              ) : (
                <LoadingButton
                  variant="contained"
                  sx={disableGenerateButton ? disabledButton : {}}
                  onClick={handleMoveForward}
                  loading={
                    creatingDraft ||
                    loadingUploadDoc ||
                    uploadingDoc ||
                    isLoading ||
                    updatingDraft ||
                    documentLoading
                  }
                >
                  Move forward
                </LoadingButton>
              )
            ) : canEdit ? (
              <DropdownButton
                options={EditDocumentSaveMenu}
                startIcon={<SaveOutlinedIcon />}
                buttonName="Save draft"
                handleDropdownChange={handleEditingModeSaveDraft}
              />
            ) : (
              (draftData?.status
                .toLowerCase()
                .includes("internal draft created") ||
                draftData?.status
                  .toLowerCase()
                  .includes("counter party draft created")) && (
                <LoadingButton
                  variant="contained"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                  loading={
                    loadingUploadDoc ||
                    uploadingDoc ||
                    creatingDraft ||
                    isLoading ||
                    documentLoading
                  }
                  disabled={!isDraftUpdated || !canCommit}
                  onClick={handleOpenCommitDraftModal}
                >
                  <CommitDraftIcon sx={{ mt: "4px" }} /> Commit Draft
                </LoadingButton>
              )
            )
          }
          canDownload={draftData?.version !== 0}
          collaborating={draftData?.version !== 0}
          collaboratorDisabled={collaboratorDisabled}
          goBackUrl="/draftingreview"
          setCanEdit={setCanEdit}
          canEdit={canEdit}
          isLocked={draftData?.is_locked}
          showLockedModal={() => setShowDraftLockedAlert(true)}
        />
        <Grid container sx={{ mt: 2 }}>
          <Grid item sm={canEdit ? 12 : 6}>
            {loaded && (
              <PdfView
                key={fileLink}
                draftData={viewDocDetails?.dataObject}
                fileLink={viewDocDetails?.access_url}
                instance={instance}
                setInstance={setInstance}
                dropPoint={dropPoint}
                setDropPoint={setDropPoint}
                addedSignatureFields={addedSignatureFields}
                setAddedSignatureFields={setAddedSignatureFields}
                canEdit={canEdit}
                handleOpenClauseLibraryDrawer={() => setOpenClauseLibrary(true)}
                referenceDocData={dropDownList}
                setViewDocDetails={setViewDocDetails}
                setDocumentLoading={setDocumentLoading}
              />
            )}
          </Grid>
          {!canEdit && (
            <Grid item sm={6} sx={{ paddingLeft: 3 }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Draft tabs"
                variant="scrollable"
                scrollButtons={false}
                sx={innerTabStyle}
              >
                {tabs.map((tab: any, index: number) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    value={tab.value}
                    {...a11yProps(tab.value)}
                  />
                ))}
              </Tabs>
              {activeTab === "issues" ? (
                renderDeviation()
              ) : (
                <Stack
                  spacing={1}
                  sx={{
                    height: "90%",
                    overflowY: "auto",
                    backgroundColor: "riTertiary.50",
                    borderRadius: "24px",
                    padding: "8px",
                    mt: "8px",
                  }}
                >
                  {activeTab === "complete_draft" && (
                    <CompleteDraft
                      draftData={draftData}
                      setCompleteDraftFormData={setCompleteDraftFormData}
                      instance={instance}
                      setFieldUpdated={setFieldUpdated}
                      updateDraftData={updateDraftData}
                      setSaveDraftLoading={setSaveDraftLoading}
                    />
                  )}
                  {activeTab === "checklist" && (
                    <ChecklistTab draftData={draftData} />
                  )}
                  {activeTab === "clause_library" && <ClauseLibraryTab />}
                  {activeTab === "approvals" && (
                    <ApprovalTab
                      approvalData={approvalData}
                      draftData={draftData}
                      version={version}
                      approvalLoading={approvalLoading}
                    />
                  )}
                  {activeTab === "insights" &&
                    (insightsStatus?.progress < 100 ? (
                      <LoadingInsights progress={insightsStatus.progress} />
                    ) : (
                      <InsightsTab
                        draftData={draftData}
                        instance={instance}
                        extractedData={extractedData}
                      />
                    ))}
                  {activeTab === "signature" && (
                    <SignatureTab
                      draftData={draftData}
                      instance={instance}
                      dropPoint={dropPoint}
                      addedSignatureFields={addedSignatureFields}
                      setAddedSignatureFields={setAddedSignatureFields}
                      updateDraftData={updateDraftData}
                      setRedirect={setRedirect}
                      createDraft={createDraftMutation}
                      editModeDisabled={editModeDisabled}
                    />
                  )}
                </Stack>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
      {openUploadDraftDialog && (
        <UploadDraftDialog
          files={uploadedFile}
          open={openUploadDraftDialog}
          onClose={handleCloseUploadDialog}
          setFiles={setUploadedFile}
          loadingUploadDoc={loadingUploadDoc}
          uploadDocument={uploadDocument}
          draftID={id}
        />
      )}
      {isOpenMergeTagDialog && (
        <GenerateDraftDialog
          open={isOpenMergeTagDialog}
          onClose={handleDialogClose}
          mergeTagData={falseMergeTags}
          handleGenerateDraft={handleGenerateDraft}
        />
      )}
      {openCommitDraftModal && (
        <ReusableConfirmationModal
          open={openCommitDraftModal}
          onClose={handleCloseCommitDraftModal}
          onConfirm={handleConfirmActionBtn}
          title="Commit Draft"
          confirmBtnText={confirmationText}
          cancelBtnText="Cancel"
          children={<Typography>{confirmationDescription}</Typography>}
          buttonLoading={documentLoading}
        />
      )}
      {openClauseLibrary && (
        <DrawerComponent
          open={openClauseLibrary}
          onClose={() => setOpenClauseLibrary(false)}
          drawerHeader="Replace with clause library "
        >
          <ClauseLibrary
            isClauseLibraryTab={true}
            openDocxEditor={switchToDocxEdit}
            isDocxOpen={canEdit}
          />
        </DrawerComponent>
      )}
      {showDraftLockedAlert && (
        <DraftViewAlert
          isOpen={showDraftLockedAlert}
          onClose={() => setShowDraftLockedAlert(false)}
          data={draftData?.locked_by}
        />
      )}
    </React.Fragment>
  );
};

export default ViewDraft;
