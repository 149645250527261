import React, { useEffect, useState } from "react";
import {
  LinkPhraseRequest,
  SentencesData,
  editedPhrases,
  phraseInfo,
  tableInfo,
} from "../../../../State/documentState";
import { ClauseType } from "../../../../Constants/ClauseType";
import { useForm } from "react-hook-form";
import { Box, Button, Stack, Typography } from "@mui/material";
import RadioButtonGroup from "../../../../../RiverusUI/Components/RadioButtonGroup";
import ControlledTextField from "../../../../../RiverusUI/Components/ControlledTextField";
import {
  deletePhraseFromPhraseArray,
  filterPhrasesFromPhrasesArray,
  filterTableCellsFromPhraseArray,
  isTableCell,
  phraseBiMap,
} from "../../../Utils/docUtils";
import {
  deleteDataFromObligation,
  getClauseDataFormat,
  getClauseObjects,
} from "../../../ClauseComponent/utils/ClauseTypeUtils";
import * as changesets from "json-diff-ts";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  dataPointName: string;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editOptionSelected: (editOptionSelected: boolean) => void;
  savedInsight: any;
  editPhrasesRequest: (newPhraseRequest: LinkPhraseRequest) => void;
  savedHighlightedPhrases: phraseInfo[] | null;
  saveHighlightedPhrases: (
    savedHighlightedPhrases: phraseInfo[] | null
  ) => void;
  phraseEditOptionSelected: boolean;
  savePhraseEditOption: (phraseEditOptionSelected: boolean) => void;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  saveDeletePhrase: (
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ) => void;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  saveAddPhrase: (
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  fileId: string;
  clauseType: string;
  sentenceData: SentencesData;
  clauseDataByType: any;
  onClose: VoidFunction;
  updatedClauseDataByType: any;
  clauseData: any;
}

const EditAddDefinition: React.FC<Props> = (props) => {
  const [isAddingPhrase, setIsAddingPhrase] = useState<boolean>(false);
  const [isEditingState, setIsEditingState] = useState<boolean>(false);
  const [currentEditingPhrase, setCurrentEditingPhrase] =
    useState<phraseInfo | null>(null);
  const [definitionTitleText, setDefinitionTitleText] = useState<string>("");

  const clauseName = props?.dataPointName?.replace(/_/g, " ");
  const { control, watch } = useForm({
    defaultValues: {
      isPresent: "Yes",
      definitionTitle: "",
    },
  });

  const clauseOptions = [
    {
      value: "Yes",
      title: `${clauseName} clause is present`,
    },
    {
      value: "No",
      title: `${clauseName}  clause is not present`,
    },
  ];

  const isPresent = watch("isPresent") || "";

  useEffect(() => {
    props.saveHighlightedDataPoint(props.dataPointName);
  }, []);

  useEffect(() => {
    if (props.phraseInAddMode !== null) {
      setIsAddingPhrase(false);
    }
  }, [props.phraseInAddMode]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDefinitionTitleText(e.target.value);
  };

  const linkToPhrase = () => {
    props.savePhraseEditOption(true);
    setIsAddingPhrase(true);
    setCurrentEditingPhrase(null);
    setIsEditingState(false);
    props.saveDeletePhrase(null, false);
    props.saveHighlightedTableCells(null);
    props.editOptionSelected(true);
    props.saveHighlightedDataPoint(props.dataPointName);
  };

  const editLinkedPhraseOnDoc = (phraseInfo: phraseInfo) => {
    let deletePhraseElement = "";
    if (isTableCell(phraseInfo)) {
      let tempTableCell: tableInfo = {
        paraId: phraseInfo.paraId,
        rowId: phraseInfo.rowId !== null ? phraseInfo.rowId : -1,
        columnId: phraseInfo.columnId !== null ? phraseInfo.columnId : -1,
      };
      deletePhraseElement =
        "p" +
        phraseInfo.paraId +
        ";r" +
        phraseInfo.rowId +
        ";c" +
        phraseInfo.columnId;
      props.saveHighlightedTableCells([tempTableCell]);
    } else {
      deletePhraseElement =
        "p" + phraseInfo.paraId + ";w" + phraseInfo.startWordId;
      props.saveHighlightedTableCells(null);
    }
    let phraseElement = document.getElementById(deletePhraseElement);
    if (phraseElement) {
      phraseElement.scrollIntoView({ block: "center" });
      document.documentElement.style.scrollBehavior = "smooth";
    }
    props.saveDeletePhrase(phraseInfo, true);
    props.saveHighlightedDataPoint(props.dataPointName);
    setIsEditingState(true);
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(phraseInfo);
    props.editOptionSelected(true);
  };
  const linkPhraseOnDoc = (phraseInfo: phraseInfo | null) => {
    if (phraseInfo === null) {
      return (
        <Stack width="100%" alignItems="start">
          <Button
            sx={{
              color:
                isAddingPhrase !== true
                  ? "#88305F"
                  : props.phraseEditOptionSelected === true
                  ? "#C1C1C1"
                  : "#88305F",
              padding: 0,
            }}
            onClick={() => linkToPhrase()}
          >
            Link phrase
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="start"
        >
          <Button
            sx={{
              color:
                currentEditingPhrase === phraseInfo ? "#C1C1C1" : "#88305F",
              padding: 0,
            }}
            onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
          >
            Edit linked phrase
          </Button>
          <Typography fontSize="14px">{phraseInfo === null ? 0 : 1}</Typography>
        </Stack>
      );
    }
  };

  const addOrRemovePhrase = (action: string, phraseInfo: phraseInfo | null) => {
    if (action === "add") {
      props.saveAddPhrase(null, false);
      props.saveDeletePhrase(null, false);
      props.saveHighlightedTableCells(null);
      let tempPhrases = props.savedHighlightedPhrases;
      if (tempPhrases !== null && phraseInfo !== null) {
        tempPhrases.push(phraseInfo);
        props.saveHighlightedPhrases(tempPhrases);
      } else {
        if (phraseInfo === null) {
          props.saveHighlightedPhrases(phraseInfo);
        } else {
          props.saveHighlightedPhrases([phraseInfo]);
        }
      }
      setIsEditingState(false);
      setIsAddingPhrase(false);
      setCurrentEditingPhrase(null);
    } else if (action === "remove") {
      if (phraseInfo) {
        let tempPhrases = deletePhraseFromPhraseArray(
          phraseInfo,
          props.savedHighlightedPhrases
        );
        props.saveHighlightedPhrases(
          tempPhrases.length === 0 ? null : tempPhrases
        );
      } else {
        props.saveHighlightedPhrases(null);
      }
      props.saveDeletePhrase(null, false);
      props.saveHighlightedTableCells(null);
    }
    props.savePhraseEditOption(false);
  };

  const onCancel = () => {
    props.onClose();
    props.saveDeletePhrase(null, false);
    props.saveAddPhrase(null, false);
    props.saveHighlightedPhrases(null);
    props.savePhraseEditOption(false);
    props.editOptionSelected(false);
    props.saveHighlightedTableCells(null);
  };

  const getAddedAndDeletedPhrases = (
    previousLinkedPhrases: phraseInfo[],
    changedLinkedPhrases: phraseInfo[]
  ): editedPhrases => {
    let addedPhrases: phraseInfo[] = [];
    let deletedPhrases: phraseInfo[] = [];
    if (previousLinkedPhrases.length !== 0) {
      if (changedLinkedPhrases !== null && changedLinkedPhrases.length !== 0) {
        //get newly added phrases
        for (let i = 0; i < changedLinkedPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < previousLinkedPhrases.length; j++) {
            if (
              changedLinkedPhrases[i].paraId === previousLinkedPhrases[j].paraId
            ) {
              if (
                changedLinkedPhrases[i].startWordId ===
                  previousLinkedPhrases[j].startWordId &&
                changedLinkedPhrases[i].endWordId ===
                  previousLinkedPhrases[j].endWordId
              ) {
                exists = true;
                break;
              }
            }
          }
          if (exists === false) {
            addedPhrases.push({
              paraId: changedLinkedPhrases[i].paraId,
              startWordId: changedLinkedPhrases[i].startWordId,
              endWordId: changedLinkedPhrases[i].endWordId,
              startSentenceId: changedLinkedPhrases[i].startSentenceId,
              endSentenceId: changedLinkedPhrases[i].endSentenceId,
              rowId: changedLinkedPhrases[i].rowId,
              columnId: changedLinkedPhrases[i].columnId,
              phrase: changedLinkedPhrases[i].phrase,
              definedword: definitionTitleText,
            });
          }
        }

        //get Deleted phrases
        for (let i = 0; i < previousLinkedPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < changedLinkedPhrases.length; j++) {
            if (
              previousLinkedPhrases[i].paraId === changedLinkedPhrases[j].paraId
            ) {
              if (
                previousLinkedPhrases[i].startWordId ===
                  changedLinkedPhrases[j].startWordId &&
                previousLinkedPhrases[i].endWordId ===
                  changedLinkedPhrases[j].endWordId
              ) {
                exists = true;
                break;
              }
            }
          }
          if (exists === false) {
            deletedPhrases.push({
              paraId: previousLinkedPhrases[i].paraId,
              startWordId: previousLinkedPhrases[i].startWordId,
              endWordId: previousLinkedPhrases[i].endWordId,
              startSentenceId: previousLinkedPhrases[i].startSentenceId,
              endSentenceId: previousLinkedPhrases[i].endSentenceId,
              rowId: previousLinkedPhrases[i].rowId,
              columnId: previousLinkedPhrases[i].columnId,
              phrase: previousLinkedPhrases[i].phrase,
            });
          }
        }
        //all deleted
      } else if (changedLinkedPhrases.length === 0) {
        for (let i = 0; i < previousLinkedPhrases.length; i++) {
          deletedPhrases.push(previousLinkedPhrases[i]);
        }
      }
    } else {
      //newly added
      if (changedLinkedPhrases !== null && changedLinkedPhrases.length !== 0) {
        for (let i = 0; i < changedLinkedPhrases.length; i++) {
          addedPhrases.push({
            paraId: changedLinkedPhrases[i].paraId,
            startWordId: changedLinkedPhrases[i].startWordId,
            endWordId: changedLinkedPhrases[i].endWordId,
            startSentenceId: changedLinkedPhrases[i].startSentenceId,
            endSentenceId: changedLinkedPhrases[i].endSentenceId,
            rowId: changedLinkedPhrases[i].rowId,
            columnId: changedLinkedPhrases[i].columnId,
            phrase: changedLinkedPhrases[i].phrase,
            definedword: definitionTitleText,
          });
        }
      }
    }
    let biType = phraseBiMap[props.dataPointName];

    let tempEditedPhrases: editedPhrases = {
      upsert: addedPhrases,
      deleted: deletedPhrases,
      bi: biType,
    };
    return tempEditedPhrases;
  };

  const getEditedTableCellPhrases = (
    previousLinkedTableCells: phraseInfo[],
    changedLinkedTableCells: phraseInfo[]
  ) => {
    let addedTableCells: phraseInfo[] = [];
    let deletedTableCells: phraseInfo[] = [];
    if (previousLinkedTableCells.length > 0) {
      if (changedLinkedTableCells.length > 0) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId ===
                previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId ===
                previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId ===
                previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push({
              paraId: changedLinkedTableCells[i].paraId,
              startSentenceId: changedLinkedTableCells[i].startSentenceId,
              endSentenceId: changedLinkedTableCells[i].endSentenceId,
              startWordId: changedLinkedTableCells[i].startWordId,
              endWordId: changedLinkedTableCells[i].endWordId,
              rowId: changedLinkedTableCells[i].rowId,
              columnId: changedLinkedTableCells[i].columnId,
              phrase: changedLinkedTableCells[i].phrase,
              definedword: definitionTitleText,
            });
          }
        }

        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId ===
                changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId ===
                changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId ===
                changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (deletedCellExists === false) {
            deletedTableCells.push(previousLinkedTableCells[i]);
          }
        }
      } else {
        //previous deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push(previousLinkedTableCells[i]);
        }
      }
    } else {
      //all newly added
      if (changedLinkedTableCells.length > 0) {
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          addedTableCells.push({
            paraId: changedLinkedTableCells[i].paraId,
            startSentenceId: changedLinkedTableCells[i].startSentenceId,
            endSentenceId: changedLinkedTableCells[i].endSentenceId,
            startWordId: changedLinkedTableCells[i].startWordId,
            endWordId: changedLinkedTableCells[i].endWordId,
            rowId: changedLinkedTableCells[i].rowId,
            columnId: changedLinkedTableCells[i].columnId,
            phrase: changedLinkedTableCells[i].phrase,
            definedword: definitionTitleText,
          });
        }
      }
    }

    let editedTableCells: editedPhrases = {
      upsert: addedTableCells,
      deleted: deletedTableCells,
      bi: "",
    };
    return editedTableCells;
  };

  const mergePhrases = (
    firstEditedPhraseArray: editedPhrases,
    secondEditedPhraseArray: editedPhrases
  ) => {
    let upsertPhrases: phraseInfo[] = firstEditedPhraseArray.upsert.concat(
      secondEditedPhraseArray.upsert
    );
    let deletedPhrases: phraseInfo[] = firstEditedPhraseArray.deleted.concat(
      secondEditedPhraseArray.deleted
    );
    let biType = phraseBiMap[props.dataPointName];
    let mergedPhrases: editedPhrases = {
      upsert: upsertPhrases,
      deleted: deletedPhrases,
      bi: biType,
    };
    return mergedPhrases;
  };

  const getTempPhrase = () => {
    let tempPhraseRequest: LinkPhraseRequest = {
      mode: "",
      editedPhrases: { upsert: [], deleted: [], bi: "" },
    };

    let changedPhrasesArray =
      props.savedHighlightedPhrases !== null
        ? props.savedHighlightedPhrases
        : [];
    if (props.phraseInAddMode !== null) {
      changedPhrasesArray.push(props.phraseInAddMode);
    }
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: "",
    };

    let changedPhrases = filterPhrasesFromPhrasesArray(changedPhrasesArray);
    let changedTableCells =
      filterTableCellsFromPhraseArray(changedPhrasesArray);

    let editedPhrases = getAddedAndDeletedPhrases([], changedPhrases);
    let editedTableCells = getEditedTableCellPhrases([], changedTableCells);
    addedDeletedPhrases = mergePhrases(editedPhrases, editedTableCells);

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: "manual",
    };
    return tempPhraseRequest;
  };

  const onSave = () => {
    let tempPhraseRequest = getTempPhrase();
    const {
      clauseType,
      updatedClauseDataByType,
      sentenceData,
      clauseDataByType,
      updatedClauseData,
      postClauseDataByType,
      fileId,
      clauseData,
    } = props;

    let updatedData = updatedClauseDataByType;
    let newData = updatedData;

    let addedData = tempPhraseRequest.editedPhrases.upsert;

    if (isPresent == "Yes") {
      for (var i = 0; i < addedData.length; i++) {
        if (addedData[i].startSentenceId === addedData[i].endSentenceId) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(clauseType, newData);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    if (diff.length > 0) {
      postClauseDataByType(fileId, clauseType as ClauseType, diff, newData);
    }

    props.saveDeletePhrase(null, false);
    props.saveAddPhrase(null, false);
    props.savePhraseEditOption(false);
    props.saveHighlightedPhrases(null);
    props.editOptionSelected(false);
    props.saveHighlightedDataPoint(props.dataPointName);
    props.saveHighlightedTableCells(null);
    props.onClose();
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600}>
        Add / Edit {props.dataPointName} Clause
      </Typography>
      <Stack direction="column" className="edit-clause-select" spacing={2}>
        <RadioButtonGroup
          row
          required
          name="isPresent"
          options={clauseOptions}
          valueKey="value"
          control={control}
        />
        {isPresent === "Yes" && (
          <Stack width="100%" spacing={1}>
            <ControlledTextField
              name="definitionTitle"
              type="text"
              control={control}
              label="Enter Definition Title"
              value={definitionTitleText}
              onChange={handleChange}
              fullWidth
            />
            {linkPhraseOnDoc(props.phraseInAddMode)}
          </Stack>
        )}
        <Stack direction="row" width="100%">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            disabled={
              props.savedHighlightedTableCells !== null &&
              props.savedHighlightedTableCells.length > 0
            }
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditAddDefinition;
