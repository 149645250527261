import * as changesets from "json-diff-ts";

import {
  ClauseComponentAction,
  GETCLAUSEDATABYTYPE,
  GETCLAUSEDATABYTYPE_FAILURE,
  GETCLAUSEDATABYTYPE_SUCCESS,
  POSTCLAUSEDATABYTYPE_SUCCESS,
  UPDATECLAUSEDATA,
} from "../Actions/def";
import ClauseComponentState, {
  defaultClauseComponentState,
} from "../State/clauseComponentState";
import { ClauseType, ClauseTypeMap } from "../../../Constants/ClauseType";

import { isNullOrUndefined } from "is-what";

export default function ClauseComponentReducer(
  state: ClauseComponentState = defaultClauseComponentState(),
  action: ClauseComponentAction
): ClauseComponentState {
  switch (action.type) {
    case GETCLAUSEDATABYTYPE: {
      return { ...state };
    }
    case GETCLAUSEDATABYTYPE_SUCCESS: {
      const newClauseData = {};
      Object.assign(newClauseData, {
        ...state.clauseData,
        [ClauseTypeMap[action.payload.type as ClauseType]]:
          action.payload.clauseData,
      });
      return {
        ...state,
        clauseData: JSON.parse(JSON.stringify(newClauseData)),
        // updatedClauseData: newUpdatedClauseData,
      };
    }
    case UPDATECLAUSEDATA: {
      let changeList;
      if (action.payload.clauseData) {
        const diff = JSON.parse(
          JSON.stringify(action.payload.clauseData.edited_content)
        );

        let orig = JSON.parse(
          JSON.stringify(
            getFormattedOrigData(
              action.payload.clauseData.raw_content,
              action.payload.type
            )
          )
        );
        if (diff) {
          const unFlattenChanges = changesets.unflattenChanges(diff.update);
          changeList = JSON.parse(
            JSON.stringify(
              changesets.applyChangeset(
                JSON.parse(JSON.stringify(orig)),
                unFlattenChanges
              )
            )
          );
        } else {
          changeList = JSON.parse(
            JSON.stringify(
              changesets.applyChangeset(JSON.parse(JSON.stringify(orig)), diff)
            )
          );
        }
      }

      const newUpdatedClauseData = {};
      Object.assign(newUpdatedClauseData, {
        ...state.updatedClauseData,
        [ClauseTypeMap[action.payload.type as ClauseType]]: JSON.parse(
          JSON.stringify(changeList)
        ),
      });

      return {
        ...state,
        updatedClauseData: JSON.parse(JSON.stringify(newUpdatedClauseData)),
      };
    }
    case GETCLAUSEDATABYTYPE_FAILURE: {
      return { ...state };
    }

    case POSTCLAUSEDATABYTYPE_SUCCESS: {
      let type = ClauseTypeMap[action.payload.type as ClauseType];
      const updatedClauseData = {
        ...state.updatedClauseData,
        [type]: action.payload.updatedClauseData,
      };
      return { ...state, updatedClauseData };
    }

    default:
      return state;
  }
}

function getDurationObj(data: any, value: number, type: string) {
  let item = {
    para_id: data.para_id,
    start_word_id: data.d_start_word_id,
    end_word_id: data.d_end_word_id,
    start_sentence_id: data.sentence_id,
    end_sentence_id: data.sentence_id,
    phrase: data.duration,
    duration_value: value,
    duration_type: type,
    table: data.table,
  };
  return item;
}

function getConfidentialityDurationObj(data: any, value: number, type: string) {
  let item = {
    para_id: data.para_id,
    start_word_id: data.duration_start_word_id,
    end_word_id: data.duration_end_word_id,
    start_sentence_id: data.sentence_id,
    end_sentence_id: data.sentence_id,
    phrase: data.duration,
    duration_value: value,
    duration_type: type,
    table: data.table,
  };
  return item;
}

function getTerritoryObj(data: any) {
  let item = {
    para_id: data.para_id,
    start_word_id: data.t_start_word_id,
    end_word_id: data.t_end_word_id,
    start_sentence_id: data.sentence_id,
    end_sentence_id: data.sentence_id,
    phrase: data.territory,
    table: data.table,
  };
  return item;
}

function getNatureObj(data: any) {
  let item = {
    para_id: data.para_id,
    start_word_id: data.nature_start_word_id,
    end_word_id: data.nature_end_word_id,
    start_sentence_id: data.sentence_id,
    end_sentence_id: data.sentence_id,
    phrase: data.nature,
    table: [{ row: data.row, column: data.column }],
  };
  return item;
}

function getFormattedOrigData(origData: any, type: ClauseType) {
  let newData = JSON.parse(JSON.stringify(origData));
  switch (type) {
    case "non_compete": {
      let duration: any[] = [];
      let territory: any[] = [];
      origData.non_compete.map((data: any) => {
        if (data.duration) {
          let durationData = getDurationTypeValue(data.duration);
          const value = durationData.value;
          const type = durationData.type;
          let item = getDurationObj(data, value, type);
          duration.push(item);
        }
        if (data.territory) {
          let territoryItem = getTerritoryObj(data);
          territory.push(territoryItem);
        }
      });
      newData = {
        ...origData,
        duration,
        territory,
      };
      return newData;
    }
    case "confidentiality": {
      let newDuration: any[] = [];
      let nature: any[] = [];
      newData.confidentiality.map((data: any) => {
        if (!isNullOrUndefined(data.duration)) {
          let durationData = getDurationTypeValue(data.duration);
          const value = durationData.value;
          const type = durationData.type;
          let item = getConfidentialityDurationObj(data, value, type);
          newDuration.push(item);
        }
        if (!isNullOrUndefined(data.nature)) {
          let natureItem = getNatureObj(data);
          nature.push(natureItem);
        }
      });
      newData = {
        ...origData,
        duration: newDuration,
        nature: nature,
      };
      return newData;
    }

    case "non_solicitation": {
      let duration: any[] = [];
      newData.non_solicitation.map((data: any) => {
        if (data.duration) {
          let durationData = getDurationTypeValue(data.duration);
          const value = durationData.value;
          const type = durationData.type;
          let item = getDurationObj(data, value, type);
          duration.push(item);
        }
      });
      newData = {
        ...origData,
        duration,
      };
      return newData;
    }
    case "renewal": {
      let renewal: any[] = [];
      let notice_auto_renewal: any[] = [];
      let auto_renewal: any[] = [];
      let reference_date: any[] = [];
      let renewal_duration: any[] = [];
      newData.renewal.forEach((data: any) => {
        renewal.push({
          para_id: data.para_id,
          para: data.para,
          table: [{ row: -1, column: -1 }],
        });
        let noticeAutoRenewal = getNoticeAutoRenewal(
          data.notice_opt_in_period,
          data.para_id
        );
        let autoRenewal = getAutoRenewal(data.opt_in_out, data.para_id);
        let date = getRenewalDate(data.reference_date, data.para_id);
        let duration = getRenewalDuration(
          data.opt_in_out_duration,
          data.para_id
        );
        notice_auto_renewal = [...noticeAutoRenewal];
        auto_renewal = [...autoRenewal];
        reference_date = [...date];
        renewal_duration = [...duration];
      });
      newData = {
        renewal,
        notice_auto_renewal,
        auto_renewal,
        reference_date,
        renewal_duration,
      };

      return newData;
    }
    case "term": {
      let end: any[] = [];
      let start: any[] = [];
      if (newData?.end) {
        end = [...newData.end];
      }
      if (newData?.start) {
        start = [...newData.start];
      }
      newData = {
        ...origData,
        end,
        start,
      };
      return newData;
    }
    default: {
      return newData;
    }
  }
}
function convertSpelledNumberToNumeric(number: any) {
  const spelledNumbers: any = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
  };

  const lowercaseNumber = number.toLowerCase();
  return spelledNumbers[lowercaseNumber] || 0;
}

function getDurationTypeValue(sentence: string) {
  let value = 0;
  let type = "month";

  const regex =
    /(\d+|\((\d+)\s*\)|\b(?:one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve)\b)\s*(?:days|day|months|month|years|year)/i;
  const match = sentence.match(regex);
  if (match) {
    let temp = match[0].split(" ");
    type = temp[1];
    if (match[2]) {
      value = parseInt(match[2]);
    } else if (typeof temp[0] == "string") {
      value = convertSpelledNumberToNumeric(temp[0]);
    }
  }
  return {
    value,
    type,
  };
}

function getNoticeAutoRenewal(renewalList: any[], paraId: string) {
  if (renewalList.length > 0) {
    let renewalObj = renewalList.map((data) => {
      return {
        para_id: paraId,
        start_word_id: data.notice_opt_in_period_start,
        end_word_id: data.notice_opt_in_period_end,
        start_sentence_id: -1,
        end_sentence_id: -1,
        phrase: data.notice_opt_in_period,
        table: [{ row: data.row || -1, column: data.column || -1 }],
      };
    });
    return renewalObj;
  } else return [];
}

function getAutoRenewal(renewalList: any[], paraId: string) {
  if (renewalList.length > 0) {
    let renewalObj = renewalList.map((data) => {
      return {
        para_id: paraId,
        table: [{ row: data.row || -1, column: data.row || -1 }],
      };
    });
    return renewalObj;
  } else return [];
}

function getRenewalDuration(renewalList: any[], paraId: string) {
  if (renewalList.length > 0) {
    let renewalObj = renewalList.map((data) => {
      let { value, type } = getDurationTypeValue(data.opt_in_out_duration);
      return {
        para_id: paraId,
        start_word_id: data.opt_in_out_duration_start,
        end_word_id: data.opt_in_out_duration_end,
        start_sentence_id: -1,
        end_sentence_id: -1,
        phrase: data.opt_in_out_duration,
        duration_value: value,
        duration_type: type,
        table: [{ row: data.row || -1, column: data.column || -1 }],
      };
    });
    return renewalObj;
  } else return [];
}

function getRenewalDate(list: any[], paraId: string) {
  if (list.length > 0) {
    let renewalObj = list.map((data) => {
      return {
        date: data.date ? data.date : "",
        para_id: paraId,
        start_word_id: data.reference_date_start,
        end_word_id: data.reference_date_end,
        start_sentence_id: -1,
        end_sentence_id: -1,
        phrase: data.reference_date,
        table: [{ row: data.row || -1, column: data.column || -1 }],
      };
    });
    return renewalObj;
  } else return [];
}
