import * as changesets from "json-diff-ts";
import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function ForceClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddEvents, setShowAddEvents] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "force_majeure");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.forceMajeure && clauseData?.forceMajeure,
    [clauseData?.forceMajeure]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.forceMajeure || {},
    [updatedClauseData]
  );

  const forceMajeure = React.useMemo(() => {
    if (updates?.force_majeure) {
      let sortData = updates.force_majeure.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const events = React.useMemo(() => {
    if (updates?.fm_events) {
      let sortData = updates.fm_events.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let force_majeure = forceMajeure?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let fm_events = events?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let updatedData = {
        ...updates,
        force_majeure,
        fm_events,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "force_majeure", diff, updatedData);
      }
    },
    [
      forceMajeure,
      events,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteEvents = React.useCallback(
    (item: any) => {
      let newEvents = events?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        fm_events: newEvents,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "force_majeure", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, events, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="force majeure"
          onClick={() =>
            handleAddEdit(props, "force_majeure", forceMajeure, setShowAdd)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="force_majeure"
          savedInsight={!isNullOrUndefined(forceMajeure) ? forceMajeure : []}
          savedParentClauseDataPoint={forceMajeure}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      <Scrollable maxHeight={200}>
        {forceMajeure?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "force_majeure", index });
              props.onClickHighlight(index, item, "force_majeure");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "force_majeure" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
          />
        ))}
      </Scrollable>
      {!showAddEvents ? (
        <ClauseHeader
          title="Events Covered"
          buttonText="events"
          onClick={() =>
            handleAddEdit(props, "Events Covered", events, setShowAddEvents)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Events Covered"
          savedInsight={!isNullOrUndefined(events) ? events : []}
          savedParentClauseDataPoint={events}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddEvents(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="force_majeure"
        />
      )}
      <Scrollable maxHeight={200}>
        {events?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "events", index });
              props.onClickHighlight(index, item, "Events Covered");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === "events" && activeClause?.index === index
            }
            deleteClause={() => deleteEvents(item)}
            para={item.events}
          />
        ))}
      </Scrollable>
    </>
  );
}
