import { ChangeEvent, ReactNode } from "react";
import { Control, FieldError, Path, useController } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";

import { FieldValues } from "react-hook-form/dist/types/fields";
import React from "react";
import { useFormError } from "./FormErrorProvider";

export type RadioButtonGroupProps<T extends FieldValues> = {
  options: { label: string; id: string | number }[] | any[];
  helperText?: ReactNode;
  name: Path<T>;
  required?: boolean;
  parseError?: (error: FieldError) => ReactNode;
  label?: string;
  labelKey?: string;
  valueKey?: string;
  type?: "number" | "string";
  emptyOptionLabel?: string;
  onChange?: (value: any) => void;
  returnObject?: boolean;
  row?: boolean;
  control?: Control<T>;
  labelProps?: Omit<FormControlLabelProps, "label" | "control" | "value">;
  formLabelProps?: Omit<FormLabelProps, "required" | "error">;
  disabled?: boolean;
  isDescription?: boolean;
  renderCustomLabel?: (option: any) => React.ReactNode;
};

export default function RadioButtonGroup<TFieldValues extends FieldValues>({
  helperText,
  options,
  label,
  name,
  parseError,
  labelKey = "title",
  valueKey = "id",
  required,
  emptyOptionLabel,
  returnObject,
  row,
  control,
  type,
  labelProps,
  disabled,
  formLabelProps,
  isDescription,
  renderCustomLabel,
  ...rest
}: RadioButtonGroupProps<TFieldValues>): JSX.Element {
  const theme = useTheme();
  const errorMsgFn = useFormError();
  const customErrorFn = parseError || errorMsgFn;
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    rules: required ? { required: "This field is required" } : undefined,
    control,
  });

  helperText = error
    ? typeof customErrorFn === "function"
      ? customErrorFn(error)
      : error.message
    : helperText;

  const onRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const radioValue = (event.target as HTMLInputElement).value;
    const returnValue = returnObject
      ? options.find((items) => items[valueKey] === radioValue)
      : radioValue;
    // setValue(name, returnValue, { shouldValidate: true })
    onChange(returnValue);
    if (typeof rest.onChange === "function") {
      rest.onChange(returnValue);
    }
  };

  return (
    <FormControl error={!!error}>
      {label && (
        <FormLabel {...formLabelProps} required={required} error={!!error}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        onChange={onRadioChange}
        name={name}
        row={row}
        value={value || ""}
        sx={{ width: "100%" }}
      >
        {emptyOptionLabel && (
          <FormControlLabel
            {...labelProps}
            control={
              <Radio
                sx={{
                  color: error ? theme.palette.error.main : undefined,
                }}
                checked={!value}
              />
            }
            label={emptyOptionLabel}
            value=""
            sx={{ flex: 1 }}
          />
        )}
        {options.map((option: any) => {
          const optionKey = option[valueKey];
          if (!optionKey) {
            console.error(
              `CheckboxButtonGroup: valueKey ${valueKey} does not exist on option`,
              option
            );
          }
          let val = returnObject ? value[valueKey] : value;
          if (type === "number") {
            val = Number(val);
          }
          const isChecked = val === optionKey;
          return (
            <FormControlLabel
              {...labelProps}
              control={
                <Radio
                  sx={{
                    color: error ? theme.palette.error.main : undefined,
                  }}
                  disabled={disabled || option.disabled}
                  checked={isChecked}
                />
              }
              value={optionKey}
              label={
                renderCustomLabel ? (
                  renderCustomLabel(option)
                ) : (
                  <>
                    <Typography className="title">
                      {option[labelKey]}
                    </Typography>
                    {isDescription && (
                      <Typography className="description" variant="caption">
                        {option?.description}
                      </Typography>
                    )}
                  </>
                )
              }
              key={optionKey}
              sx={{ flex: 1 }}
            />
          );
        })}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
