interface variantProps {
  props: {};
  style: {};
}

interface tabsProps {
  variants: variantProps[];
}

const tabsTheme: tabsProps = {
  variants: [
    {
      props: { variant: "custom" },
      style: {
        indicator: {
          backgroundColor: "transparent",
        },
      },
    },
    {
      props: { variant: "contained" },
      style: {
        indicator: {
          backgroundColor: "transparent",
        },
      },
    },
  ],
};

export default tabsTheme;
