import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import NameAvatar from "../../../RiverusUI/DataGrid/NameAvatar";
import React from "react";
import { riPrimary } from "../../../RiverusUI/Theme/colors";

interface Props {
  tempSignatory: any;
  handleDeleteTempSignatory: (id: string) => void;
}

const AddedSignatoriesList: React.FC<Props> = ({
  tempSignatory,
  handleDeleteTempSignatory,
}) => {
  return (
    <List
      style={{
        backgroundColor: riPrimary[20],
        borderRadius: "10px",
        height: "60px",
        margin: "15px 0",
      }}
    >
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => handleDeleteTempSignatory(tempSignatory)}
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <NameAvatar
            firstName={
              tempSignatory?.first_name || tempSignatory?.name?.split(" ")[0]
            }
            lastName={
              tempSignatory?.last_name || tempSignatory?.name?.split(" ")[1]
            }
          />
        </ListItemAvatar>
        <ListItemText primary={`${tempSignatory?.name} added as signatory`} />
      </ListItem>
    </List>
  );
};

export default AddedSignatoriesList;
