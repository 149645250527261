import React, { useState } from "react";

import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as RiverusLogo } from "../../RiverusUI/Components/Icons/web-menubar-logo.svg";

const Nav = (props: any) => {
  const { handleKeycloakRealmNameChange } = props;
  const [realmName, setRealmName] = useState("");

  return (
    <Box className="cover-background">
      <Grid container sx={{ height: "100%", padding: "20px 50px" }}>
        <Grid item sm={8} className="image-background" />
        <Grid
          item
          sm={4}
          sx={{ maxWidth: "480px", alignSelf: "center", position: "right" }}
        >
          <Box
            sx={{
              alignContent: "center",
              margin: "0 auto 30px",
              width: "180px",
            }}
          >
            <RiverusLogo />
          </Box>
          <Stack
            spacing={2}
            sx={{
              marginBottom: 4,
              padding: "32px",
              background: "white",
              borderRadius: "6px",
              boxShadow: "8px 8px 16px 0px rgba(8, 13, 37, 0.25)",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "#2B3C7F", fontWeight: 600 }}
            >
              Name of Organization
            </Typography>
            <TextField
              className="org-input mb-5"
              size="small"
              value={realmName}
              onChange={(e) => {
                setRealmName(e.target.value);
              }}
              fullWidth={true}
              id="outlined-basic"
              variant="outlined"
            />
            <Button
              onClick={() => {
                handleKeycloakRealmNameChange(realmName);
              }}
              className="org-continue-btn"
              sx={{ width: "100%", borderRadius: "5px", color: "white" }}
            >
              Continue
            </Button>
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ color: "#818181", fontSize: "14px" }}
          >
            <Link
              href="https://www.riverus.co"
              target="_blank"
              sx={{ color: "#818181" }}
              className="link"
              rel="noreferrer"
            >
              Home
            </Link>
            <span>|</span>
            <Link
              href="https://www.riverus.co/privacy-policy"
              target="_blank"
              sx={{ color: "#818181" }}
              className="link"
              rel="noreferrer"
            >
              Terms of Service
            </Link>
            <span>|</span>
            <Link
              href="https://www.riverus.co/privacy-policy"
              target="_blank"
              sx={{ color: "#818181" }}
              className="link"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Nav;
