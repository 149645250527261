import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Stack, Typography } from "@mui/material";
import * as changesets from "json-diff-ts";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ControlledTextField from "../../../../../../RiverusUI/Components/ControlledTextField";
import { ClauseType } from "../../../../../Constants/ClauseType";
import {
  LinkSentenceRequest,
  ParentClauseDataPoint,
  SentencesData,
  editedSentences,
  sentenceInfo,
} from "../../../../../State/documentState";
import { getClauseDataFormat } from "../../../../ClauseComponent/utils/ClauseTypeUtils";
import {
  dataForMap,
  getAuthoritiesFromChild,
} from "../../../../Utils/docUtils";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresentSentences: (presentValue: LinkSentenceRequest) => void;
  dataPointName: string;
  savedInsightChild: any;
  savedParentClauseDataPoint: ParentClauseDataPoint;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  clauseItem: any;
  onClose: any;
  clauseData: any;
}

const EditAddConsentAuthority: React.FC<Props> = (props) => {
  const {
    editOptionSelected,
    editPresentSentences,
    dataPointName,
    savedInsightChild,
    savedParentClauseDataPoint,
    fileId,
    clauseType,
    postClauseDataByType,
    updatedClauseData,
    sentenceData,
    clauseDataByType,
    updatedClauseDataByType,
    parentClauseType,
    clauseItem,
    onClose,
  } = props;

  const [savedAuthorities, setSavedAuthorities] = useState<any[]>([]);
  const [authorityInAddMode, setAuthorityInAddMode] = useState<sentenceInfo>({
    paraId: savedParentClauseDataPoint.paraId,
    sentenceId: savedParentClauseDataPoint.sentenceId,
    rowId: savedParentClauseDataPoint.rowId,
    columnId: savedParentClauseDataPoint.columnId,
    startWordId: -1,
    endWordId: -1,
    phrase: "",
    typestring: "",
  });
  const [newAdded, setNewAdded] = useState<boolean>(false);

  const { control, watch, resetField } = useForm();
  const authorityValue = watch("authority") || "";

  useEffect(() => {
    let currentIndex = -1;

    for (
      let i = 0;
      i < updatedClauseData.consent.consent_regulatory_bi?.length;
      i++
    ) {
      if (
        updatedClauseData.consent.consent_regulatory_bi[i]?.para_id ===
          clauseItem?.para_id &&
        updatedClauseData.consent.consent_regulatory_bi[i]?.sentence_id ===
          clauseItem?.sentence_id
      ) {
        currentIndex = i;
      }
    }

    let existingAuthoritiesArray =
      updatedClauseData.consent.consent_regulatory_bi?.[currentIndex]
        ?.extraction;
    setSavedAuthorities(existingAuthoritiesArray);
  }, []);

  const getAddedAndDeletedAuthorities = (
    changedAuthorities: sentenceInfo[] | null,
    previousAuthorities: sentenceInfo[]
  ) => {
    let addedAuthorities: sentenceInfo[] = [];
    let deletedAuthorities: sentenceInfo[] = [];

    if (previousAuthorities.length !== 0) {
      if (changedAuthorities !== null) {
        if (changedAuthorities.length !== 0) {
          //get newly added elements
          for (let i = 0; i < changedAuthorities.length; i++) {
            let addedExists = false;
            for (let j = 0; j < previousAuthorities.length; j++) {
              if (
                changedAuthorities[i].startWordId ===
                  previousAuthorities[j].startWordId &&
                changedAuthorities[i].endWordId ===
                  previousAuthorities[j].endWordId &&
                changedAuthorities[i].phrase === previousAuthorities[j].phrase
              ) {
                addedExists = true;
                break;
              }
            }
            if (addedExists === false) {
              addedAuthorities.push(changedAuthorities[i]);
            }
          }

          //get deleted elements
          for (let i = 0; i < previousAuthorities.length; i++) {
            let deletedExists = false;
            for (let j = 0; j < changedAuthorities.length; j++) {
              if (
                previousAuthorities[i].paraId ===
                  changedAuthorities[j].paraId &&
                previousAuthorities[i].sentenceId ===
                  changedAuthorities[j].sentenceId &&
                previousAuthorities[i].phrase === changedAuthorities[j].phrase
              ) {
                deletedExists = true;
                break;
              }
            }
            if (deletedExists === false) {
              deletedAuthorities.push(previousAuthorities[i]);
            }
          }
        } else if (changedAuthorities.length === 0) {
          for (let i = 0; i < previousAuthorities.length; i++) {
            deletedAuthorities.push(previousAuthorities[i]);
          }
        }
      }
    } else {
      if (changedAuthorities !== null) {
        if (changedAuthorities.length !== 0) {
          //adding for first time, newly added elements
          for (let i = 0; i < changedAuthorities.length; i++) {
            addedAuthorities.push(changedAuthorities[i]);
          }
        }
      }
    }

    let addedDeletedAuthorties: editedSentences = {
      upsert: addedAuthorities,
      deleted: deletedAuthorities,
      dataFor: dataForMap[dataPointName],
    };

    return addedDeletedAuthorties;
  };

  const addOrRemovePresent = (action: string) => {
    let tempPresentList: LinkSentenceRequest = { data: "", mode: "" };

    let oldAuthorities: sentenceInfo[] =
      getAuthoritiesFromChild(savedInsightChild);
    let changedAuthorities: sentenceInfo[] = savedAuthorities;
    if (
      authorityInAddMode.phrase !== undefined &&
      authorityInAddMode.phrase.length > 0
    ) {
      changedAuthorities.push(authorityInAddMode);
    }

    let mergeEditedAuthorities: editedSentences = getAddedAndDeletedAuthorities(
      changedAuthorities,
      oldAuthorities
    );

    if (action === "add") {
      tempPresentList = {
        mode: "manual",
        editedSentences: mergeEditedAuthorities,
      };
    }
    return tempPresentList;
  };

  const onCancel = () => {
    editOptionSelected(false);
    onClose();
    resetField("authority");
  };

  const editPresent = (action: string, updateAuthority: any[]) => {
    let addSentenceRequest;
    if (newAdded) {
      addSentenceRequest = addOrRemovePresent(action);
      editPresentSentences(addSentenceRequest);
    }
    let newData = updatedClauseDataByType;
    if (updateAuthority?.length > 0) {
      let addedData = updateAuthority;
      for (let i = 0; i < addedData?.length; i++) {
        newData = getClauseDataFormat(
          "add",
          clauseType as ClauseType,
          addedData[i],
          newData,
          sentenceData,
          clauseItem,
          i
        );
      }
    } else {
      newData = getClauseDataFormat(
        "add",
        clauseType as ClauseType,
        null,
        newData,
        sentenceData,
        clauseItem,
        0
      );
    }
    setNewAdded(false);

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
    onCancel();
  };

  const addOrRemoveAuthority = (action: string, authority: any) => {
    if (action === "add") {
      let tempSavedAuthorities: sentenceInfo[];
      if (!savedAuthorities) {
        tempSavedAuthorities = [];
      } else {
        tempSavedAuthorities = savedAuthorities;
      }
      tempSavedAuthorities.push(authority);
      setNewAdded(true);
      setSavedAuthorities(tempSavedAuthorities);
      setAuthorityInAddMode({
        paraId: savedParentClauseDataPoint.paraId,
        sentenceId: savedParentClauseDataPoint.sentenceId,
        rowId: savedParentClauseDataPoint.rowId,
        columnId: savedParentClauseDataPoint.columnId,
        startWordId: -1,
        endWordId: -1,
        phrase: "",
        typestring: "",
      });
      editPresent("add", tempSavedAuthorities);
    } else if (action === "remove") {
      let tempFilteredAuthorities: sentenceInfo[] = [];
      for (let i = 0; i < savedAuthorities.length; i++) {
        if (authority.entity) {
          if (
            savedAuthorities[i].startWordId === authority.startWordId &&
            savedAuthorities[i].endWordId === authority.endWordId &&
            savedAuthorities[i].entity === authority.entity
          ) {
            continue;
          } else {
            tempFilteredAuthorities.push(savedAuthorities[i]);
          }
        } else if (authority.phrase) {
          if (
            savedAuthorities[i].startWordId === authority.startWordId &&
            savedAuthorities[i].endWordId === authority.endWordId &&
            savedAuthorities[i].phrase === authority.phrase
          ) {
            continue;
          } else {
            tempFilteredAuthorities.push(savedAuthorities[i]);
          }
        }
      }
      setSavedAuthorities(tempFilteredAuthorities);
    }
  };

  useEffect(() => {
    if (!authorityValue && savedAuthorities?.length > 0) {
      addOrRemoveAuthority("remove", savedAuthorities?.[0]);
    }
  }, [authorityValue, savedAuthorities]);

  const onSave = () => {
    if (authorityValue) {
      const newAuthority = {
        ...authorityInAddMode,
        phrase: authorityValue,
      };
      setAuthorityInAddMode(newAuthority);
      addOrRemoveAuthority("add", newAuthority);
    }
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add/edit Consent Authority
      </Typography>
      <Stack className="edit-clause-select" spacing={2}>
        <ControlledTextField
          name="authority"
          control={control}
          label="Authority"
          fullWidth
        />

        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditAddConsentAuthority;
