import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import * as changesets from "json-diff-ts";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ControlledTextField from "../../../../../RiverusUI/Components/ControlledTextField";
import { ClauseType } from "../../../../Constants/ClauseType";
import {
  DurationType,
  LinkPhraseRequest,
  SentencesData,
  editedPhrases,
  phraseInfo,
  tableInfo,
} from "../../../../State/documentState";
import { getClauseDataFormat } from "../../../ClauseComponent/utils/ClauseTypeUtils";
import { isTableCell, phraseBiMap } from "../../../Utils/docUtils";

interface LinkDurationPhrasesProps {
  dataPointName: string;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editOptionSelected: (editOptionSelected: boolean) => void;
  savedInsight: any;
  editPhrasesRequest: (newPhraseRequest: LinkPhraseRequest) => void;
  savedHighlightedPhrases: phraseInfo[] | null;
  saveHighlightedPhrases: (
    savedHighlightedPhrases: phraseInfo[] | null
  ) => void;
  phraseEditOptionSelected: boolean;
  savePhraseEditOption: (phraseEditOptionSelected: boolean) => void;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  saveDeletePhrase: (
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ) => void;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  saveAddPhrase: (
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  durationList: DurationType[];
  fileId: string;
  clauseType: string;
  clauseData: any;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  onClose: VoidFunction;
  parentData?: any;
}

const EditLinkDurationPhrase: React.FC<LinkDurationPhrasesProps> = (props) => {
  const {
    dataPointName,
    saveAddPhrase,
    saveDeletePhrase,
    savePhraseEditOption,
    saveHighlightedPhrases,
    saveHighlightedTableCells,
    saveHighlightedDataPoint,
    editOptionSelected,
    phraseInAddMode,
    onClose,
    phraseEditOptionSelected,
    parentData,
    durationList,
  } = props;

  const [isAddingPhrase, setIsAddingPhrase] = useState<boolean>(false);
  const [currentEditingPhrase, setCurrentEditingPhrase] =
    useState<phraseInfo | null>(null);
  const [selectedOption, setSelectedOption] = useState<DurationType>({
    durationName: "",
    durationType: "",
    durationTypeId: -1,
  });

  const { control, setValue, watch, reset } = useForm();

  const durationValue = watch("value") || -1;

  useEffect(() => {
    saveHighlightedDataPoint(dataPointName);
  }, []);

  useEffect(() => {
    const durationData = parentData.child.timeline;
    if (durationData) {
      setValue("value", durationData.duration_value);
      setValue("duration", durationData.duration_type);
      const duration = durationList.filter(
        (data: any) => data.durationName === durationData.duration_type
      );
      setSelectedOption(duration[0]);
    }
  }, [parentData.child.timeline, durationList, setValue]);

  useEffect(() => {
    if (phraseInAddMode !== null) {
      setIsAddingPhrase(false);
    }
  }, [phraseInAddMode]);

  const setDuration = (duration: DurationType) => {
    setSelectedOption(duration);
  };

  const getSaveStatus = () => {
    let saveStatus: boolean = false;
    if (
      phraseInAddMode !== null &&
      phraseInAddMode.paraId !== null &&
      phraseInAddMode.paraId !== -1 &&
      durationValue !== -1 &&
      selectedOption.durationTypeId !== -1
    ) {
      saveStatus = true;
    }
    return saveStatus;
  };

  const linkToPhrase = () => {
    savePhraseEditOption(true);
    setIsAddingPhrase(true);
    setCurrentEditingPhrase(null);
    saveDeletePhrase(null, false);
    saveHighlightedTableCells(null);
    editOptionSelected(true);
    saveHighlightedDataPoint(dataPointName);
  };

  const editLinkedPhraseOnDoc = (phraseInfo: phraseInfo) => {
    let deletePhraseElement = "";
    if (isTableCell(phraseInfo)) {
      let tempTablCell: tableInfo = {
        paraId: phraseInfo.paraId,
        rowId: phraseInfo.rowId !== null ? phraseInfo.rowId : -1,
        columnId: phraseInfo.columnId !== null ? phraseInfo.columnId : -1,
      };
      deletePhraseElement =
        "p" +
        phraseInfo.paraId +
        ";r" +
        phraseInfo.rowId +
        ";c" +
        phraseInfo.columnId;
      saveHighlightedTableCells([tempTablCell]);
    } else {
      deletePhraseElement =
        "p" + phraseInfo.paraId + ";w" + phraseInfo.startWordId;
      saveHighlightedTableCells(null);
    }
    let phraseElement = document.getElementById(deletePhraseElement);
    phraseElement !== undefined &&
      phraseElement !== null &&
      phraseElement.scrollIntoView({ block: "center" }); //scroll to linked phrase
    document.documentElement.style.scrollBehavior = "smooth";

    saveDeletePhrase(phraseInfo, true);
    saveHighlightedDataPoint(dataPointName);
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(phraseInfo);
    editOptionSelected(true);
  };

  const onCancel = () => {
    onClose();
    saveDeletePhrase(null, false);
    saveAddPhrase(null, false);
    saveHighlightedPhrases(null);
    savePhraseEditOption(false);
    editOptionSelected(false);
    saveHighlightedTableCells(null);
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(null);
    setSelectedOption({
      durationName: "",
      durationType: "",
      durationTypeId: -1,
    });
    reset();
  };

  const getTempPhrase = () => {
    let tempPhraseRequest: LinkPhraseRequest = {
      mode: "",
      editedPhrases: { upsert: [], deleted: [], bi: "" },
    };
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: "",
    };
    let newPhrase: phraseInfo =
      phraseInAddMode === null
        ? {
            paraId: -1,
            startWordId: -1,
            endWordId: -1,
            startSentenceId: -1,
            endSentenceId: -1,
            rowId: -1,
            columnId: -1,
            phrase: "",
            durationValue: -1,
            durationTypeId: -1,
          }
        : phraseInAddMode;

    addedDeletedPhrases = {
      upsert: [
        {
          paraId: newPhrase.paraId,
          startWordId: newPhrase.startWordId,
          endWordId: newPhrase.endWordId,
          startSentenceId: newPhrase.startSentenceId,
          endSentenceId: newPhrase.endSentenceId,
          rowId: newPhrase.rowId,
          columnId: newPhrase.columnId,
          phrase: newPhrase.phrase,
          durationValue: durationValue,
          durationTypeId: selectedOption.durationTypeId,
        },
      ],
      deleted: [],
      bi: phraseBiMap[dataPointName],
    };

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: "manual",
    };
    return tempPhraseRequest;
  };

  const onSave = () => {
    let tempPhraseRequest = getTempPhrase();
    const {
      clauseType,
      updatedClauseDataByType,
      sentenceData,
      postClauseDataByType,
      clauseDataByType,
      fileId,
      dataPointName,
      parentClauseType,
      saveAddPhrase,
      editPhrasesRequest,
      saveDeletePhrase,
      savePhraseEditOption,
      saveHighlightedPhrases,
      saveHighlightedTableCells,
      saveHighlightedDataPoint,
      editOptionSelected,
    } = props;
    let updatedData = updatedClauseDataByType;
    let newData = updatedData;
    let addedData = tempPhraseRequest.editedPhrases.upsert;

    for (var i = 0; i < addedData.length; i++) {
      if (addedData[i].startSentenceId === addedData[i].endSentenceId) {
        newData = getClauseDataFormat(
          "add",
          clauseType as ClauseType,
          addedData[i],
          newData,
          sentenceData
        );
      }
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType as ClauseType,
        diff,
        newData
      );
    }

    editPhrasesRequest(tempPhraseRequest);
    saveDeletePhrase(null, false);
    saveAddPhrase(null, false);
    savePhraseEditOption(false);
    saveHighlightedPhrases(null);
    editOptionSelected(false);
    saveHighlightedDataPoint(dataPointName);
    saveHighlightedTableCells(null);
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(null);
    setSelectedOption({
      durationName: "",
      durationType: "",
      durationTypeId: -1,
    });
    onClose();
    reset();
  };

  const linkPhraseOnDoc = (phraseInfo: phraseInfo | null) => {
    if (phraseInfo === null) {
      return (
        <Stack alignItems="start" width="100%">
          <Button
            variant="text"
            sx={{
              color:
                isAddingPhrase !== true
                  ? "#88305F"
                  : phraseEditOptionSelected === true
                  ? "#C1C1C1"
                  : "#88305F",
              padding: 0,
            }}
            onClick={() => linkToPhrase()}
          >
            Link phrase
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button
            variant="text"
            sx={{
              color:
                currentEditingPhrase === phraseInfo ? "#C1C1C1" : "#88305F",
              padding: 0,
            }}
            onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
          >
            Edit linked phrase
          </Button>
          <Typography fontSize="14px">{phraseInfo === null ? 0 : 1}</Typography>
        </Stack>
      );
    }
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} pb={1}>
        Add / Edit {dataPointName}
      </Typography>

      <Stack className="edit-clause-select" spacing={2}>
        <Stack direction="row" width="100%" spacing={1}>
          <ControlledTextField
            name="value"
            type="number"
            control={control}
            label="Value"
            fullWidth
          />
          <Controller
            name="duration"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="Select-Duration">
                  Select Duration
                </InputLabel>
                <Select
                  {...field}
                  required
                  label="Select Duration"
                  variant="outlined"
                  inputProps={{
                    id: "Select-Duration",
                  }}
                  value={selectedOption}
                >
                  {durationList.map((durationItem: any, i: number) => (
                    <MenuItem
                      key={i}
                      value={durationItem}
                      onClick={() => setDuration(durationItem)}
                    >
                      {durationItem.durationName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>
        {linkPhraseOnDoc(phraseInAddMode)}

        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to add a {dataPointName}?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Input the duration using the field above.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Click "Link Phrase" button to link a text from the contract.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the desired paragraph that contains {dataPointName}.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Confirm your selection using the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            disabled={!getSaveStatus()}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditLinkDurationPhrase;
