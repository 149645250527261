import { Box, Stack, Typography } from "@mui/material";
import * as changesets from "json-diff-ts";
import React from "react";
import { useForm } from "react-hook-form";
import RadioButtonGroup from "../../../../../RiverusUI/Components/RadioButtonGroup";
import { ClauseType } from "../../../../Constants/ClauseType";
import {
  LinkParaRequest,
  SentencesData,
  editedParas,
  paraInfo,
  tableInfo,
} from "../../../../State/documentState";
import {
  getClauseDataFormat,
  getClauseObjects,
} from "../../../ClauseComponent/utils/ClauseTypeUtils";
import {
  getParasFromChild,
  getTableCellsFromChild,
} from "../../../Utils/docUtils";
import SaveOrCancel from "../saveOrCancel";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresent: (newParasRequest: LinkParaRequest) => void;
  savedPresent: string;
  dataPointName: string;
  highlightedId: number[] | null;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedPresentData: any;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  onClose: any;
  fileId: string;
  clauseType: string;
  clauseData: any;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  parentClauseData: any;
  updatedParentClauseDataByType: any;
}

const EditTerminationConveniences: React.FC<Props> = (props) => {
  const { control, watch } = useForm({
    defaultValues: {
      isPresent: "Yes",
    },
  });

  const clauseOptions = [
    {
      value: "Yes",
      title: `${props.dataPointName} clause is present`,
    },
    {
      value: "No",
      title: `${props.dataPointName}  clause is not present`,
    },
  ];

  const isPresent = watch("isPresent") || "";

  const saveHighlightedDataPoint = (highlight?: boolean) => {
    props.saveHighlightedDataPoint(props.dataPointName);
  };

  const getAddedAndDeletedParas = (
    previousParas: number[],
    changedParas: number[]
  ) => {
    let addedParas: paraInfo[] = [];
    let deletedParas: paraInfo[] = [];
    if (previousParas.length !== 0) {
      if (changedParas.length !== 0) {
        for (let i = 0; i < changedParas.length; i++) {
          let exists = previousParas.includes(changedParas[i]);
          if (!exists) {
            addedParas.push({
              paraId: changedParas[i],
              rowId: -1,
              columnId: -1,
            });
          }
        }
        for (let i = 0; i < previousParas.length; i++) {
          let exists = changedParas.includes(previousParas[i]);
          if (!exists) {
            deletedParas.push({
              paraId: previousParas[i],
              rowId: -1,
              columnId: -1,
            });
          }
        }
      } else {
        for (let i = 0; i < previousParas.length; i++) {
          deletedParas.push({
            paraId: previousParas[i],
            rowId: -1,
            columnId: -1,
          });
        }
      }
    } else {
      if (changedParas.length !== 0) {
        for (let i = 0; i < changedParas.length; i++) {
          addedParas.push({
            paraId: changedParas[i],
            rowId: -1,
            columnId: -1,
          });
        }
      }
    }

    let addedDeletedParas: editedParas = {
      upsert: addedParas,
      deleted: deletedParas,
      bi: "",
    };

    return addedDeletedParas;
  };

  const getAddedAndDeletedTableCells = (
    previousLinkedTableCells: tableInfo[],
    changedLinkedTableCells: tableInfo[] | null
  ) => {
    let addedTableCells: tableInfo[] = [];
    let deletedTableCells: tableInfo[] = [];
    if (
      previousLinkedTableCells.length !== 0 &&
      previousLinkedTableCells !== null
    ) {
      if (
        changedLinkedTableCells !== null &&
        changedLinkedTableCells.length !== 0
      ) {
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = previousLinkedTableCells.some(
            (cell) =>
              cell.paraId === changedLinkedTableCells[i].paraId &&
              cell.rowId === changedLinkedTableCells[i].rowId &&
              cell.columnId === changedLinkedTableCells[i].columnId
          );
          if (!addedCellExists) {
            addedTableCells.push({
              paraId: changedLinkedTableCells[i].paraId,
              rowId: changedLinkedTableCells[i].rowId,
              columnId: changedLinkedTableCells[i].columnId,
            });
          }
        }
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = changedLinkedTableCells.some(
            (cell) =>
              previousLinkedTableCells[i].paraId === cell.paraId &&
              previousLinkedTableCells[i].rowId === cell.rowId &&
              previousLinkedTableCells[i].columnId === cell.columnId
          );
          if (!deletedCellExists) {
            deletedTableCells.push({
              paraId: previousLinkedTableCells[i].paraId,
              rowId: previousLinkedTableCells[i].rowId,
              columnId: previousLinkedTableCells[i].columnId,
            });
          }
        }
      } else {
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else if (
      changedLinkedTableCells !== null &&
      changedLinkedTableCells.length !== 0
    ) {
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        addedTableCells.push(changedLinkedTableCells[i]);
      }
    }

    let upsertParas: paraInfo[] = addedTableCells.map((cell) => ({
      paraId: cell.paraId,
      rowId: cell.rowId,
      columnId: cell.columnId,
    }));

    let deletedParas: paraInfo[] = deletedTableCells.map((cell) => ({
      paraId: cell.paraId,
      rowId: cell.rowId,
      columnId: cell.columnId,
    }));

    let editedTableCellsAsPara: editedParas = {
      upsert: upsertParas,
      deleted: deletedParas,
      bi: "",
    };
    return editedTableCellsAsPara;
  };

  const mergeEditedParas = (
    firstEditedParas: editedParas,
    secondEditedParas: editedParas
  ) => {
    let mergedAddedParas: paraInfo[] = firstEditedParas.upsert.concat(
      secondEditedParas.upsert
    );
    let mergedDeletedParas: paraInfo[] = firstEditedParas.deleted.concat(
      secondEditedParas.deleted
    );

    let mergeEditedParas: editedParas = {
      upsert: mergedAddedParas,
      deleted: mergedDeletedParas,
      bi: props.dataPointName,
    };
    return mergeEditedParas;
  };

  const addOrRemovePresent = (action: string) => {
    let tempPresentList: LinkParaRequest = {
      data: "",
      mode: "",
      editedParas: { upsert: [], deleted: [], bi: "" },
    };

    let previousParas = getParasFromChild(props.savedPresentData);
    let changedParas: number[] =
      props.highlightedId !== null ? props.highlightedId : [];

    let previousTableCells = getTableCellsFromChild(props.savedPresentData);
    let changedLinkedTableCells = props.savedHighlightedTableCells;

    let editedPara: editedParas = getAddedAndDeletedParas(
      previousParas,
      changedParas
    );

    let editedTablesCells: editedParas = getAddedAndDeletedTableCells(
      previousTableCells,
      changedLinkedTableCells
    );

    let mergeEditedPara: editedParas = mergeEditedParas(
      editedPara,
      editedTablesCells
    );

    if (action === "add") {
      if (
        props.highlightedId !== null ||
        props.savedHighlightedTableCells !== null
      ) {
        tempPresentList = {
          data: isPresent,
          mode: "manual",
          editedParas: mergeEditedPara,
        };
      } else {
        tempPresentList = {
          data: isPresent,
          mode: "retain",
        };
      }
    }
    return tempPresentList;
  };

  const editPresent = () => {
    const {
      clauseType,
      sentenceData,
      clauseDataByType,
      fileId,
      updatedClauseDataByType,
      postClauseDataByType,
      parentClauseType,
      parentClauseData,
      updatedParentClauseDataByType,
    } = props;
    const newParasRequest = addOrRemovePresent("add");
    props.editPresent(newParasRequest);
    let newData = updatedClauseDataByType;
    let newTerminationData = updatedParentClauseDataByType;

    if (isPresent === "Yes") {
      if (newParasRequest.editedParas?.upsert) {
        const addedData = newParasRequest.editedParas.upsert;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
        }

        for (let i = 0; i < addedData.length; i++) {
          newTerminationData = getClauseDataFormat(
            "add",
            "termination",
            addedData[i],
            newTerminationData,
            sentenceData
          );
        }
      }
      if (newParasRequest?.editedParas?.deleted) {
        const deletedData = newParasRequest.editedParas.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            "remove",
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(clauseType, newData);
    }

    const termination_at_convenience = {
      termination_at_convenience: newData?.termination_at_convenience,
    };

    let diff = changesets.diff(
      clauseDataByType?.raw_content,
      termination_at_convenience,
      {
        children: "$index",
      }
    );

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType,
        diff,
        termination_at_convenience
      );
    }

    if (isPresent === "Yes") {
      const termination = {
        termination: newTerminationData?.termination,
      };

      diff = changesets.diff(parentClauseData?.raw_content, termination, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType(fileId, "termination", diff, termination);
      }
    }
    props.onClose();
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} mb={1} textTransform="capitalize">
        Add / Edit {props.dataPointName} Clause
      </Typography>

      <Stack width="100%" spacing={2}>
        <RadioButtonGroup
          row
          required
          name="isPresent"
          options={clauseOptions}
          valueKey="value"
          control={control}
        />
        <Stack width="100%">
          <Typography variant="body2" fontWeight={700}>
            How to add a paragraph to {props.dataPointName} clause?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Select "{props.dataPointName} Clause Is Present"
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on "Edit Linked Paragraph(s)" button.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            4). Click on the desired text or the link icon on the box around the
            text that you want to add as {props.dataPointName} clause.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            5). Click on Save.
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body2" fontWeight={700}>
            How to remove a paragraph from {props.dataPointName} clause?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Hover over the highlighted text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on the bin icon against the highlighted paragraph you want
            to remove from {props.dataPointName} clause.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Click on Save.
          </Typography>
        </Stack>
        <SaveOrCancel
          enableHighlightOption={isPresent === "Yes"}
          dataPointName={props.dataPointName}
          editOptionSelected={props.editOptionSelected}
          editDataPoint={() => {
            editPresent();
            props.saveHighlightedId(null);
            props.editOptionSelected(false);
            props.saveHighlightedTableCells(null);
            props.onClose();
          }}
          highlightedId={props.highlightedId}
          saveHighlightedDataPoint={(highlight?: boolean) =>
            saveHighlightedDataPoint(highlight)
          }
          enableSaveBtn={true}
          saveHighlightedId={(highlightedId: number[] | null) => {
            props.saveHighlightedId(highlightedId);
          }}
          previouslyLinkedPara={
            getParasFromChild(props.savedPresentData)?.length > 0
              ? getParasFromChild(props.savedPresentData)
              : undefined
          }
          previouslyLinkedTableCells={
            getTableCellsFromChild(props.savedPresentData)?.length > 0
              ? getTableCellsFromChild(props.savedPresentData)
              : undefined
          }
          savedHighlightedTableCells={props.savedHighlightedTableCells}
          saveHighlightedTableCells={(
            savedHighlightedTableCells: tableInfo[] | null
          ) => props.saveHighlightedTableCells(savedHighlightedTableCells)}
          handleSubmitSelectedUserGroups={() => {}}
          onClose={props.onClose}
        />
      </Stack>
    </Box>
  );
};

export default EditTerminationConveniences;
