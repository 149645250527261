import { Box, Button, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import React, { useCallback, useState } from "react";

import DocxEditingComponent from "./DocxEditingComponent";
import DocxViewerComponent from "./DocxViewerComponent";
import SearchIcon from "@mui/icons-material/Search";
import ValidationPopup from "./ValidationPopup";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useMutation } from "@tanstack/react-query";
import { getS3ReferencePresignedURL } from "../../Services/Draft";

interface IProps {
  draftData: any;
  fileLink: string;
  handleOpenClauseLibraryDrawer?: VoidFunction;
  canEdit?: boolean;
  instance: any;
  setInstance: any;
  dropPoint?: any;
  setDropPoint?: any;
  addedSignatureFields?: any;
  setAddedSignatureFields?: any;
  referenceDocData?: any;
  setViewDocDetails?: any;
  isExternal?: boolean;
  setDocumentLoading?: any;
}

const PdfView: React.FC<IProps> = ({
  draftData,
  fileLink,
  canEdit,
  instance,
  setInstance,
  setDropPoint,
  setAddedSignatureFields,
  handleOpenClauseLibraryDrawer,
  referenceDocData,
  setViewDocDetails,
  isExternal,
  setDocumentLoading,
}) => {
  const [referenceDoc, setReferenceDoc] = React.useState<any>();

  const [validationPopup, setValidationPopup] = useState<any>({
    modal: false,
    text: "",
  });

  const zoomOut = () => {
    const zoom = instance.UI.getZoomLevel() - 0.25;
    if (zoom > 0.25) {
      instance.UI.setZoomLevel(zoom);
    }
  };

  const zoomIn = () => {
    const zoom = instance.UI.getZoomLevel() + 0.25;
    instance.UI.setZoomLevel(zoom);
  };

  const search = useCallback(() => {
    const { UI } = instance;
    UI.searchText("", {
      caseSensitive: true,
      wholeWord: true,
    });
  }, [instance]);

  const drop = useCallback(
    (e: any, docViewer: any) => {
      const scrollElement = docViewer.getScrollViewElement();
      const scrollLeft = scrollElement.scrollLeft || 0;
      const scrollTop = scrollElement.scrollTop || 0;
      setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
      e.preventDefault();
      return false;
    },
    [setDropPoint]
  );

  const dragOver = useCallback((e: any) => {
    e.preventDefault();
    return false;
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate: viewReferenceDocument } = useMutation({
    mutationKey: ["view_reference_document_link"],
    mutationFn: getS3ReferencePresignedURL,
    onSuccess: (response: any) => {
      setViewDocDetails({
        access_url: response?.data?.presigned_url,
        dataObject: referenceDoc,
      });
    },
  });

  const handleViewDocDetails = (data: any) => {
    setReferenceDoc(data?.dataObject);
    const payload = {
      file_type: "view_document",
      link: data?.access_url,
    };
    viewReferenceDocument(payload);
  };

  return (
    <React.Fragment>
      <Stack direction="row">
        <Stack
          sx={{
            background: "#88305F14",
            borderRadius: "25px",
            padding: "2px 10px",
            flex: 1,
          }}
          direction="row"
          justifyContent="space-between"
        >
          <IconButton
            color="primary"
            onClick={() => {
              search();
            }}
          >
            <SearchIcon /> Search
          </IconButton>
          {referenceDocData?.length > 0 && !canEdit && (
            <Button
              endIcon={<ArrowDropDownIcon />}
              id="menu-button"
              aria-controls={open ? "fade-menu" : undefined}
              onClick={handleClick}
            >
              {draftData?.contractName || draftData?.file_name}
            </Button>
          )}
          <Menu
            id="menu-button"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {referenceDocData?.map(
              (referenceDoc: any) =>
                (draftData?.contractName !==
                  referenceDoc?.dataObject?.contractName ||
                  draftData?.file_name !==
                    referenceDoc?.dataObject?.file_name) && (
                  <MenuItem onClick={handleClose}>
                    <Button
                      variant="text"
                      style={{
                        padding: 0,
                      }}
                      onClick={() => handleViewDocDetails(referenceDoc)}
                    >
                      {referenceDoc?.dataObject?.file_name ||
                        referenceDoc?.dataObject?.contractName}
                    </Button>
                  </MenuItem>
                )
            )}
          </Menu>
          <Stack direction="row">
            <IconButton
              color="primary"
              onClick={() => {
                zoomIn();
              }}
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                zoomOut();
              }}
            >
              <ZoomOutIcon />
            </IconButton>
          </Stack>
        </Stack>
        {canEdit && (
          <Button variant="contained" onClick={handleOpenClauseLibraryDrawer}>
            Open Clause Library
          </Button>
        )}
      </Stack>
      <Box marginTop={1}>
        {canEdit ? (
          <DocxEditingComponent
            key={`${fileLink}-edit`}
            fileLink={fileLink}
            setInstance={setInstance}
            dragOver={dragOver}
            setAddedSignatureFields={setAddedSignatureFields}
            drop={drop}
            draftDetails={draftData}
          />
        ) : (
          <DocxViewerComponent
            key={fileLink}
            fileLink={fileLink}
            setInstance={setInstance}
            dragOver={dragOver}
            setAddedSignatureFields={setAddedSignatureFields}
            drop={drop}
            draftDetails={draftData}
            instance={instance}
            isExternal={isExternal}
            setDocumentLoading={setDocumentLoading}
          />
        )}
      </Box>
      {validationPopup && (
        <ValidationPopup
          validationPopup={validationPopup}
          onClose={() => {
            setValidationPopup({ modal: false, text: "" });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PdfView;
