import * as changesets from "json-diff-ts";

import React, { useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";

export default function TerminationClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddConvenience, setShowAddConvenience] = useState(false);
  const [showAddLockInPeriod, setShowAddLockInPeriod] = useState(false);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  const data = useMemo(
    () => clauseData?.termination && clauseData.termination,
    [clauseData?.termination]
  );

  const terminationAtConvenienceData = useMemo(
    () =>
      clauseData?.terminationAtConvenience &&
      clauseData.terminationAtConvenience,
    [clauseData?.terminationAtConvenience]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "termination");
    hasData && getClauseDataByType(fileId, "termination_at_convenience");
  }, [fileId, hasData]);

  const updates = useMemo(
    () => updatedClauseData?.termination || {},
    [updatedClauseData]
  );

  const updatesTerminationAtConvenience = useMemo(
    () => updatedClauseData?.terminationAtConvenience || {},
    [updatedClauseData]
  );

  const termination_clause = useMemo(() => {
    if (updates?.termination) {
      let sortData = updates.termination.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const terminationConvenience = useMemo(() => {
    if (updatesTerminationAtConvenience?.termination_at_convenience) {
      let sortData =
        updatesTerminationAtConvenience.termination_at_convenience.sort(
          function (a: any, b: any) {
            return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
          }
        );
      return sortData;
    }
    return [];
  }, [updatesTerminationAtConvenience]);

  const terminationLockin = useMemo(() => {
    if (updates?.lockin) {
      let sortData = updates.lockin.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const terminationEvent = useMemo(() => {
    if (updates?.event) {
      let sortData = updates.event.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let termination = termination_clause?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let savedPresent = terminationConvenience?.present;
      let termination_at_convenience = terminationConvenience?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      if (savedPresent) {
        termination_at_convenience.present = savedPresent;
      }
      if (termination_at_convenience?.length === 0) {
        termination_at_convenience.present = "No";
      }

      let lockin = terminationLockin?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let event = terminationEvent?.filter(
        (data: any) =>
          data.eventName !== item.eventName || data.phrase !== item.phrase
      );

      let updatedData = {
        ...updates,
        termination,
        lockin,
        event,
      };
      let diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "termination", diff, updatedData);
      }

      let updatedTerminationAtConvenienceData = {
        ...updatesTerminationAtConvenience,
        termination_at_convenience,
      };

      diff = changesets.diff(
        terminationAtConvenienceData?.raw_content,
        updatedTerminationAtConvenienceData,
        {
          children: "$index",
        }
      );

      if (diff.length > 0) {
        postClauseDataByType?.(
          fileId,
          "termination_at_convenience",
          diff,
          updatedTerminationAtConvenienceData
        );
      }

      if (diff.length === 0) {
        postClauseDataByType?.(
          fileId,
          "termination_at_convenience",
          diff,
          terminationAtConvenienceData?.raw_content
        );
      }
    },
    [
      termination_clause,
      terminationConvenience,
      terminationLockin,
      terminationEvent,
      updates,
      data?.raw_content,
      updatesTerminationAtConvenience,
      terminationAtConvenienceData?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteConvenience = React.useCallback(
    (item: any) => {
      let savedPresent = terminationConvenience?.present;
      let termination_at_convenience = terminationConvenience?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      if (savedPresent) {
        termination_at_convenience.present = savedPresent;
      }
      if (termination_at_convenience?.length === 0) {
        termination_at_convenience.present = "No";
      }

      let updatedData = {
        ...updatesTerminationAtConvenience,
        termination_at_convenience,
      };
      const diff = changesets.diff(
        terminationAtConvenienceData?.raw_content,
        updatedData,
        {
          children: "$index",
        }
      );

      if (diff.length > 0) {
        postClauseDataByType?.(
          fileId,
          "termination_at_convenience",
          diff,
          updatedData
        );
      }

      if (diff.length === 0) {
        postClauseDataByType?.(
          fileId,
          "termination_at_convenience",
          diff,
          terminationAtConvenienceData?.raw_content
        );
      }
    },
    [
      updatesTerminationAtConvenience,
      postClauseDataByType,
      terminationConvenience,
      terminationAtConvenienceData,
      fileId,
    ]
  );

  const deleteLockinPeriod = React.useCallback(
    (item: any) => {
      let lockin = terminationLockin?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        lockin,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "termination", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, terminationLockin, data, fileId]
  );

  const deleteEvent = React.useCallback(
    (item: any) => {
      let event = terminationEvent?.filter(
        (data: any) =>
          data.eventName !== item.eventName || data.phrase !== item.phrase
      );

      let updatedData = {
        ...updates,
        event,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "termination", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, terminationEvent, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="Termination clause"
          onClick={() => {
            handleAddEdit(
              props,
              "Termination clause",
              termination_clause,
              setShowAdd
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={props.toBeEdited}
          savedInsight={
            !isNullOrUndefined(termination_clause) ? termination_clause : []
          }
          savedParentClauseDataPoint={termination_clause}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseData={updates}
          postClauseDataByType={postClauseDataByType}
          updatedClauseDataByType={updates}
          parentClauseType={"termination"}
          // onSubmit={(payload: any) => onUpdate(payload)}
        />
      )}
      <Scrollable maxHeight={200}>
        {termination_clause?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "termination", index });
              props.onClickHighlight(index, item, "termination");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === "termination" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>
      {!showAddConvenience ? (
        <ClauseHeader
          title="Termination at Convenience"
          buttonText="Convenience"
          onClick={() => {
            handleAddEdit(
              props,
              "Termination at Convenience",
              terminationConvenience,
              setShowAddConvenience
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={props.toBeEdited}
          savedInsight={
            !isNullOrUndefined(terminationConvenience)
              ? terminationConvenience
              : []
          }
          savedParentClauseDataPoint={terminationConvenience}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAddConvenience(false);
          }}
          clauseDataByType={terminationAtConvenienceData}
          updatedClauseData={updatesTerminationAtConvenience}
          postClauseDataByType={postClauseDataByType}
          updatedClauseDataByType={updatesTerminationAtConvenience}
          parentClauseType={"termination_at_convenience"}
          parentClauseData={data}
          updatedParentClauseDataByType={updates}
          // onSubmit={(payload: any) => onUpdate(payload)}
        />
      )}
      <Scrollable maxHeight={200}>
        {terminationConvenience?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "convenience", index });
              props.onClickHighlight(index, item, "convenience");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === "convenience" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteConvenience(item)}
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>
      {!showAddLockInPeriod ? (
        <ClauseHeader
          title="Lock-in Period"
          buttonText="Period"
          onClick={() => {
            handleAddEdit(
              props,
              "Lock-in Period",
              terminationLockin,
              setShowAddLockInPeriod
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={props.toBeEdited}
          savedInsight={
            !isNullOrUndefined(terminationLockin) ? terminationLockin : []
          }
          savedParentClauseDataPoint={terminationLockin}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAddLockInPeriod(false);
          }}
          clauseDataByType={data}
          updatedClauseData={updates}
          postClauseDataByType={postClauseDataByType}
          updatedClauseDataByType={updates}
          parentClauseType={"termination"}
          // onSubmit={(payload: any) => onUpdate(payload)}
        />
      )}
      {terminationLockin?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "period", index });
            props.onClickHighlight(index, item, "convenience");
          }}
          index={index}
          clauseItem={item}
          sentenceData={props?.sentenceData}
          isActive={
            activeClause?.type === "period" && activeClause?.index === index
          }
          deleteClause={() => deleteLockinPeriod(item)}
          para={
            item.sentence ||
            getPara(
              props?.sentenceData,
              item.para_id,
              item.sentence_id,
              item.table && item.table[0]?.row,
              item.table && item.table[0]?.column
            )
          }
          subTitle={item?.phrase}
        />
      ))}
      {!showAddEvent ? (
        <ClauseHeader
          title="Termination Event"
          buttonText="Event"
          onClick={() => {
            handleAddEdit(
              props,
              "Termination Event",
              terminationEvent,
              setShowAddEvent
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={props.toBeEdited}
          savedInsight={
            !isNullOrUndefined(terminationEvent) ? terminationEvent : []
          }
          savedParentClauseDataPoint={terminationEvent}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          onClose={() => {
            setShowAddEvent(false);
          }}
          clauseDataByType={data}
          updatedClauseData={updates}
          postClauseDataByType={postClauseDataByType}
          updatedClauseDataByType={updates}
          parentClauseType={"termination"}
          canAddTags
        />
      )}
      <Scrollable maxHeight={200}>
        {terminationEvent?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "event", index });
              props.onClickHighlight(index, item, "convenience");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === "event" && activeClause?.index === index
            }
            deleteClause={() => deleteEvent(item)}
            phraseLevel
            subTitle={item?.eventName}
          />
        ))}
      </Scrollable>
    </>
  );
}
