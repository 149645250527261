import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const CommitDraftIcon = ({ props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M4.5 7.875L9 9L4.5 10.125V12.7425L8.5425 11.04C8.355 11.58 8.25 12.15 8.25 12.75V12.7875L3 15V3L8.355 5.25L9.69375 5.8125L10.3631 6.09375L10.6978 6.23438L10.3292 6.43492C9.95397 6.63904 9.65822 6.96328 9.48938 7.35563L7.82625 6.65625L4.5 5.2575V7.875Z" />
      <path d="M12.375 13.5C12.7875 13.5 13.125 13.1538 13.125 12.7308H11.625C11.625 13.1538 11.9588 13.5 12.375 13.5ZM14.625 11.1923V9.26923C14.625 8.08846 14.01 7.1 12.9375 6.83846V6.57692C12.9375 6.25769 12.6862 6 12.375 6C12.0638 6 11.8125 6.25769 11.8125 6.57692V6.83846C10.7363 7.1 10.125 8.08462 10.125 9.26923V11.1923L9.375 11.9615V12.3462H15.375V11.9615L14.625 11.1923Z" />
    </SvgIcon>
  );
};

export default CommitDraftIcon;
