import * as changesets from "json-diff-ts";

import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function DataBreachClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
    editOptionSelected,
    childInEditId,
    onClickHighlight,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddNotify, setShowAddNotify] = useState(false);
  const [showAddRegulation, setShowAddRegulation] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "data_breach");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.dataBreach && clauseData.dataBreach,
    [clauseData?.dataBreach]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.dataBreach || {},
    [updatedClauseData]
  );

  const dataBreach = React.useMemo(() => {
    if (updates?.data_breach) {
      let sortData = updates.data_breach.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const notify = React.useMemo(() => {
    if (updates?.notify) {
      let sortData = updates.notify.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const regulation = React.useMemo(() => {
    if (updates?.regulation) {
      return updates.regulation;
    }
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let data_breach = dataBreach?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let notifyData = notify?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let regulation_data = regulation;
      if (regulation?.para_id === item.para_id) {
        regulation_data = null;
      }

      let updatedData = {
        ...updates,
        data_breach,
        notify: notifyData,
        regulation: regulation_data,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "data_breach", diff, updatedData);
      }
    },
    [
      dataBreach,
      notify,
      regulation,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteNotify = React.useCallback(
    (item: any) => {
      let notifyData = notify?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        notify: notifyData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "data_breach", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, notify, data, fileId]
  );

  const deleteRegulation = React.useCallback(() => {
    let updatedData = {
      ...updates,
      regulation: null,
    };
    const diff = changesets.diff(data?.raw_content, updatedData, {
      children: "$index",
    });
    if (diff.length > 0) {
      postClauseDataByType?.(fileId, "data_breach", diff, updatedData);
    }
  }, [updates, postClauseDataByType, data, fileId]);

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="data breach"
          onClick={() => {
            handleAddEdit(props, "Data Breach", dataBreach, setShowAdd);
          }}
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="data_breach"
          savedInsight={!isNullOrUndefined(dataBreach) ? dataBreach : []}
          savedParentClauseDataPoint={dataBreach}
          editOptionSelected={(selected: boolean) => {
            editOptionSelected(selected);
          }}
          childInEditId={childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      <Scrollable maxHeight={200}>
        {dataBreach?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "data_breach", index });
              onClickHighlight(index, item, "data_breach");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === "data_breach" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
          />
        ))}
      </Scrollable>
      {!showAddNotify ? (
        <ClauseHeader
          title="Notify"
          buttonText="notify"
          onClick={() => {
            handleAddEdit(
              props,
              "Data Breach Notify",
              notify,
              setShowAddNotify
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="Data Breach Notify"
          savedInsight={!isNullOrUndefined(notify) ? notify : []}
          savedParentClauseDataPoint={notify}
          editOptionSelected={(selected: boolean) => {
            editOptionSelected(selected);
          }}
          childInEditId={childInEditId}
          onClose={() => {
            setShowAddNotify(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="data_breach"
        />
      )}
      {notify?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: "notify", index });
            onClickHighlight(index, item, "notify");
          }}
          index={index}
          clauseItem={item}
          sentenceData={props?.sentenceData}
          isActive={
            activeClause?.type === "notify" && activeClause?.index === index
          }
          deleteClause={() => deleteNotify(item)}
          para={item.notify[0]?.ext_}
        />
      ))}
      {!showAddRegulation ? (
        <ClauseHeader
          title="Regulation"
          buttonText="Regulation"
          onClick={() => {
            handleAddEdit(
              props,
              "Data Breach Regulation",
              regulation,
              setShowAddRegulation
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="Data Breach Regulation"
          savedInsight={!isNullOrUndefined([regulation]) ? regulation : []}
          savedParentClauseDataPoint={[regulation]}
          editOptionSelected={(selected: boolean) => {
            editOptionSelected(selected);
          }}
          childInEditId={childInEditId}
          onClose={() => {
            setShowAddRegulation(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="data_breach"
        />
      )}
      {regulation?.extraction?.length && (
        <ClauseCard
          onClick={() => {
            setActiveClause({ type: "regulation", index: 0 });
            onClickHighlight(0, regulation, "regulation");
          }}
          index={0}
          clauseItem={regulation?.extraction[0]}
          sentenceData={props?.sentenceData}
          isActive={
            activeClause?.type === "regulation" && activeClause?.index === 0
          }
          deleteClause={() => deleteRegulation()}
          para={regulation?.extraction[0]?.ext}
        />
      )}
    </>
  );
}
