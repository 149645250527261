import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface IProps extends DialogProps {
  title: string;
  handleClose: VoidFunction;
}
const CustomModal: React.FC<IProps> = ({
  open,
  handleClose,
  title,
  children,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      {...props}
    >
      <DialogTitle
        id="scroll-dialog-title"
        className="modal-header"
        sx={{
          borderRadius: props.fullScreen ? 0 : "4px 4px 0 0",
          paddingY: "4px",
        }}
      >
        <Typography justifyItems="center" ml={2}>
          {title}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
