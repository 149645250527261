import React, { useEffect, useRef, useState } from "react";

import { Dialog, Link, Stack, Typography } from "@mui/material";
import WebViewer from "@pdftron/webviewer";
import { useParams } from "react-router-dom";

interface IProps {
  fileLink: string;
  setInstance: any;
  dragOver: any;
  setAddedSignatureFields?: any;
  drop: any;
  draftDetails: any;
}

const DocxEditingComponent = (props: IProps) => {
  const documentContainer = useRef<HTMLDivElement>(null);
  const {
    fileLink,
    setInstance,
    dragOver,
    setAddedSignatureFields,
    drop,
    draftDetails,
  } = props;

  const { id, version } = useParams<{
    id: string;
    version: string;
  }>();

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);

  useEffect(() => {
    if (fileLink && documentContainer.current) {
      WebViewer.WebComponent(
        {
          path: "/webviewer",
          fullAPI: true,
          enableOfficeEditing: true,
          initialDoc: fileLink,
          css: "/styles/webviewer.css",
          disabledElements: ["header", "toolsHeader"],
          licenseKey: process.env.REACT_APP_RIVERUS_EDIT_LICENSE_KEY,
        },
        documentContainer.current
      )
        .then((instance) => {
          const { UI, Core } = instance;
          setInstance(instance);
          const { documentViewer, Annotations, annotationManager } = Core;
          const { hotkeys } = UI;
          hotkeys.on(hotkeys.Keys.CTRL_V);

          instance.UI.annotationPopup.update([{ type: "ac" }]);

          setOpenErrorModal(true);

          documentViewer.addEventListener("documentLoaded", async () => {
            var FitMode = instance.UI.FitMode;
            instance.UI.setFitMode(FitMode.FitWidth);
          });

          const doc = documentContainer.current;
          if (doc) {
            doc.addEventListener("dragover", dragOver);
            doc.addEventListener("drop", (e: any) => {
              drop(e, documentViewer);
            });
          }
          Annotations.setCustomCreateSignHereElementHandler(function (
            tool,
            { annotation, originalCreateSignHereElement }
          ) {
            const signHereElement = originalCreateSignHereElement(tool);
            signHereElement.innerHTML = "Click Here To Sign";
            return signHereElement;
          });
          annotationManager.addEventListener(
            "annotationChanged",
            (annot, action, info) => {
              annot.forEach((annot: any) => {
                if (annot.Subject.toLowerCase() === "signature") {
                  annot.NoMove = true;
                  annot.NoResize = true;
                  annot.NoRotate = true;
                  setAddedSignatureFields?.(true);
                }
              });
            }
          );
          if (!draftDetails?.open_collab) {
            UI.disableElements(["textPopup"]);
          }
        })
        .catch((error) => {
          if (error.message.includes("403")) {
            setOpenErrorModal(true);
          } else {
            console.error("WebViewer initialization error:", error);
          }
        });
    }
  }, [
    fileLink,
    documentContainer,
    setInstance,
    dragOver,
    draftDetails,
    drop,
    setAddedSignatureFields,
  ]);

  return (
    <React.Fragment>
      <div
        ref={documentContainer}
        id="document-editing"
        style={{ height: "82vh" }}
      />
      <Dialog
        open={openErrorModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpenErrorModal(false);
          }
        }}
      >
        <Stack width="100%" padding={2} spacing={2} alignItems="center">
          <Typography>
            Please edit the document in Riverus Ally (Riverus MS Word add-in) or
            contact your Riverus support executive to enable this in-app editor.
          </Typography>
          <Link
            href={`/draft/${version}/${id}`}
            sx={{
              padding: "5px 15px",
              background: "#6D264C",
              color: "white",
              borderRadius: "5px",
            }}
          >
            Go back
          </Link>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default DocxEditingComponent;
