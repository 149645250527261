import { Chip, ChipProps, SvgIconProps } from "@mui/material";

import React from "react";

interface IProps extends ChipProps {
  icon?: React.ReactElement<SvgIconProps>;
  label: string;
}

const CustomChip: React.FC<IProps> = ({ icon, label, ...props }) => {
  return (
    <Chip
      sx={{
        background: "#FFECF1",
        padding: "15px 10px",
        borderRadius: "5px",
        color: "#6D264C",
      }}
      icon={icon}
      label={label}
      {...props}
    />
  );
};

export default CustomChip;
