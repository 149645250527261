import axios from "axios";
import { getLocalStorage } from "../Authentication/Actions/authentication";
import { PresignedDataField } from "../Draft/State/DraftState";
import { authApi } from "./authApi";

const baseUrl = process.env.REACT_APP_RIVERUS_CLM_API;

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

let axiosConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
};

const APIPaths = {
  groupUrl: baseUrl + "users/groups/",
  presignedUrl: baseUrl + "contracts/aws/presigned-url/",
  projectUrl: baseUrl + "umbrella/projects/",
  baseContractUrl: baseUrl + "contracts/",
  reminderUrl: baseUrl + "reminder/",
};

export const fetchContractById = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.baseContractUrl}${id}/`,
    APIConfig()
  );
  return response.json();
};

export const fetchDownloadUrl = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.baseContractUrl}download/${id}/`,
    APIConfig()
  );
  return response.json();
};

export const fetchGroups = async () => {
  const res = await fetch(`${APIPaths.groupUrl}`, APIConfig());
  return res.json();
};

export const fetchReminder = async (id: string) => {
  const response = await fetch(
    `${APIPaths.reminderUrl}?contract=${id}`,
    APIConfig()
  );
  return response.json();
};

export const uploadDoc = async (payload: any) => {
  return axios.post(APIPaths?.presignedUrl, payload, APIConfig());
};

export const createReminder = async (payload: any) => {
  return axios.post(APIPaths?.reminderUrl, payload, APIConfig());
};

export const upload_file_in_s3_bucket = ({
  presignedPostData,
  file,
  onHandleFileProgress,
}: {
  presignedPostData: any;
  file?: any;
  onHandleFileProgress?: any;
}) => {
  const { fields, url } = presignedPostData;
  const formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key as keyof PresignedDataField]);
  });

  file && formData.append("file", file);

  let config = onHandleFileProgress
    ? { axiosConfig, ...onHandleFileProgress }
    : axiosConfig;

  return axios.post(url, formData, config);
};

export const deleteContractDoc = (id: string) => {
  return axios.delete(`${APIPaths?.baseContractUrl}${id}/`, APIConfig());
};

export const deleteReminder = (id: string) => {
  return axios.delete(`${APIPaths?.reminderUrl}${id}/`, APIConfig());
};

export const bulkDeleteDocs = (ids: any) => {
  let data = { id: ids };
  return axios.delete(`${APIPaths?.baseContractUrl}bulk-delete/`, {
    ...APIConfig(),
    data,
  });
};

export const fetchHeaderCounts = async () => {
  const response = await fetch(
    `${APIPaths?.baseContractUrl}statistics/?type=documentlibrary`,
    APIConfig()
  );
  return response.json();
};

export const fetchProjects = async () => {
  const response = await fetch(APIPaths?.projectUrl, APIConfig());
  return response.json();
};

export const fetchDocumentLibrary = (payload: any) => {
  return axios.post(
    `${APIPaths?.baseContractUrl}document-library/&page=${payload?.pageNumber}&${payload?.params}`,
    payload?.body,
    APIConfig()
  );
};

export const editReplaceTags = (payload: any) => {
  return axios.patch(`${APIPaths?.baseContractUrl}tag/`, payload, APIConfig());
};

export const editReminder = (payload: any) => {
  const { id, body } = payload;
  return axios.patch(`${APIPaths?.reminderUrl}${id}/`, body, APIConfig());
};

export const editContract = (payload: any) => {
  const { id, body } = payload;
  return axios.patch(`${APIPaths?.baseContractUrl}${id}/`, body, APIConfig());
};

export const addProject = (payload: any) => {
  return axios.post(APIPaths?.projectUrl, payload, APIConfig());
};

export const fetchAllContracts = async () => {
  const response = await fetch(
    `${APIPaths?.baseContractUrl}document-library/`,
    APIConfig()
  );
  return response.json();
};

export const fetchDocLibContracts = async (params: string) => {
  const response = await fetch(
    `${APIPaths?.baseContractUrl}document-library/${params}`,
    APIConfig()
  );
  return response.json();
};

export const fetchContractingParty = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.baseContractUrl}insights/${id}/contracting_parties/`,
    APIConfig()
  );
  return response.json();
};

export const updateContractingParty = async (payload: any) => {
  const { id, body } = payload;
  return axios.patch(
    `${APIPaths?.baseContractUrl}insights/${id}/contracting_parties/`,
    body,
    APIConfig()
  );
};

export const getAllFilterInsights = async (
  contract_id: string,
  user_id: string
) => {
  const response = await fetch(
    `${APIPaths?.baseContractUrl}kdp-user-status/?contract=${contract_id}&user=${user_id}`,
    APIConfig()
  );
  return response.json();
};

export const filterInsights = async (payload: any) => {
  const { contract_id, user_id, body } = payload;
  return axios.post(
    `${APIPaths?.baseContractUrl}kdp-user-status/?contract=${contract_id}&user=${user_id}`,
    body,
    APIConfig()
  );
};

export const updatedFilterInsights = async (payload: any) => {
  const { id, body } = payload;
  return axios.patch(
    `${APIPaths?.baseContractUrl}kdp-user-status/${id}/`,
    body,
    APIConfig()
  );
};

export const getTags = async (type: string): Promise<any> => {
  const response = await fetch(
    `${APIPaths?.baseContractUrl}event/?type=${type}`,
    APIConfig()
  );
  return response.json();
};
 
export const AddTag = async (payload: any) => {
  return await axios.post(`${APIPaths?.baseContractUrl}event/`, payload, APIConfig());
};

