import { Box, Stack, Typography } from "@mui/material";
import * as changesets from "json-diff-ts";
import React from "react";
import { useForm } from "react-hook-form";
import RadioButtonGroup from "../../../../../RiverusUI/Components/RadioButtonGroup";
import { ClauseType } from "../../../../Constants/ClauseType";
import {
  LinkSentenceRequest,
  SentencesData,
  editedSentences,
  sentenceInfo,
  tableInfo,
} from "../../../../State/documentState";
import {
  getClauseDataFormat,
  getClauseObjects,
} from "../../../ClauseComponent/utils/ClauseTypeUtils";
import {
  getSentencesFromChild,
  getTableCellsFromChild,
} from "../../../Utils/docUtils";
import SaveAndCancelSentence from "../SaveAndCancelSentence";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresentSentences: (presentValue: LinkSentenceRequest) => void;
  dataPointName: string;
  highlightedId: number[] | null;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedPresentData: any;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  otherClauses: (type: string) => void;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  clauseItem: any;
  onClose: any;
  clauseData: any;
}

const assignmentInfoBiMap: any = {
  "Subletting/Assignment Notice Info": "Notice Required",
  "Subletting/Assignment Consent Info": "Consent Required",
  "Exception to Confidentiality": "",
};

export const assignmentInfoBiValue: any = {
  "Subletting/Assignment Notice Info": "notice",
  "Subletting/Assignment Consent Info": "consent",
  "Exception to Confidentiality": "c_except",
};

const EditAddAssignmentInfo: React.FC<Props> = (props) => {
  const { control, watch } = useForm({
    defaultValues: {
      isPresent: "Yes",
    },
  });

  const clauseOptions = [
    {
      value: "Yes",
      title: `${assignmentInfoBiMap[props.dataPointName]} is present`,
    },
    {
      value: "No",
      title: `${assignmentInfoBiMap[props.dataPointName]} is not present`,
    },
  ];

  const isPresent = watch("isPresent") || "";

  const getAddedAndDeletedSentences = (
    changedLinkedSentences: sentenceInfo[] | null,
    previousLinkedSentences: sentenceInfo[]
  ) => {
    let addedSentences: sentenceInfo[] = [];
    let deletedSentences: sentenceInfo[] = [];

    if (isPresent === "No") {
      changedLinkedSentences = [];
    }

    if (previousLinkedSentences.length !== 0) {
      if (changedLinkedSentences !== null) {
        if (changedLinkedSentences.length !== 0) {
          //get newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            let addedExists = false;
            for (let j = 0; j < previousLinkedSentences.length; j++) {
              if (
                changedLinkedSentences[i].paraId ===
                previousLinkedSentences[j].paraId
              ) {
                if (
                  changedLinkedSentences[i].sentenceId ===
                  previousLinkedSentences[j].sentenceId
                ) {
                  addedExists = true;
                  break;
                }
              }
            }
            if (addedExists === false) {
              addedSentences.push({
                paraId: changedLinkedSentences[i].paraId,
                sentenceId: changedLinkedSentences[i].sentenceId,
                rowId: -1,
                columnId: -1,
                requirement: isPresent === "Yes" ? true : false,
              });
            }
          }

          //get deleted elements
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            let deletedExists = false;
            for (let j = 0; j < changedLinkedSentences.length; j++) {
              if (
                previousLinkedSentences[i].paraId ===
                changedLinkedSentences[j].paraId
              ) {
                if (
                  previousLinkedSentences[i].sentenceId ===
                  changedLinkedSentences[j].sentenceId
                ) {
                  deletedExists = true;
                  break;
                }
              }
            }
            if (deletedExists === false) {
              deletedSentences.push({
                paraId: previousLinkedSentences[i].paraId,
                sentenceId: previousLinkedSentences[i].sentenceId,
                rowId: previousLinkedSentences[i].rowId,
                columnId: previousLinkedSentences[i].columnId,
                requirement: isPresent === "Yes" ? true : false,
              });
            }
          }
        } else if (changedLinkedSentences.length === 0) {
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            deletedSentences.push({
              paraId: previousLinkedSentences[i].paraId,
              sentenceId: previousLinkedSentences[i].sentenceId,
              rowId: previousLinkedSentences[i].rowId,
              columnId: previousLinkedSentences[i].columnId,
              requirement: isPresent === "Yes" ? true : false,
            });
          }
        }
      }
    } else {
      if (changedLinkedSentences !== null) {
        if (changedLinkedSentences.length !== 0) {
          //adding for first time, newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            addedSentences.push(changedLinkedSentences[i]);
            addedSentences[i].requirement = isPresent === "Yes" ? true : false;
          }
        }
      }
    }

    let addedDeletedSentences: editedSentences = {
      upsert: addedSentences,
      deleted: deletedSentences,
    };

    return addedDeletedSentences;
  };

  const getAddedAndDeletedTableCells = (
    changedLinkedTableCells: tableInfo[] | null,
    previousLinkedTableCells: tableInfo[]
  ) => {
    let addedTableCells: tableInfo[] = [];
    let deletedTableCells: tableInfo[] = [];
    if (
      previousLinkedTableCells.length !== 0 &&
      previousLinkedTableCells !== null
    ) {
      if (
        changedLinkedTableCells !== null &&
        changedLinkedTableCells.length !== 0
      ) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId ===
                previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId ===
                previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId ===
                previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push({
              paraId: changedLinkedTableCells[i].paraId,
              rowId: changedLinkedTableCells[i].rowId,
              columnId: changedLinkedTableCells[i].columnId,
            });
          }
        }

        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId ===
                changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId ===
                changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId ===
                changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (deletedCellExists === false) {
            deletedTableCells.push({
              paraId: previousLinkedTableCells[i].paraId,
              rowId: previousLinkedTableCells[i].rowId,
              columnId: previousLinkedTableCells[i].columnId,
            });
          }
        }
      } else {
        //all deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else if (
      changedLinkedTableCells !== null &&
      changedLinkedTableCells.length !== 0
    ) {
      //newly added
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        addedTableCells.push(changedLinkedTableCells[i]);
      }
    }

    //Convert table cells json to sentence level json
    let upsertSentences: sentenceInfo[] = [];
    let deletedSentences: sentenceInfo[] = [];

    for (let i = 0; i < addedTableCells.length; i++) {
      upsertSentences.push({
        paraId: addedTableCells[i].paraId,
        sentenceId: -1,
        rowId: addedTableCells[i].rowId,
        columnId: addedTableCells[i].columnId,
      });
    }

    for (let i = 0; i < deletedTableCells.length; i++) {
      deletedSentences.push({
        paraId: deletedTableCells[i].paraId,
        sentenceId: -1,
        rowId: deletedTableCells[i].rowId,
        columnId: deletedTableCells[i].columnId,
      });
    }

    let editedTableCellsAsSentence: editedSentences = {
      upsert: upsertSentences,
      deleted: deletedSentences,
    };
    return editedTableCellsAsSentence;
  };

  const mergeEditedSentences = (
    firstEditedSentences: editedSentences,
    secondEditedSentences: editedSentences
  ) => {
    let mergedAddedSentences: sentenceInfo[] =
      firstEditedSentences.upsert.concat(secondEditedSentences.upsert);
    let mergedDeletedSentences: sentenceInfo[] =
      firstEditedSentences.deleted.concat(secondEditedSentences.deleted);

    let mergedEditedSentences: editedSentences = {
      upsert: mergedAddedSentences,
      deleted: mergedDeletedSentences,
      bi: assignmentInfoBiValue[props.dataPointName],
    };
    return mergedEditedSentences;
  };

  const addOrRemovePresent = (action: string): LinkSentenceRequest => {
    let {
      savedHighlightedSentences,
      savedHighlightedTableCells,
      savedPresentData,
    } = props;
    let tempPresentList: LinkSentenceRequest = { data: "", mode: "" };

    //edited sentences
    //let previousLinkedSentences: sentenceInfo[] = [];
    let previousLinkedSentences = getSentencesFromChild(savedPresentData);
    let editedSentencesObject = getAddedAndDeletedSentences(
      savedHighlightedSentences,
      previousLinkedSentences
    );

    //edited table cells
    let previousLinkedTableCells: tableInfo[] = [];
    let editedTableCellsObject = getAddedAndDeletedTableCells(
      savedHighlightedTableCells,
      previousLinkedTableCells
    );

    //merge both
    let mergeEditedSentence = mergeEditedSentences(
      editedTableCellsObject,
      editedSentencesObject
    );

    if (action === "add") {
      if (
        savedHighlightedSentences !== null ||
        savedHighlightedTableCells !== null ||
        isPresent === "No"
      ) {
        tempPresentList = {
          mode: "manual",
          editedSentences: mergeEditedSentence,
        };
      } else {
        tempPresentList = {
          mode: "retain",
        };
      }
    }
    return tempPresentList;
  };

  const editPresent = () => {
    let {
      clauseType,
      sentenceData,
      clauseDataByType,
      fileId,
      updatedClauseDataByType,
      postClauseDataByType,
      parentClauseType,
    } = props;

    let addSentenceRequest = addOrRemovePresent("add");

    let updatedData = updatedClauseDataByType;
    let newData = updatedData;

    if (isPresent === "Yes") {
      if (addSentenceRequest?.editedSentences?.upsert) {
        let addedData = addSentenceRequest.editedSentences.upsert;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
        }
      }
      if (addSentenceRequest?.editedSentences?.deleted) {
        let deletedData = addSentenceRequest.editedSentences.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            "remove",
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(clauseType as ClauseType, newData);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
  };

  const saveHighlightedDataPointCallback = () => {
    props.saveHighlightedDataPoint(props.dataPointName);
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600}>
        Add / Edit {props.dataPointName} Clause
      </Typography>
      <Stack direction="column" className="edit-clause-select" spacing={2}>
        <RadioButtonGroup
          row
          required
          name="isPresent"
          options={clauseOptions}
          valueKey="value"
          control={control}
        />
        <Stack width="100%">
          <Typography variant="body2" fontWeight={700}>
            How to add a sentence to {props?.dataPointName} clause?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Select "{props?.dataPointName} clause Is Present"
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on "Edit Linked Sentences(s)" button.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            4). Click on the desired text or the link icon on the box around the
            text that you want to add as {props?.dataPointName} clause.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            5). Click on Save.
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body2" fontWeight={700}>
            How to remove a sentence from {props?.dataPointName} clause?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Hover over the highlighted text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on the bin icon against the highlighted sentence you want
            to remove from {props?.dataPointName} clause.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Click on Save.
          </Typography>
        </Stack>
        <SaveAndCancelSentence
          enableHighlightOption={isPresent === "Yes"}
          dataPointName={props.dataPointName}
          editOptionSelected={props.editOptionSelected}
          editDataPoint={() => {
            editPresent();
            props.saveHighlightedSentences(null);
            props.saveHighlightedTableCells(null);
            props.editOptionSelected(false);
            props.onClose();
          }}
          savedHighlightedSentences={props.savedHighlightedSentences}
          saveHighlightedDataPoint={saveHighlightedDataPointCallback}
          enableSaveBtn={true}
          saveHighlightedSentences={(
            savedHighlightedSentences: sentenceInfo[] | null
          ) => props.saveHighlightedSentences(savedHighlightedSentences)}
          savedHighlightedTableCells={props.savedHighlightedTableCells}
          saveHighlightedTableCells={(
            savedHighlightedTableCells: tableInfo[] | null
          ) => props.saveHighlightedTableCells(savedHighlightedTableCells)}
          previouslyLinkedSentences={
            getSentencesFromChild(props.savedPresentData).length > 0
              ? getSentencesFromChild(props.savedPresentData)
              : undefined
          }
          previouslyLinkedTableCells={
            getTableCellsFromChild(props.savedPresentData).length > 0
              ? getTableCellsFromChild(props.savedPresentData)
              : undefined
          }
          onClose={props.onClose}
          otherClauses={(type: string) => props.otherClauses(type)}
        />
      </Stack>
    </Box>
  );
};

export default EditAddAssignmentInfo;
