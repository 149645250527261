import React, { useState } from "react";
import { isNullOrUndefined } from "is-what";
import {
  LinkSentenceRequest,
  editedSentences,
  sentenceInfo,
  tableInfo,
} from "../../../../State/documentState";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  sentenceBiMap,
  sentenceLevelComponents,
} from "../../../Utils/docUtils";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresentSentences: (presentValue: LinkSentenceRequest) => void;
  savedPresent: string;
  dataPointName: string;
  savedPresentData: any;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  onClose: any;
}

const EditLinkPresentSentence: React.FC<Props> = (props) => {
  const [highlight, setHighlight] = useState(false);

  const getAddedAndDeletedSentences = (
    changedLinkedSentences: sentenceInfo[] | null,
    previousLinkedSentences: sentenceInfo[]
  ) => {
    let addedSentences: sentenceInfo[] = [];
    let deletedSentences: sentenceInfo[] = [];

    if (previousLinkedSentences.length !== 0) {
      if (changedLinkedSentences !== null) {
        if (changedLinkedSentences.length !== 0) {
          //get newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            let addedExists = false;
            for (let j = 0; j < previousLinkedSentences.length; j++) {
              if (
                changedLinkedSentences[i].paraId ===
                previousLinkedSentences[j].paraId
              ) {
                if (
                  changedLinkedSentences[i].sentenceId ===
                  previousLinkedSentences[j].sentenceId
                ) {
                  addedExists = true;
                  break;
                }
              }
            }
            if (addedExists === false) {
              addedSentences.push({
                paraId: changedLinkedSentences[i].paraId,
                sentenceId: changedLinkedSentences[i].sentenceId,
                rowId: -1,
                columnId: -1,
              });
            }
          }

          //get deleted elements
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            let deletedExists = false;
            for (let j = 0; j < changedLinkedSentences.length; j++) {
              if (
                previousLinkedSentences[i].paraId ===
                changedLinkedSentences[j].paraId
              ) {
                if (
                  previousLinkedSentences[i].sentenceId ===
                  changedLinkedSentences[j].sentenceId
                ) {
                  deletedExists = true;
                  break;
                }
              }
            }
            if (deletedExists === false) {
              deletedSentences.push({
                paraId: previousLinkedSentences[i].paraId,
                sentenceId: previousLinkedSentences[i].sentenceId,
                rowId: previousLinkedSentences[i].rowId,
                columnId: previousLinkedSentences[i].columnId,
              });
            }
          }
        } else if (changedLinkedSentences.length === 0) {
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            deletedSentences.push({
              paraId: previousLinkedSentences[i].paraId,
              sentenceId: previousLinkedSentences[i].sentenceId,
              rowId: previousLinkedSentences[i].rowId,
              columnId: previousLinkedSentences[i].columnId,
            });
          }
        }
      }
    } else {
      if (changedLinkedSentences !== null) {
        if (changedLinkedSentences.length !== 0) {
          //adding for first time, newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            addedSentences.push({
              paraId: changedLinkedSentences[i].paraId,
              sentenceId: changedLinkedSentences[i].sentenceId,
              rowId: -1,
              columnId: -1,
            });
          }
        }
      }
    }

    let addedDeletedSentences: editedSentences = {
      upsert: addedSentences,
      deleted: deletedSentences,
    };

    return addedDeletedSentences;
  };

  const getAddedAndDeletedTableCells = (
    changedLinkedTableCells: tableInfo[] | null,
    previousLinkedTableCells: tableInfo[]
  ) => {
    let addedTableCells: tableInfo[] = [];
    let deletedTableCells: tableInfo[] = [];
    if (
      previousLinkedTableCells.length !== 0 &&
      previousLinkedTableCells !== null
    ) {
      if (
        changedLinkedTableCells !== null &&
        changedLinkedTableCells.length !== 0
      ) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId ===
                previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId ===
                previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId ===
                previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push({
              paraId: changedLinkedTableCells[i].paraId,
              rowId: changedLinkedTableCells[i].rowId,
              columnId: changedLinkedTableCells[i].columnId,
            });
          }
        }

        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId ===
                changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId ===
                changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId ===
                changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (deletedCellExists === false) {
            deletedTableCells.push({
              paraId: previousLinkedTableCells[i].paraId,
              rowId: previousLinkedTableCells[i].rowId,
              columnId: previousLinkedTableCells[i].columnId,
            });
          }
        }
      } else {
        //all deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else if (
      changedLinkedTableCells !== null &&
      changedLinkedTableCells.length !== 0
    ) {
      //newly added
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        addedTableCells.push({
          paraId: changedLinkedTableCells[i].paraId,
          rowId: changedLinkedTableCells[i].rowId,
          columnId: changedLinkedTableCells[i].columnId,
        });
      }
    }

    //Convert table cells json to sentence level json
    let upsertSentences: sentenceInfo[] = [];
    let deletedSentences: sentenceInfo[] = [];

    for (let i = 0; i < addedTableCells.length; i++) {
      upsertSentences.push({
        paraId: addedTableCells[i].paraId,
        sentenceId: -1,
        rowId: addedTableCells[i].rowId,
        columnId: addedTableCells[i].columnId,
      });
    }

    for (let i = 0; i < deletedTableCells.length; i++) {
      deletedSentences.push({
        paraId: deletedTableCells[i].paraId,
        sentenceId: -1,
        rowId: deletedTableCells[i].rowId,
        columnId: deletedTableCells[i].columnId,
      });
    }

    let editedTableCellsAsSentence: editedSentences = {
      upsert: upsertSentences,
      deleted: deletedSentences,
    };
    return editedTableCellsAsSentence;
  };

  const mergeEditedSentences = (
    firstEditedSentences: editedSentences,
    secondEditedSentences: editedSentences
  ) => {
    let mergedAddedSentences: sentenceInfo[] =
      firstEditedSentences.upsert.concat(secondEditedSentences.upsert);
    let mergedDeletedSentences: sentenceInfo[] =
      firstEditedSentences.deleted.concat(secondEditedSentences.deleted);

    let mergedEditedSentences: editedSentences = {
      upsert: mergedAddedSentences,
      deleted: mergedDeletedSentences,
      bi: sentenceBiMap[props.dataPointName],
    };
    return mergedEditedSentences;
  };

  const addOrRemovePresent = (action: string): LinkSentenceRequest => {
    let { savedHighlightedSentences, savedHighlightedTableCells } = props;
    let tempPresentList: LinkSentenceRequest = { data: "", mode: "" };

    //edited sentences
    let previousLinkedSentences: sentenceInfo[] = [];
    let editedSentencesObject = getAddedAndDeletedSentences(
      savedHighlightedSentences,
      previousLinkedSentences
    );

    //edited table cells
    let previousLinkedTableCells: tableInfo[] = [];
    let editedTableCellsObject = getAddedAndDeletedTableCells(
      savedHighlightedTableCells,
      previousLinkedTableCells
    );

    //merge both
    let mergeEditedSentence = mergeEditedSentences(
      editedTableCellsObject,
      editedSentencesObject
    );

    if (action === "add") {
      if (
        savedHighlightedSentences !== null ||
        savedHighlightedTableCells !== null
      ) {
        tempPresentList = {
          data: "Yes",
          mode: "manual",
          editedSentences: mergeEditedSentence,
        };
      } else {
        tempPresentList = {
          data: "No",
          mode: "retain",
        };
      }
    }
    return tempPresentList;
  };

  const linkToSentences = () => {
    props.editOptionSelected(true);
    props.saveHighlightedDataPoint(props.dataPointName);
    props.saveHighlightedSentences(null);
    props.saveHighlightedTableCells(null);
    setHighlight(true);
  };

  const showLinkToSentence = () => {
    let { savedHighlightedTableCells, savedHighlightedSentences } = props;
    let sentenceLength: number =
      savedHighlightedSentences !== null && savedHighlightedSentences.length > 0
        ? savedHighlightedSentences.length
        : 0;
    let tableLength: number =
      savedHighlightedTableCells !== null &&
      savedHighlightedTableCells.length > 0
        ? savedHighlightedTableCells.length
        : 0;
    let sentencesLength = sentenceLength + tableLength;

    if (
      ((savedHighlightedSentences !== null &&
        savedHighlightedSentences !== undefined &&
        savedHighlightedSentences.length > 0) ||
        (savedHighlightedTableCells !== null &&
          savedHighlightedTableCells !== undefined &&
          savedHighlightedTableCells.length > 0)) &&
      highlight === true
    ) {
      return (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography fontSize="14px">Edit linked sentence(s)</Typography>
          <Typography fontSize="14px">{sentencesLength}</Typography>
        </Stack>
      );
    } else {
      if (highlight === true) {
        return (
          <Stack width="100%">
            <Typography fontSize="14px">Link to sentences(s)</Typography>
            <Typography fontSize="14px">
              Please highlight the sentences on the document, you wish to link
              to this data point and click on save.
            </Typography>
          </Stack>
        );
      } else {
        return (
          <Button variant="text" onClick={linkToSentences}>
            Link to sentences's
          </Button>
        );
      }
    }
  };

  const cancel = () => {
    props.onClose();
    setHighlight(false);
    props.editOptionSelected(false);
    props.saveHighlightedSentences(null);
    props.saveHighlightedTableCells(null);
  };

  const onSave = () => {
    let addSentenceRequest = addOrRemovePresent("add");
    props.editPresentSentences(addSentenceRequest);
    setHighlight(false);
    props.editOptionSelected(false);
    props.saveHighlightedSentences(null);
    props.saveHighlightedTableCells(null);
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add / Edit {props.dataPointName}
      </Typography>
      <Stack spacing={2}>
        {sentenceLevelComponents.indexOf(props.dataPointName) > -1 &&
          showLinkToSentence()}
        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            disabled={
              (props.savedHighlightedSentences === null &&
                !props.savedHighlightedSentences) ||
              (props.savedHighlightedTableCells === null &&
                !props.savedHighlightedTableCells)
            }
          >
            Save
          </Button>
          <Button variant="outlined" onClick={cancel} startIcon={<CloseIcon />}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditLinkPresentSentence;
