import * as React from "react";
import * as changesets from "json-diff-ts";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";
import { useState } from "react";

export default function GoverningClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    onClickDataPoint,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddJurisdiction, setShowAddJurisdiction] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  React.useEffect(() => {
    hasData && getClauseDataByType(fileId, "governing_law");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.governingLaw && clauseData.governingLaw,
    [clauseData?.governingLaw]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.governingLaw || {},
    [updatedClauseData]
  );

  const governingLaw = React.useMemo(() => {
    if (updates?.governing_law) {
      let sortData = updates.governing_law.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const jurisdiction = React.useMemo(() => {
    if (updates?.jurisdiction) {
      let sortData = updates.jurisdiction.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let governing_law = governingLaw?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let jurisdictionData = jurisdiction?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let updatedData = {
        ...updates,
        governing_law,
        jurisdiction: jurisdictionData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "governing_law", diff, updatedData);
      }
    },
    [
      governingLaw,
      jurisdiction,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteJurisdiction = React.useCallback(
    (item: any) => {
      let newJurisdiction = jurisdiction?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        jurisdiction: newJurisdiction,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "governing_law", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, jurisdiction, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="governing law"
          onClick={() => {
            handleAddEdit(props, "governing_law", governingLaw, setShowAdd);
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="governing_law"
          savedInsight={!isNullOrUndefined(governingLaw) ? governingLaw : []}
          savedParentClauseDataPoint={governingLaw}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          governingLaw
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      <Scrollable maxHeight={200}>
        {governingLaw?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "governing_law", index });
              props.onClickHighlight(index, item, "governing_law");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === "governing_law" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>

      {!showAddJurisdiction ? (
        <ClauseHeader
          title="Jurisdiction"
          buttonText="jurisdiction"
          onClick={() => {
            handleAddEdit(
              props,
              "Jurisdiction",
              jurisdiction,
              setShowAddJurisdiction
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Jurisdiction"
          savedInsight={!isNullOrUndefined(jurisdiction) ? jurisdiction : []}
          savedParentClauseDataPoint={jurisdiction}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddJurisdiction(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="governing_law"
        />
      )}
      <Scrollable maxHeight={200}>
        {jurisdiction?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: "Jurisdiction", index });
              props.onClickHighlight(index, item, "Jurisdiction");
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === "Jurisdiction" &&
              activeClause?.index === index
            }
            deleteClause={() => deleteJurisdiction(item)}
            para={item.clean_jurisdiction}
          />
        ))}
      </Scrollable>
    </>
  );
}
