import {
  Search,
  SEARCH,
  SEARCH_WITH_FILTER,
  SEARCHCOUNT,
  SearchCount,
  SearchWithFilter,
} from "../Actions/SearchDef";
import SearchAPI from "../Actions/SearchAPI";
import { SearchResult } from "../State/SearchState";
import SearchActionGen from "../Actions/SearchGen";
import { call, put, all, takeLatest } from "@redux-saga/core/effects";
import { SITE_API_BY_REALM_NAME } from "../../Configuration/global";
import AggregatesActionGenerator from "../../Aggregates/Actions/AggregatesGen";
import { getKeyCloakRealmFromLS } from "../../Authentication/Actions/authentication";
import { ResponseGenerator } from "../../Types";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
export function* search(action: Search) {
  try {
    let url = SITEAPI + "search/";
    let searchTerm = atob(action.payload.searchTerm);
    let sort = action.payload.sortBy;
    let pageSize = action.payload.pageSize;
    let filterType = action.payload.filterType;
    let payload = { search_term: searchTerm };
    let response: ResponseGenerator = yield call(
      SearchAPI.search,
      url,
      payload
    );
    switch (response.status) {
      case 200: {
        let parsed = response.data.results as SearchResult[];
        yield put(SearchActionGen.searchSuccess(parsed));
        yield put(
          AggregatesActionGenerator.searchClauses(
            response.data.results,
            searchTerm
          )
        );
        break;
      }
      default: {
        yield put(SearchActionGen.searchFailure());
      }
    }
  } catch (e) {
    yield put(SearchActionGen.searchFailure());
  }
}

export function* searchCount(action: SearchCount) {
  try {
    let url = SITEAPI + "search/count/";
    let filters = action.payload.filters;
    let sort = action.payload.sortBy;
    let filterType = action.payload.filterType;

    let response: ResponseGenerator = yield call(SearchAPI.count, url, {
      filters,
      filterType,
    });
    switch (response.status) {
      case 200: {
        let count = response.data.count;
        let uniqueFileIds = response.data.uniqueFileIds;
        yield put(SearchActionGen.searchCountSuccess(count, uniqueFileIds));
        yield put(
          AggregatesActionGenerator.getFilterConfig(
            uniqueFileIds,
            filters,
            filterType
          )
        );
        break;
      }
      default: {
        yield put(SearchActionGen.searchCountFailure());
      }
    }
  } catch (e) {
    yield put(SearchActionGen.searchCountFailure());
  }
}

function* searchWithFilter(action: SearchWithFilter) {
  try {
    let url = SITEAPI + "search/";
    let response: ResponseGenerator = yield call(
      SearchAPI.search,
      url,
      action.payload
    );
    if (response.status == 200) {
      let parsed = response.data.results;
      if (parsed.length > 0) {
        yield put(SearchActionGen.searchSuccess(parsed));
      } else {
        yield put(SearchActionGen.searchSuccess([]));
        yield put(AggregatesActionGenerator.saveAppliedFilters([]));
        yield put(AggregatesActionGenerator.addFilterSuccess({}));
      }
    } else yield put(SearchActionGen.searchFailure());
  } catch (error) {
    console.error(error);
    yield put(SearchActionGen.searchFailure());
  }
}

export default function* searchPageWatcher() {
  yield all([
    takeLatest(SEARCH, search),
    takeLatest(SEARCHCOUNT, searchCount),
    takeLatest(SEARCH_WITH_FILTER, searchWithFilter),
  ]);
}
