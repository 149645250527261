import { Avatar, AvatarProps } from "@mui/material";

import React from "react";

interface IProps extends AvatarProps {
  firstName: string;
  lastName: string;
  avatarStyle?: any;
}

const NameAvatar = ({ firstName, lastName, avatarStyle, ...props }: IProps) => {
  return (
    <Avatar
      alt={`${firstName}" "${lastName}`}
      sx={{
        width: 32,
        height: 32,
        fontSize: "12px",
        backgroundColor: "riPrimary.300",
        ...avatarStyle,
      }}
      {...props}
    >
      {firstName?.charAt(0).toUpperCase()}
      {lastName?.charAt(0).toUpperCase()}
    </Avatar>
  );
};

export default NameAvatar;
