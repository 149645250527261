import {
  getKeyCloakRealmFromLS,
  removeKeyCloakRealmOnLS,
} from "../../Authentication/Actions/authentication";
import {
  getLocalStorage,
  setLocalStorage,
  setUserId,
} from "../../Authentication/Actions/authentication";
import { useCallback, useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import BarLoader from "../../UniversalComponents/Loader/barLoader";
import HeimdallActionGen from "../../UniversalComponents/HeimdallChild/Actions/actionGen";
import {
  TextField,
  Stack,
  Box,
  Grid,
  Link,
  Typography,
  Button,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { ReactComponent as RiverusLogo } from "../../RiverusUI/Components/Icons/web-menubar-logo.svg";

const PrivateRoute = ({
  handleKeycloakRealmNameChange,
  children,
  setAdmin,
}) => {
  const { keycloak } = useKeycloak();
  const keycloakRealmName = getKeyCloakRealmFromLS();
  const isLoggedIn = keycloak.authenticated;
  const dispatch = useDispatch();
  const [loadingTime, setLoadingTime] = useState(0);
  const handleKeycloakRedirection = () => {
    if (keycloak && !isLoggedIn) {
      keycloak.login();
    }
  };
  // const handleRealmNameReset = () => {

  // };

  useEffect(() => {
    if (keycloak.authenticated) {
      dispatch(HeimdallActionGen.login());
    }
  }, [dispatch, keycloak.authenticated]);

  const getUserId = useCallback(async () => {
    const options = {
      headers: {
        Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
        // Origin: process.env.REACT_APP_RIVERUS_CLM_APP_HOST,
      },
    };
    // const url_data =
    //   process.env.REACT_APP_RIVERUS_CLM_API + "contracts/useridbytoken/";
    /*const url_data =
      process.env.REACT_APP_RIVERUS_CLM_API + "contracts/userprofile/";*/
    const url_data = process.env.REACT_APP_RIVERUS_CLM_API + "users/profile/";

    axios
      .get(url_data, options)
      .then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }

        if (response.status === 200) {
          setUserId("user_id", response.data.id);
          setLocalStorage("user_profile", response.data);

          if (response.data.roles.includes("admin")) {
            setAdmin(true);
          }
        }
        return response;
        // return response.json();
      })
      .then(function (data) {
        if (data === "success") {
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  }, [setAdmin]);

  useEffect(() => {
    if (isLoggedIn === true) {
      getUserId();
      // notificationWatcher();
      // sagaMiddleWare.run(notificationWatcher);
    } else if (loadingTime === 1) {
      removeKeyCloakRealmOnLS();
      window.location.reload();
    }
  }, [getUserId, isLoggedIn, loadingTime]);

  if (
    isLoggedIn === true &&
    localStorage.getItem("accessToken") !== undefined
  ) {
    return children;
  } else if (isLoggedIn === false) {
    return (
      <RedirectToKeycloak
        keycloakRealmName={keycloakRealmName}
        handleKeycloakRedirection={handleKeycloakRedirection}
        handleKeycloakRealmNameChange={handleKeycloakRealmNameChange}
      />
    );
  } else {
    setTimeout(() => {
      setLoadingTime(1);
    }, 10000);

    return (
      <div className="">
        <BarLoader />
      </div>
    );
  }
};

const RedirectToKeycloak = (props) => {
  // props.handleKeycloakRedirection();
  const [realmName, setRealmName] = useState(props.keycloakRealmName);
  const [isOrgAvailable, setIsOrgAvailable] = useState(true);

  return (
    <Box className="cover-background">
      <Grid container sx={{ height: "100%", padding: "20px 50px" }}>
        <Grid item sm={8} className="image-background" />
        <Grid
          item
          sm={4}
          sx={{ maxWidth: "480px", alignSelf: "center", position: "right" }}
        >
          <Box
            sx={{
              alignContent: "center",
              margin: "0 auto 30px",
              width: "180px",
            }}
          >
            <RiverusLogo />
          </Box>
          <Stack
            sx={{
              marginBottom: 4,
              padding: "32px",
              background: "white",
              borderRadius: "6px",
              boxShadow: "8px 8px 16px 0px rgba(8, 13, 37, 0.25)",
            }}
          >
            {isOrgAvailable && (
              <Stack spacing={1}>
                <KeyboardBackspaceIcon
                  onClick={() => setIsOrgAvailable(false)}
                />
                <Typography sx={{ color: "#2b3c7f" }}>
                  You are logging onto
                </Typography>
                <Typography sx={{ color: "#2b3c7f" }}>
                  {props.keycloakRealmName}
                </Typography>
                <Typography
                  sx={{ textDecoration: "underline", color: "#818181" }}
                  onClick={() => {
                    setIsOrgAvailable(false);
                  }}
                >
                  Change organization?
                </Typography>

                <Button
                  className="org-continue-btn"
                  sx={{ width: "100%", borderRadius: "5px", color: "white" }}
                  onClick={() => {
                    props.handleKeycloakRedirection();
                  }}
                >
                  Continue
                </Button>
              </Stack>
            )}
            {!isOrgAvailable && (
              <Stack spacing={1}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "#2B3C7F", fontWeight: 600 }}
                >
                  Name of Organization
                </Typography>
                <TextField
                  className="org-input mb-5"
                  size="small"
                  value={realmName}
                  onChange={(e) => {
                    setRealmName(e.target.value);
                  }}
                  fullWidth={true}
                  id="outlined-basic"
                  variant="outlined"
                />
                <Button
                  className="org-continue-btn"
                  sx={{ width: "100%", borderRadius: "5px", color: "white" }}
                  onClick={() => {
                    props.handleKeycloakRealmNameChange(realmName);
                    setIsOrgAvailable(true);
                  }}
                >
                  Continue
                </Button>
              </Stack>
            )}
          </Stack>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="center"
            sx={{ color: "#818181", fontSize: "14px" }}
          >
            <Link
              href="https://www.riverus.co"
              target="_blank"
              sx={{ color: "#818181" }}
              className="link"
              rel="noreferrer"
            >
              Home
            </Link>
            <span>|</span>
            <Link
              href="https://www.riverus.co/privacy-policy"
              target="_blank"
              sx={{ color: "#818181" }}
              className="link"
              rel="noreferrer"
            >
              Terms of Service
            </Link>
            <span>|</span>
            <Link
              href="https://www.riverus.co/privacy-policy"
              target="_blank"
              sx={{ color: "#818181" }}
              className="link"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrivateRoute;
