import React, { useState } from "react";
import SaveOrCancel from "../saveOrCancel";
import { Box, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import ControlledTextField from "../../../../../RiverusUI/Components/ControlledTextField";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  editTitle: (dataPointName: string) => void;
  savedTitle: string;
  saveHighlightedId: (highlightedId: number[] | null) => void;
}

const Comment: React.FC<Props> = (props) => {
  const [title, setTitle] = useState(props.savedTitle);

  const { control } = useForm();

  const editTitle = () => {
    title.length > 0 && props.editTitle(title);
  };

  return (
    <Box
      sx={{
        background: "#FFECF1",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 16px",
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Comment
      </Typography>

      <Stack direction="column" spacing={2}>
        <ControlledTextField
          name="comment"
          type="text"
          value={title}
          control={control}
          label="Comment"
          onChange={(e) => setTitle(e.currentTarget.value)}
          fullWidth
        />

        <SaveOrCancel
          enableHighlightOption={true}
          dataPointName={"Title"}
          editOptionSelected={props.editOptionSelected}
          editDataPoint={editTitle}
          highlightedId={null}
          enableSaveBtn={true}
          saveHighlightedId={(highlightedId: number[] | null) =>
            props.saveHighlightedId(highlightedId)
          }
          handleSubmitSelectedUserGroups={() => {}}
        />
      </Stack>
    </Box>
  );
};

export default Comment;
