import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Undo } from "@mui/icons-material";
import colors from "../../../RiverusUI/Theme/colors";
import { deleteSupportDocFromChecklist } from "../../../Services/Draft";
import { deleteSupportDocFromChecklistExternal } from "../../../ExternalUserFlow/Services/Draft";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

interface Props {
  allowedFileTypes: string[];
  label: string;
  control?: Control;
  name: any;
  files: any[];
  setFiles: Dispatch<SetStateAction<any>>;
  fileList: any[];
  deleteUploadedFile?: VoidFunction;
  isExternal?: boolean;
}

const MultipleUploadDoc: React.FC<Props> = ({
  allowedFileTypes,
  label,
  control,
  name,
  files,
  setFiles,
  fileList,
  deleteUploadedFile,
  isExternal,
}) => {
  const [isShowAddAnotherFile, setIsShowAddAnotherFile] =
    useState<boolean>(false);
  const [uploadedFileList, setUploadedFileList] = useState<any[]>([]);
  const [deletedFile, setDeletedFile] = useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleFileUploadChanges = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setIsShowAddAnotherFile(true);
      setFiles(event.target.files);
    }
  };

  const handleDeleteUploadedFile = () => {
    setFiles([]);
    setIsShowAddAnotherFile(false);
  };

  const { mutate: deleteDoc } = useMutation({
    mutationKey: ["delete_support_document"],
    mutationFn: isExternal
      ? deleteSupportDocFromChecklistExternal
      : deleteSupportDocFromChecklist,
    onSuccess: () => {
      if (deleteUploadedFile) {
        deleteUploadedFile();
      }
    },
    onError: () => {
      enqueueSnackbar("Failed to delete the document!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const handleDeleteMultipleUploadedFiles = (id: string) => {
    deleteDoc(id);
  };

  const handleAddAnotherFile = () => {
    setUploadedFileList([...files, ...uploadedFileList]);
    handleDeleteUploadedFile();
  };

  const handleTemporaryDeleteFile = (index: number, file: any) => {
    const newArray = [...uploadedFileList];
    newArray.splice(index, 1);
    setUploadedFileList(newArray);
    setDeletedFile([file]);
  };

  const handlePermanentDeleteFile = (index: number, id: string) => {
    const newDeletedFileArray = [...deletedFile];
    newDeletedFileArray.splice(index, 1);
    setDeletedFile(newDeletedFileArray);
    deleteDoc(id);
  };

  const handleUndoFunctionality = (file: any, index: number) => {
    const newDeletedFileArray = [...deletedFile];
    newDeletedFileArray.splice(index, 1);
    setDeletedFile(newDeletedFileArray);
    setUploadedFileList([file, ...uploadedFileList]);
  };

  useEffect(() => {
    if (fileList) {
      setUploadedFileList(fileList);
    }
  }, [fileList]);

  return (
    <Stack spacing={2} marginBottom="15px" alignItems="start">
      <Controller
        name={name}
        control={control}
        render={() => (
          <TextField
            variant="outlined"
            label={label}
            fullWidth
            disabled
            helperText={`Supported file formats:${allowedFileTypes
              .join(", ")
              .replace(/,([^,]*)$/, " and$1")}`}
            value={files?.[0] ? files?.[0]?.name : ""}
            InputProps={{
              startAdornment: files?.[0] && (
                <InputAdornment position="start">
                  <AttachFileOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {files?.[0] ? (
                    <IconButton onClick={() => handleDeleteUploadedFile()}>
                      <HighlightOffIcon />
                    </IconButton>
                  ) : (
                    <label htmlFor={`upload-doc`}>
                      <input
                        type="file"
                        id={`upload-doc`}
                        style={{ display: "none" }}
                        accept={allowedFileTypes.join(", ")}
                        onChange={(e) => handleFileUploadChanges(e)}
                      />
                      <IconButton component="span">
                        <FileUploadOutlinedIcon />
                      </IconButton>
                    </label>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      {deletedFile?.length > 0 && (
        <>
          {deletedFile?.map((fileData, deleteFileIndex) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontSize="15px" fontWeight={700}>
                {fileData?.name}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  size={"small"}
                  style={{ color: colors.riNeutralVariant[700] }}
                  onClick={() =>
                    handleUndoFunctionality(fileData, deleteFileIndex)
                  }
                >
                  <Undo />
                  Undo
                </Button>
                <Button
                  onClick={() =>
                    handlePermanentDeleteFile(deleteFileIndex, fileData?.id)
                  }
                >
                  Delete
                </Button>
              </Stack>
            </Stack>
          ))}
        </>
      )}

      {uploadedFileList?.length > 0 && (
        <>
          {uploadedFileList?.map((fileItems, indexNumber) => (
            <Stack direction="row">
              <Controller
                name="upload-doc"
                control={control}
                render={() => (
                  <TextField
                    variant="outlined"
                    label={label}
                    fullWidth
                    disabled
                    helperText={`Supported file formats:${allowedFileTypes
                      .join(", ")
                      .replace(/,([^,]*)$/, " and$1")}`}
                    value={fileItems?.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachFileOutlinedIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleDeleteMultipleUploadedFiles(fileItems?.id)
                            }
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <IconButton
                onClick={() =>
                  handleTemporaryDeleteFile(indexNumber, fileItems)
                }
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Stack>
          ))}
        </>
      )}

      {isShowAddAnotherFile && (
        <Button onClick={handleAddAnotherFile}>
          <AddOutlinedIcon />
          <Box marginLeft={1}> Add Another File</Box>
        </Button>
      )}
    </Stack>
  );
};

export default MultipleUploadDoc;
