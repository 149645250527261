import { Button, Grid, Stack, Typography } from "@mui/material";

import React from "react";
import ControlledTextField from "../../../RiverusUI/Components/ControlledTextField";
import RadioButtonGroup from "../../../RiverusUI/Components/RadioButtonGroup";
import RISelectComponent from "../../../RiverusUI/Components/SelectComponent";
import colors from "../../../RiverusUI/Theme/colors";
import { useFormContext } from "react-hook-form";

interface Props {
  signatoriesData: any[];
  userTypes: any[];
  user_type: any;
  onSubmit: (data: any) => void;
  handleUserType: VoidFunction;
  control: any;
  handleSubmit: any;
  addField: any;
  dropPoint: any;
}

const AddSignatories: React.FC<Props> = ({
  signatoriesData,
  userTypes,
  user_type,
  onSubmit,
  handleUserType,
  control,
  handleSubmit,
  addField,
  dropPoint,
}) => {
  const { watch } = useFormContext();
  const name = watch("name") || "";
  const handleClear = () => {
    handleUserType();
  };

  const dragStart = (e: any) => {
    e.target.style.opacity = 0.5;
    const copy = e.target.cloneNode(true);
    copy.id = "form-build-drag-image-copy";
    copy.style.width = "250px";
    document.body.appendChild(copy);
    e.dataTransfer.setDragImage(copy, 125, 25);
    e.dataTransfer.setData("text", "");
  };

  const dragEnd = (e: any, type: any) => {
    addField(type, dropPoint);
    e.target.style.opacity = 1;
    document.body.removeChild(
      document.getElementById("form-build-drag-image-copy") as Node
    );
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={1}
        sx={{ backgroundColor: colors?.riPrimary[20] }}
        borderRadius="10px"
        padding={2}
        marginBottom={2}
      >
        <Typography fontSize="16px" fontWeight="bold">
          Add signatories
        </Typography>

        <RadioButtonGroup
          name="user_type"
          control={control}
          row
          required
          options={userTypes}
          valueKey="value"
          onChange={handleUserType}
        />

        {user_type === "external" && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item sm={6}>
              <ControlledTextField
                name="name"
                control={control}
                label="Name"
                fullWidth
                required
              />
            </Grid>
            <Grid item sm={6}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email Address"
                fullWidth
                type="email"
                required
              />
            </Grid>
          </Grid>
        )}
        {user_type === "internal" && (
          <RISelectComponent
            name="name"
            control={control}
            label="Select user"
            options={signatoriesData}
            required
          />
        )}
        {name && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="12px" fontWeight="400" marginTop={1}>
              Drag and Drop this Signature Box to fix the signature position on
              the document.
            </Typography>
            <div
              draggable
              onDragStart={(e) => dragStart(e)}
              onDragEnd={(e) => dragEnd(e, "SIGNATURE")}
            >
              <Button
                variant="contained"
                sx={{ whiteSpace: "nowrap" }}
                onClick={() => addField("SIGNATURE")}
              >
                Signature Box
              </Button>
            </div>
          </Stack>
        )}
        <Stack justifyContent="end" direction="row" marginTop="15px">
          <Button type="submit">Add signatory</Button>
          <Button onClick={handleClear}>Clear</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default AddSignatories;
