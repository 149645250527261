import React, { useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import SideNavbar from "../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useQuery } from "@tanstack/react-query";
import { fetchStamps } from "../Services/Stamp";
import CreateStampForm from "./CreateStampForm";
import UploadStamp from "./UploadStamp";
import StampTable from "./StampTable";

const buttonStyle = {
  borderRadius: "6px",
  fontSize: "11px",
  padding: "8px 16px",
  m: "0",
  width: "fit-content",
  display: "flex",
  flexDirection: "column",
  lineHeight: 1,
  alignItems: "center",
  whiteSpace: "nowrap",
};

const StampBank = () => {
  const [openCreateStamp, setOpenCreateStamp] = useState<boolean>(false);
  const [openUploadStamp, setOpenUploadStamp] = useState<boolean>(false);
  const [params, setParams] = useState<string>("");
  const [view, setView] = useState({ view: "card", state: "" });

  const handleChange = (nextView: string, state: string) => {
    if (nextView === "card") {
      setView({ view: "card", state: "" });
    } else {
      setView({ view: nextView, state: state });
      setParams(`?state=${state}`);
    }
  };

  const { data: stampBankData } = useQuery({
    queryKey: ["stamp-bank"],
    queryFn: () => fetchStamps(),
  });

  const { data: stampByState, isLoading: stampStateLoading } = useQuery({
    queryKey: ["stamp-by-state", params],
    queryFn: () => fetchStamps(params),
    enabled: !!params,
  });

  const handleClose = () => {
    setOpenCreateStamp(false);
  };

  const handleCloseUploadStamp = () => {
    setOpenUploadStamp(false);
  };

  return (
    <Stack direction="row" mt={6}>
      <SideNavbar />
      <Stack className="sidebar-right-column" gap="24px">
        {/* Buttons */}
        <Stack gap={1}>
          <Typography variant="body2" fontWeight="600">
            Add to Stamp Bank
          </Typography>
          <Stack direction="row" gap={1}>
            <Button
              variant="contained"
              sx={buttonStyle}
              onClick={() => setOpenUploadStamp(true)}
            >
              <UploadFileOutlinedIcon sx={{ fontSize: "24px" }} />
              <Typography component="span" sx={{ mt: "6px" }}>
                Upload a CSV file (Bulk upload)
              </Typography>
            </Button>
            <Button
              variant="outlined"
              sx={buttonStyle}
              onClick={() => setOpenCreateStamp(true)}
            >
              <EditOutlinedIcon sx={{ fontSize: "24px" }} />
              <Typography component="span" sx={{ mt: "6px" }}>
                Add/Modify
              </Typography>
            </Button>
          </Stack>
        </Stack>

        {/* Stamp Bank */}
        <Stack gap={3}>
          {(stampBankData as any)?.map((stamp: any, index: number) => (
            <Stack gap={view.state === stamp.state ? 2 : 1}>
              <Stack direction="row" gap={2}>
                <Typography variant="body2" fontWeight="600">
                  {stamp.state} stamp-papers
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={view.state === stamp.state ? "list" : "card"}
                  exclusive
                  onChange={(_, nextView) =>
                    handleChange(nextView, stamp.state)
                  }
                >
                  <ToggleButton
                    value="card"
                    aria-label="card-view"
                    sx={{ padding: "5px" }}
                  >
                    <ViewModuleIcon sx={{ fontSize: "18px" }} />
                  </ToggleButton>
                  <ToggleButton
                    value="list"
                    aria-label="list-view"
                    sx={{ padding: "5px" }}
                  >
                    <ViewListIcon sx={{ fontSize: "18px" }} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              {view.state === stamp.state ? (
                <StampTable data={stampByState} isLoading={stampStateLoading} />
              ) : (
                <Stack direction="row" gap={1}>
                  {stamp.total_denomination?.map((item: any, index: number) => (
                    <Box
                      sx={{
                        background: "#FFF7FA",
                        padding: "8px 16px",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        borderRadius: "6px",
                      }}
                    >
                      <Typography variant="h5" fontWeight="600">
                        &#8377;{item.denomination}
                      </Typography>
                      <Typography variant="caption" fontWeight={500}>
                        {item.count} available
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
      {openCreateStamp && (
        <CreateStampForm open={openCreateStamp} onClose={handleClose} />
      )}
      {openUploadStamp && (
        <UploadStamp open={openUploadStamp} onClose={handleCloseUploadStamp} />
      )}
    </Stack>
  );
};

export default StampBank;
