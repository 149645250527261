export const riPrimary = {
  10: "#F3EAEF",
  20: "#E7D6DF",
  50: "#DBC1CF",
  100: "#CFACBF",
  200: "#AC6E8F",
  300: "#A0597F",
  400: "#973569",
  500: "#6D264C",
  600: "#521D39",
  700: "#361326",
  800: "#290E1C",
  900: "#0E0509",
  light: "#CFACBF",
  main: "#6D264C",
  dark: "#290E1C",
  contrastText: "#F3EAEF",
};
export const riSecondary = {
  10: "#E7D6DF",
  50: "#F6EDFF",
  100: "#E8DEF8",
  200: "#CCC2DC",
  300: "#B0A7C0",
  400: "#958DA5",
  500: "#7A7289",
  600: "#521D39",
  700: "#4A4458",
  800: "#332D41",
  900: "#1D192B",
  light: "#E8DEF8",
  main: "##7A7289",
  dark: "#332D41",
  contrastText: "#E7D6DF",
};

export const riTertiary = {
  10: "#FFFBFA",
  50: "#FFECF1",
  100: "#FFD8E4",
  200: "#EFB8C8",
  300: "#D29DAC",
  400: "#B58392",
  500: "#986977",
  600: "#7D5260",
  700: "#633B48",
  800: "#492532",
  900: "#31111D",
  main: "#986977",
};
export const riError = {
  10: "#FFFBF9",
  50: "#FCEEEE",
  100: "#F9DEDC",
  200: "#F2B8B5",
  300: "#EC928E",
  400: "#E46962",
  500: "#DC362E",
  600: "#B3261E",
  700: "#8C1D18",
  800: "#601410",
  900: "#410E0B",
  main: "#DC362E",
};

export const riNeutral = {
  10: "#E7D6DF",
  50: "#F5EFF7",
  100: "#E6E0E9",
  200: "#CAC5CD",
  300: "#AEA9B1",
  400: "#938F96",
  500: "#79767D",
  600: "#605D64",
  700: "#48464C",
  800: "#48464C",
  900: "#1D1B20",
  main: "#79767D",
};

export const riNeutralVariant = {
  10: "#E7D6DF",
  50: "#F5EEFA",
  100: "#E7E0EC",
  200: "#CAC4D0",
  300: "#AEA9B4",
  400: "#938F99",
  500: "#79747E",
  600: "#605D66",
  700: "#49454F",
  800: "#322F37",
  900: "#1D1A22",
  main: "#79747E",
};

const colors = {
  riPrimary: riPrimary,
  riSecondary: riSecondary,
  riTertiary: riTertiary,
  riError: riError,
  riNeutral: riNeutral,
  riNeutralVariant: riNeutralVariant,
  primary: {
    light: riPrimary[100],
    main: riPrimary[500],
    dark: riPrimary[800],
    contrastText: riPrimary[10],
  },
};

export default colors;
