import * as changesets from "json-diff-ts";

import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentInterface";
import EditFeature from "../EditFeature/Container/cont";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

import ClauseCard from "./CLauseCard";
import ClauseHeader from "./ClauseHeader";

export default function GeneralClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "general_definitions");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.generalDefinitions && clauseData.generalDefinitions,
    [clauseData?.generalDefinitions]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.generalDefinitions || {},
    [updatedClauseData]
  );

  const generalDefinitions = React.useMemo(() => {
    if (updates?.general_definitions) {
      let sortData = updates.general_definitions.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let general_definitions = generalDefinitions?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        general_definitions,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(
          fileId,
          "general_definitions",
          diff,
          updatedData
        );
      }
    },
    [updates, postClauseDataByType, generalDefinitions, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="definition"
          onClick={() => {
            handleAddEdit(
              props,
              "general_definitions",
              generalDefinitions,
              setShowAdd
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="general_definitions"
          savedInsight={
            !isNullOrUndefined(generalDefinitions) ? generalDefinitions : []
          }
          savedParentClauseDataPoint={generalDefinitions}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      {generalDefinitions?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          subTitle={item?.defined_word}
          onClick={() => {
            setActiveClause({ type: "obligation", index });
            props.onClickHighlight(index, item, "General Definitions");
          }}
          index={index}
          clauseItem={item}
          sentenceData={props?.sentenceData}
          isActive={
            activeClause?.type === "obligation" && activeClause?.index === index
          }
          deleteClause={() => deleteClause(item)}
          para={item.definition}
        />
      ))}
    </>
  );
}
